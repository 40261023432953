import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaHistory, FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@material-ui/core';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import toast from 'react-hot-toast';
import api from 'services/api';
import { useQuery } from 'react-query';

import ModalLog from 'components/ModalLog';
import { ContainerButtons } from './styles';
import Parametros from '../../../models/Parametros';
import ProdutoCategoriaModel from '../../../models/ProdutoCategoria';
import InputMoeda from '../Proposta/components/Materiais/components/Precificacao/components/InputMoeda';
import InputPercentual from '../Proposta/components/Materiais/components/Precificacao/components/InputPercentual';
import InputNumber from '../Proposta/components/Materiais/components/Precificacao/components/InputNumber';

export default function TelaParametros(): JSX.Element {
  const [atualizando, setAtualizando] = useState(false);

  const [modalLogVisivel, setModalLogVisivel] = useState(false);
  const [valorDolar, setValorDolar] = useState(0);
  const [precificacaoPercentual1, setprecificacaoPercentual1] = useState(0);
  const [precificacaoPercentual2, setprecificacaoPercentual2] = useState(0);
  const [minutosCalculoLances, setMinutosCalculoLances] = useState(0);
  const [fatorToleranciaLancesManual, setFatorToleranciaLancesManual] =
    useState(0);
  const [fatorToleranciaPerdaCarga, setFatorToleranciaPerdaCarga] = useState(0);

  const [propostaJurosMensal, setPropostaJurosMensal] = useState(0);

  const [diasDefaultDataEmbarque, setDiasDefaultDataEmbarque] = useState(0);

  const [modalProp, setModalProp] = useState('valorDolar');
  const [categoriaProdutos, setCategoriaProdutos] = useState(
    [] as ProdutoCategoriaModel[],
  );
  const [categoriaFotovoltaicaId, setCategoriaFotovoltaicaId] = useState(0);
  const [validadeProposta, setValidadeProposta] = useState(0);
  const [raioValidacaoPivos, setRaioValidacaoPivos] = useState(0);

  async function listaProdutoCategoria() {
    const response = await api.get('/produto-categoria');
    setCategoriaProdutos(response.data);
  }

  const { data: queryParametros, isLoading } = useQuery(
    `parametros`,
    async () => {
      async function getData(): Promise<Parametros> {
        const response = await api.get('/parametros');
        return response.data;
      }

      const dataParametros = await getData();
      return dataParametros;
    },
  );

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (queryParametros) {
      setValorDolar(queryParametros.valorDolar ?? 0);
      setprecificacaoPercentual1(queryParametros.precificacaoPercentual1 ?? 0);
      setprecificacaoPercentual2(queryParametros.precificacaoPercentual2 ?? 0);
      setCategoriaFotovoltaicaId(queryParametros.categoriaFotovoltaicaId ?? 0);
      setMinutosCalculoLances(queryParametros.minutosCalculoLances ?? 0);
      setFatorToleranciaLancesManual(
        queryParametros.fatorToleranciaLancesManual ?? 0,
      );
      setFatorToleranciaPerdaCarga(
        queryParametros.fatorToleranciaPerdaCarga ?? 0,
      );
      setPropostaJurosMensal(queryParametros.propostaJurosMensal ?? 0);
      setDiasDefaultDataEmbarque(queryParametros.diasDefaultDataEmbarque ?? 0);
      setValidadeProposta(queryParametros.validadeProposta ?? 0);
      setRaioValidacaoPivos(queryParametros.raioValidacaoPivos ?? 0);
    }
  }, [queryParametros]);

  useEffect(() => {
    listaProdutoCategoria();
  }, []);

  async function handleSalvarRegistro() {
    if (minutosCalculoLances < 2) {
      toast.error(
        `O valor para cálculo de lances não pode ser inferior à 2 minutos`,
      );
      return;
    }

    async function salvar() {
      const dataSend = {
        valorDolar,
        precificacaoPercentual1,
        precificacaoPercentual2,
        categoriaFotovoltaicaId,
        minutosCalculoLances,
        fatorToleranciaLancesManual,
        fatorToleranciaPerdaCarga,
        validadeProposta,
        propostaJurosMensal,
        diasDefaultDataEmbarque,
        raioValidacaoPivos,
      };

      await api.put('/parametros', dataSend);
    }

    try {
      setAtualizando(true);

      toast.promise(salvar(), {
        loading: 'Aguarde...',
        success: <b>Tudo certo!</b>,
        error: <b>Algo de errado aconteceu.</b>,
      });
    } finally {
      setAtualizando(false);
    }
  }

  async function mostrarModalLog(chave: string) {
    setModalProp(chave);
    setModalLogVisivel(true);
  }

  return (
    <>
      <ModalLog
        nomeChave="parametros"
        valorChave={modalProp}
        titulo="Logs"
        modalState={modalLogVisivel}
        toggle={() => setModalLogVisivel(false)}
      />
      <UserSmallHeader />
      <Card>
        <CardHeader>
          <strong className="text-muted">Parâmetros</strong>
          <ContainerButtons>
            <Button
              className="btn btn-outline-primary mb-2"
              type="button"
              size="sm"
              disabled={atualizando}
              onClick={handleSalvarRegistro}
            >
              Salvar <FaCheckCircle />
            </Button>
          </ContainerButtons>
        </CardHeader>
        <CardBody>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputMoeda
                label="Valor do Dólar"
                value={valorDolar}
                onChange={setValorDolar}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('valorDolar')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputPercentual
                label="Imposto ICMS"
                value={precificacaoPercentual1}
                onChange={setprecificacaoPercentual1}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Imposto para composição do valor de custo nos resultados de uma proposta. Não trata-se do imposto efetivo da composição do valor de uma proposta, que é extraído do ERP.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('precificacaoPercentual1')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputPercentual
                label="Imposto PIS/Cofins"
                value={precificacaoPercentual2}
                onChange={setprecificacaoPercentual2}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Imposto para composição do valor de custo nos resultados de uma proposta. Não trata-se do imposto efetivo da composição do valor de uma proposta, que é extraído do ERP.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('precificacaoPercentual2')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputNumber
                label="Minutos Cálculo Lances"
                value={minutosCalculoLances}
                onChange={setMinutosCalculoLances}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica o tempo, em minutos, em que o cálculo de lances ficará aguardando antes de disparar o processo de cálculo assíncrono.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('minutosCalculoLances')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputNumber
                label="Fator Tolerância Lances Manual"
                value={fatorToleranciaLancesManual}
                onChange={setFatorToleranciaLancesManual}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica o fator de tolerância utilizada no cálculo de lances manual">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('fatorToleranciaLancesManual')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputPercentual
                label="Fator Tolerância Perda Carga"
                value={fatorToleranciaPerdaCarga}
                onChange={setFatorToleranciaPerdaCarga}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica o fator de tolerância para a perda de carga">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('fatorToleranciaPerdaCarga')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputNumber
                label="Validade da proposta (Em dias)"
                value={validadeProposta}
                onChange={setValidadeProposta}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica a quantidade de dias de vigência de uma proposta a partir da data de atualização.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('validadeProposta')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputPercentual
                label="Percentual Juros Mensal Analisador Proposta"
                value={propostaJurosMensal}
                onChange={setPropostaJurosMensal}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica o percentual de juros padrão para o cálculo do analisador.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('propostaJurosMensal')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="px-2" hidden>
            <Col className="mb-2" xs="4" md="8">
              <InputNumber
                label="Dias Padrão para Embarque"
                value={diasDefaultDataEmbarque}
                onChange={setDiasDefaultDataEmbarque}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica a quantidade de dias default para o embarque, na aba do Analisador.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('diasDefaultDataEmbarque')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row className="px-2">
            <Col className="mb-2" xs="4" md="8">
              <InputNumber
                label="Raio validação dos pivôs"
                value={raioValidacaoPivos}
                onChange={setRaioValidacaoPivos}
              />
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Indica o raio para validação dos pivôs. Este parâmetro é opcional, para desativar a validação deve ser informado o valor zero.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('raioValidacaoPivos')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          <Row className="px-2">
            <Col style={{ margin: 0, padding: 5 }} md="5">
              <Label className="form-control-label float-right mt-3">
                Categoria UC questionário
              </Label>
            </Col>
            <Col md="3">
              <InputGroup className="input-group-alternative input-group">
                <Input
                  placeholder="Categoria UC questionário"
                  type="select"
                  value={categoriaFotovoltaicaId}
                  onChange={text =>
                    setCategoriaFotovoltaicaId(Number(text.target.value))
                  }
                >
                  <option value="">Categoria UC questionário...</option>
                  {categoriaProdutos.map(categoria => (
                    <option value={categoria.id}>{categoria.descricao}</option>
                  ))}
                </Input>
              </InputGroup>
            </Col>
            <Col className="mb-2" xs="2" md="4">
              <Tooltip title="Define o uso da UC (unidade consumidora) no questionário para uma categoria de produtos, apresentando a possibilidade de cadastrar UCs e manter seus dados em cálculo específico para a geração de usinas fotovoltaicas.">
                <Button
                  size="sm"
                  color="info"
                  outline
                  style={{ marginTop: '10px' }}
                >
                  <FaInfoCircle />
                </Button>
              </Tooltip>
              <Tooltip title="Logs">
                <Button
                  size="sm"
                  color="default"
                  outline
                  style={{ marginTop: '10px' }}
                  onClick={() => mostrarModalLog('categoriaFotovoltaicaId')}
                >
                  <FaHistory />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
