import Dashboard from 'views/pages/Dashboard';
import Login from 'views/pages/Login';
import Produtos from 'views/pages/Produtos';
import ProdutoCategoria from 'views/pages/ProdutoCategoria';
import ProdutoPartes from 'views/pages/ProdutoPartes';
import Parametros from 'views/pages/Parametros';
import Regras from 'views/pages/Regras'; //
import Funcoes from 'views/pages/Funcoes';
import CadastroCliente from 'views/pages/CadastroCliente';
import Clientes from 'views/pages/Clientes';
import ConfiguracaoOrcamento from 'views/pages/Configuracao';
import OrcamentoResultado from 'views/pages/OrcamentoResultado';
import ConfiguracaoListaGNF from 'views/pages/ConfiguracaoListaGNF';
import CadastroUsuario from 'views/pages/CadastroUsuario';
import Usuarios from 'views/pages/Usuarios';
import Perfis from 'views/pages/Perfis';
import ConfiguradorTubos from 'views/pages/ConfiguradorTubos';
import ConfiguradorModelos from 'views/pages/ConfiguradorModelos';
import ConfiguradorLances from 'views/pages/ConfiguradorLances';
import ConfiguradorLancesTubos from 'views/pages/ConfiguradorLancesTubos';
import ConfiguradorDeclividades from 'views/pages/ConfiguradorDeclividades';
import ConfiguradorAspersores from 'views/pages/ConfiguradorAspersores';
import ConfiguradorAspersoresFinais from 'views/pages/ConfiguradorAspersores/AspersoresFinais';
import ConfiguracaoEletrica from 'views/pages/OrcamentoResultado/views/ConfiguracaoEletrica';
import Cambagem from 'views/pages/OrcamentoResultado/views/Cambagem';
import Velocidade from 'views/pages/OrcamentoResultado/views/Velocidade';
import PrecificacaoEtapa from 'views/pages/ConfiguracaoEtapas/components/Resultado/views/Precificacao';
import CadDetalhes from 'views/pages/CadDetalhes';
import Proposta from 'views/pages/Proposta';
import ConfiguracaoImpressao from 'views/pages/ConfiguracaoImpressao';
import Propostas from 'views/pages/Propostas';
import PropostaImpressao from 'views/pages/Proposta/components/Impressao';
import PropostaGerarGNF from 'views/pages/PropostaGerarGNF';
import UsuarioDetalhes from 'views/pages/UsuarioDetalhes';
import ConfiguradorTubosVelocidade from 'views/pages/ConfiguradorTubosVelocidade';
import ConfiguradorBombas from 'views/pages/ConfiguradorBombas';
import ConfiguradorFabricantes from 'views/pages/ConfiguradorFabricantes';
import ConfiguradorFlanges from 'views/pages/ConfiguradorFlanges';
import PerfilPrograma from 'views/pages/Perfis/RelacaoProgramas';
import ConfiguradorBocais from 'views/pages/ConfiguradorBocais';
import ConfiguradorMcaPressao from 'views/pages/ConfiguradorMcaPressao';
import ConfiguradorTubosAdutora from 'views/pages/ConfiguradorTubosAdutora';
import CadastroTiposUcsConsumo from 'views/pages/CadastroTiposUcsConsumo';
import CadastroIrradiacaoMunicipio from 'views/pages/CadastroIrradiacaoMunicipio';
import CadastroFotovoltaicaModulos from 'views/pages/CadastroFotovoltaicaModulos';
import CadastroFotovoltaicaDistribuidoras from 'views/pages/CadastroFotovoltaicaDistribuidoras';
import CadastroFotovoltaicaConstantes from 'views/pages/CadastroFotovoltaicaConstantes';
import CadastroQuestaoTipo from 'views/pages/CadastroQuestaoTipo';
import EtapasOrcamento from 'views/pages/ConfiguracaoEtapas';
import MateriaisEtapa from 'views/pages/ConfiguracaoMateriais';
import MateriaisEtapaEng from 'views/pages/ConfiguracaoMateriaisEng';
import ConfiguradorDesconto from 'views/pages/ConfiguradorDesconto';
import ConfiguracaoPerdaCarga from 'views/pages/ConfiguracaoEtapas/components/Resultado/views/PerdaCarga';
import ConfiguradorRegras from 'views/pages/ConfiguradorRegras';
import ConfiguradorFreteEstado from 'views/pages/ConfiguradorFreteEstado';
import ConfiguradorEstruturaJson from 'views/pages/ConfiguradorEstruturaJson';
import ConfiguradorAgrupador from 'views/pages/ConfiguradorAgrupadores';
import ConfiguradorCustoTempo from 'views/pages/ConfiguradorCustoTempo';
import AcessoExterno from 'views/pages/AcessoExterno';
import ConfiguradorGrupoUsuario from 'views/pages/ConfiguradorGrupoAtendimento';
import ConfiguradorLinhaCredito from 'views/pages/ConfiguradorLinhaCredito';
import PropostaImpressaoGeral from 'views/pages/Proposta/components/ImpressaoGeral';
import InterfaceLogs from 'components/InterfaceLogs';
import CadastroEstadoImpostos from 'views/pages/CadastroEstadoImpostos';
import UsuarioPercentual from 'views/pages/UsuarioPercentual';
import ConfiguradorPerfilAprovacao from 'views/pages/ConfiguradorPerfilAprovacao';
import CadastroFotovoltaicaGrupos from 'views/pages/CadastroFotovoltaicaGrupos';
import PropostaImpressaoNovo from 'views/pages/PropostaImpressao';
import ConfiguradorMatrizResponsabilidade from 'views/pages/ConfiguradorMatrizResponsabilidade';
import ConfiguradorCurvasBombas from 'views/pages/ConfiguradorCurvasBombas';
import ConfiguradorItensAvulsos from 'views/pages/ConfiguradorItensAvulsos';
import RouterProposta from 'views/pages/RouterProposta';
import RouterConfiguracao from 'views/pages/RouterConfiguracao';
import ConfiguradorSuccao from 'views/pages/ConfiguradorSuccao';
import ConfiguradorTipoSuccao from 'views/pages/ConfiguradorTipoSuccao';
import ConfiguradorPerdaEspecifica from 'views/pages/ConfiguradorPerdaEspecifica';
import ConfiguradorLigacaoPressao from 'views/pages/ConfiguradorLigacaoPressao';
import ConfiguradorLigacaoPivo from 'views/pages/ConfiguradorLigacaoPivo';
import VariaveisRegras from 'views/pages/VariaveisRegras';
import AtaProposta from 'views/pages/AtaProposta';
import LayoutAta from 'views/pages/AtaProposta/LayoutAta';
import ImpressoaAta from 'views/pages/AtaProposta/ImpressaoAta';
import GruposAta from 'views/pages/AtaProposta/GruposAta';
import AdmAta from 'views/pages/AtaProposta/AdmAta';
import NiveisIndicadoresAta from 'views/pages/AtaProposta/NiveisIndicadoresAta';
import MigracaoOwner from 'views/pages/MigracaoOwner';
import AdmAgrupadores from 'views/pages/ConfiguradorAgrupadores/AdmAgrupadores';
import ConfiguradorGruposAgrupadores from 'views/pages/ConfiguradorAgrupadores/ConfiguradorGruposAgrupadores';
import TabelaGenerica from 'views/pages/TabelaGenerica';
import TermometriaFabricante from 'views/pages/TermometriaFabricante';
import TermometriaModelo from 'views/pages/TermometriaModelo';
import TabelaGenericaColuna from 'views/pages/TabelaGenericaColuna';

const fotovoltaica = 'fotovoltaica';
const irrigacao = 'irrigacao';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/index',
    name: 'Home',
    icon: 'ni ni-tv-2 text-primary',
    component: Dashboard,
    layout: '/admin',
    public: true,
  },
  {
    path: '/acesso-externo',
    name: 'Acesso Exerno',
    icon: 'ni ni-tv-2 text-primary',
    component: AcessoExterno,
    layout: '/admin',
    public: true,
    invisible: true,
  },
  {
    collapse: true,
    name: 'Configurador IRR',
    icon: 'ni ni-box-2 text-primary',
    state: 'configuradorIRRCollapse',
    views: [
      {
        path: '/lances-balancos',
        name: 'Lances e Balanços',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorLances,
        layout: '/admin',
      },
      {
        path: '/tubos',
        name: 'Tubos',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorTubos,
        layout: '/admin',
      },
      {
        path: '/modelos',
        name: 'Modelos',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorModelos,
        layout: '/admin',
      },
      {
        path: '/declividades',
        name: 'Lances por Modelos',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorDeclividades,
        layout: '/admin',
      },
      {
        path: '/aspersores',
        name: 'Aspersores',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorAspersores,
        layout: '/admin',
      },
      {
        path: '/aspersores-finais',
        name: 'Aspersores Finais',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorAspersoresFinais,
        layout: '/admin',
      },
      {
        path: '/cad-detalhes',
        name: 'CAD Detalhes',
        icon: 'ni ni-settings text-primary',
        component: CadDetalhes,
        layout: '/admin',
      },
      {
        path: '/bombas',
        name: 'Bombas',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorBombas,
        layout: '/admin',
      },
      {
        path: '/fabricantes',
        name: 'Fabricantes Bombas',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorFabricantes,
        layout: '/admin',
      },
      {
        path: '/flanges',
        name: 'Cadastro de Flange',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorFlanges,
        layout: '/admin',
      },
      {
        path: '/lances-tubos',
        name: 'Associação entre lances e tubos',
        component: ConfiguradorLancesTubos,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/tubos-velocidade',
        name: 'Associação da velocidade de tubos',
        component: ConfiguradorTubosVelocidade,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/bocais',
        name: 'Cadastro de bocais',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorBocais,
        layout: '/admin',
      },
      {
        path: '/mca-pressao',
        name: 'Mca/Pressão',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorMcaPressao,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/tubos-adutora',
        name: 'Tubos Adutora',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorTubosAdutora,
        layout: '/admin',
      },
      {
        path: `/constantes/:${irrigacao}`,
        name: 'Constantes (Irrigação)',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaConstantes,
        layout: '/admin',
      },
      {
        path: '/perda-especifica-item',
        name: 'Perdas Específicas',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorPerdaEspecifica,
        layout: '/admin',
      },
      {
        path: `/variaveis-questao`,
        name: 'Variáveis por questões',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroQuestaoTipo,
        layout: '/admin',
      },
      {
        path: '/configurador-estrutura-json',
        name: 'Mapas - configuração',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorEstruturaJson,
        layout: '/admin',
      },
      {
        path: '/succao',
        name: 'Sucção',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorSuccao,
        layout: '/admin',
      },
      {
        path: '/tipos-succao',
        name: 'Sucção',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorTipoSuccao,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/configurador-ligacao-pressao',
        name: 'Ligação pressão',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorLigacaoPressao,
        layout: '/admin',
      },
      {
        path: '/configurador-ligacao-pivo',
        name: 'Ligação pivô',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorLigacaoPivo,
        layout: '/admin',
      },
      {
        path: '/ata-proposta',
        name: 'Sucção',
        icon: 'ni ni-settings text-primary',
        component: AtaProposta,
        layout: '/admin',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Configurador FV',
    icon: 'ni ni-box-2 text-primary',
    state: 'configuradorFotovoltaicaCollapse',
    views: [
      {
        path: '/fotovoltaica-tipo-carga',
        name: 'Tipos de Consumo de UC',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroTiposUcsConsumo,
        layout: '/admin',
      },
      {
        path: '/irradiacao-municipio',
        name: 'Irradiação por Município',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroIrradiacaoMunicipio,
        layout: '/admin',
      },
      {
        path: '/fotovoltaica-modulos',
        name: 'Cadastro de Módulos',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaModulos,
        layout: '/admin',
      },
      {
        path: '/fotovoltaica-distribuidoras',
        name: 'Cadastro de Distribuidoras',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaDistribuidoras,
        layout: '/admin',
      },
      {
        path: '/fotovoltaica-constantes',
        name: 'Cadastro de Constantes',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaConstantes,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/estado-impostos',
        name: 'ICMS Estados',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroEstadoImpostos,
        layout: '/admin',
      },
      {
        path: `/constantes/:${fotovoltaica}`,
        name: 'Constantes (Fotovoltaica)',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaConstantes,
        layout: '/admin',
      },
      {
        path: `/fotovoltaica-grupos`,
        name: 'Grupos FV',
        icon: 'ni ni-box-2 text-primary',
        component: CadastroFotovoltaicaGrupos,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Configurador Termometria',
    icon: 'ni ni-box-2 text-primary',
    state: 'configuradorTermometriaCollapse',
    views: [
      {
        path: '/termometria-fabricante',
        name: 'Cadastro de Fabricante',
        icon: 'ni ni-box-2 text-primary',
        component: TermometriaFabricante,
        layout: '/admin',
      },
      {
        path: '/termometria-modelo',
        name: 'Cadastro de Modelos',
        icon: 'ni ni-box-2 text-primary',
        component: TermometriaModelo,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Configurador Geral',
    icon: 'ni ni-box-2 text-primary',
    state: 'configuradorGeralCollapse',
    views: [
      {
        path: '/produtos',
        name: 'Produtos',
        icon: 'ni ni-box-2 text-primary',
        component: Produtos,
        layout: '/admin',
      },
      {
        path: '/produto-categoria',
        name: 'Categoria de produtos',
        icon: 'ni ni-box-2 text-primary',
        component: ProdutoCategoria,
        layout: '/admin',
      },
      {
        path: '/produto-partes',
        name: 'Partes de produto',
        component: ProdutoPartes,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/grupo-regras',
        name: 'Regras',
        icon: 'ni ni-ui-04 text-primary',
        component: Regras,
        layout: '/admin',
      },
      {
        path: '/tabela-generica',
        name: 'Tabela Genérica',
        icon: 'ni ni-circle-08 text-primary',
        component: TabelaGenerica,
        layout: '/admin',
      },
      {
        path: '/tabela-generica-coluna',
        name: 'Tabela Genérica',
        icon: 'ni ni-circle-08 text-primary',
        component: TabelaGenericaColuna,
        layout: '/admin',
        invisible: true,
        public: true,
      },
      {
        path: '/variaveis-regras',
        name: 'Variáveis Regras',
        icon: 'ni ni-ui-04 text-primary',
        component: VariaveisRegras,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/adm-agrupadores',
        name: 'Cadastro de Agrupadores',
        icon: 'ni ni-box-2 text-primary',
        component: AdmAgrupadores,
        layout: '/admin',
      },
      {
        path: '/regras-configurador',
        name: 'Exportar regras',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorRegras,
        layout: '/admin',
      },
      {
        path: '/funcoes',
        name: 'Funções',
        icon: 'ni ni-app text-primary',
        component: Funcoes,
        layout: '/admin',
      },
      {
        path: '/parametros',
        name: 'Parâmetros',
        icon: 'ni ni-box-2 text-primary',
        component: Parametros,
        layout: '/admin',
      },
      {
        path: '/desconto',
        name: 'Desconto',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorDesconto,
        layout: '/admin',
      },
      {
        path: '/perfil-aprovacao',
        name: 'Perfil Aprovação',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorPerfilAprovacao,
        layout: '/admin',
      },
      {
        path: '/frete-estado',
        name: 'Frete x Estado',
        icon: 'ni ni-box-2 text-primary',
        component: ConfiguradorFreteEstado,
        layout: '/admin',
      },
      {
        path: '/custo-tempo',
        name: 'Custo x Tempo',
        icon: 'ni ni-settings text-primary',
        component: ConfiguradorCustoTempo,
        layout: '/admin',
      },
      {
        path: '/grupos-atendimento',
        name: 'Grupos de atendimento',
        icon: 'ni ni-circle-08 text-primary',
        component: ConfiguradorGrupoUsuario,
        layout: '/admin',
      },
      {
        path: '/proposta-linha-credito',
        name: 'Linha de crédito',
        icon: 'ni ni-circle-08 text-primary',
        component: ConfiguradorLinhaCredito,
        layout: '/admin',
      },
      {
        path: '/matriz-responsabilidade',
        name: 'Matriz de Responsabilidade',
        icon: 'ni ni-circle-08 text-primary',
        component: ConfiguradorMatrizResponsabilidade,
        layout: '/admin',
      },
      {
        path: '/lista-usuarios',
        name: 'Usuários',
        icon: 'ni ni-circle-08 text-primary',
        component: Usuarios,
        layout: '/admin',
      },
      {
        path: '/lista-usuarios-percentuais',
        name: 'Usuários Percentuais',
        icon: 'ni ni-circle-08 text-primary',
        component: UsuarioPercentual,
        layout: '/admin',
      },
      {
        path: '/cadastro-usuario',
        name: 'Cadastro de usuário',
        icon: 'ni ni-circle-08 text-primary',
        component: CadastroUsuario,
        layout: '/admin',
        invisible: true,
      },
      {
        path: '/cadastro-perfil',
        name: 'Perfis',
        icon: 'ni ni-circle-08 text-primary',
        component: Perfis,
        layout: '/admin',
      },
      {
        path: '/admin-ata',
        name: 'Manutenção ata',
        icon: 'ni ni-circle-08 text-primary',
        component: AdmAta,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Admin',
    icon: 'ni ni-box-2 text-primary',
    state: 'configuradorAdminCollapse',
    views: [
      {
        path: '/migracao-owner',
        name: 'Migração de owner',
        icon: 'ni ni-box-2 text-primary',
        component: MigracaoOwner,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/lista-clientes',
    name: 'Clientes',
    icon: 'ni ni-single-02 text-primary',
    component: Clientes,
    layout: '/admin',
  },
  {
    path: '/cadastro-cliente',
    name: 'Cadastro de cliente',
    icon: 'ni ni-single-02 text-primary',
    component: CadastroCliente,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamentos/:codigo',
    name: 'Configurações',
    icon: 'ni ni-money-coins text-primary',
    component: ConfiguracaoListaGNF,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamentos',
    name: 'Configurações por GNF',
    icon: 'ni ni-money-coins text-primary',
    component: ConfiguracaoListaGNF,
    layout: '/admin',
  },
  {
    path: '/propostas',
    name: 'Propostas',
    icon: 'ni ni-briefcase-24 text-primary',
    component: Propostas,
    layout: '/admin',
  },
  {
    path: '/proposta/:codigo/:versao',
    name: 'Proposta',
    component: RouterProposta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/configuracao/:codigo',
    name: 'Configuração',
    icon: 'ni ni-money-coins text-primary',
    component: RouterConfiguracao,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/proposta',
    name: 'Proposta',
    component: Proposta,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/proposta-impressao',
    name: 'Impressão de proposta',
    component: PropostaImpressao,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/proposta-impressao-geral',
    name: 'Impressão de proposta',
    component: PropostaImpressaoGeral,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/proposta-impressao-novo',
    name: 'Impressão de proposta',
    component: PropostaImpressaoNovo,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/proposta-gerar-gnf',
    name: 'Gerar GNF',
    component: PropostaGerarGNF,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/cadastro-orcamento-novo',
    name: 'Configuração',
    icon: 'ni ni-money-coins text-primary',
    component: ConfiguracaoOrcamento,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/etapas-orcamento',
    name: 'Configuração',
    icon: 'ni ni-money-coins text-primary',
    component: EtapasOrcamento,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamento-resultado',
    name: 'Resultado de configuração',
    component: OrcamentoResultado,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/configuracao-eletrica',
    name: 'Configuração elétrica',
    component: ConfiguracaoEletrica,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/tabela-cambagem',
    name: 'Cambagem',
    component: Cambagem,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/tabela-velocidade',
    name: 'Velocidade',
    component: Velocidade,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamento-precificacao-novo',
    name: 'Precificação',
    component: PrecificacaoEtapa,
    layout: '/admin',
    invisible: true,
  },
  /*
  {
    path: '/orcamento-materiais-old',
    name: 'Materiais da Configuração',
    component: MateriaisEtapa,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  */
  {
    path: '/orcamento-materiais',
    name: 'Materiais da Configuração Eng',
    component: MateriaisEtapa,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamento-impressao',
    name: 'Impressão da Configuração',
    component: ConfiguracaoImpressao,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/orcamento-perda-carga',
    name: '',
    component: ConfiguracaoPerdaCarga,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/meu-perfil',
    name: 'Meu Perfil',
    icon: 'ni ni-circle-08 text-primary',
    component: UsuarioDetalhes,
    layout: '/admin',
    public: true,
    invisible: true,
  },
  {
    path: '/perfil-programa',
    name: 'Acessos Programas',
    icon: 'ni ni-circle-08 text-primary',
    component: PerfilPrograma,
    layout: '/admin',
    public: true,
    invisible: true,
  },
  {
    path: '/interface-logs',
    name: 'Interface de logs',
    component: InterfaceLogs,
    layout: '/admin',
    public: true,
    invisible: true,
  },
  {
    path: '/configurador-itens-lote',
    name: 'Configurador itens lote',
    component: ConfiguradorItensAvulsos,
    layout: '/admin',
    public: true,
    invisible: true,
  },
  {
    path: '/ata-proposta',
    name: 'Ata Proposta',
    icon: 'ni ni-settings text-primary',
    component: AtaProposta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/ata-impressao',
    name: 'Impressão de proposta',
    component: ImpressoaAta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/grupos-usuarios-ata',
    name: 'Grupo de usuários ata',
    component: GruposAta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/layout-ata',
    name: 'Manutenção template ata',
    icon: 'ni ni-circle-08 text-primary',
    component: LayoutAta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/niveis-indicadores-ata',
    name: 'Níveis de indicadores ata',
    component: NiveisIndicadoresAta,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    // acessado pela tela de bombas. Por isso, mudando para public
    path: '/curvas-bombas',
    name: 'Curvas Bombas',
    icon: 'ni ni-box-2 text-primary',
    component: ConfiguradorCurvasBombas,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/regra-agrupador',
    name: 'Cadastro de Agrupadores',
    icon: 'ni ni-box-2 text-primary',
    component: ConfiguradorAgrupador,
    layout: '/admin',
    invisible: true,
    public: true,
  },
  {
    path: '/configurador-agrupadores',
    name: 'Configurador Agrupadores',
    icon: 'ni ni-box-2 text-primary',
    component: ConfiguradorGruposAgrupadores,
    layout: '/admin',
    invisible: true,
    public: true,
  },
];

export default routes;
