/* eslint-disable no-nested-ternary */
import { useContext, useState } from 'react';
import { Card, Col, CardBody, Form, Row, Button } from 'reactstrap';
import { PropostaContext } from 'views/pages/Proposta';
import { FaEdit } from 'react-icons/fa';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import ModalValoresAprovacao from '../ModalValoresAprovacao';

interface iValoresAprovacao {
  acessoPrograma: ValidarAcessoPrograma;
  propostaVersaoId: number;
}

const STATUS_APROVADO_ID = 3;

export default function ValoresAprovacao({
  acessoPrograma,
  propostaVersaoId,
}: iValoresAprovacao): JSX.Element {
  const { propostaVersao, carregaPropostaVersao } = useContext(PropostaContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  async function onSavePostback() {
    carregaPropostaVersao();
  }

  return (
    <>
      <ModalValoresAprovacao
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        propostaVersaoId={propostaVersaoId}
        onSavePostback={onSavePostback}
      />
      <Card
        className="bg-secondary shadow mt-1 mb-1"
        hidden={propostaVersao.statusId !== STATUS_APROVADO_ID}
      >
        <CardBody>
          <Form>
            <Row className="ml-0">
              <div style={{ width: '100%', padding: '0' }}>
                <h6 className="heading-small text-muted mb-1">
                  Informações Status Aprovada
                  <Button
                    className="float-right"
                    color="primary"
                    size="sm"
                    hidden={!acessoPrograma.temAcesso}
                    title="Abrir modal edição registros para Aprovação proposta"
                    onClick={() => {
                      setIsModalOpen(!isModalOpen);
                    }}
                  >
                    <FaEdit />
                  </Button>
                </h6>
              </div>
            </Row>
            <hr />
            <div className="pl-lg-3">
              <Row>
                <Col sm="4">
                  <strong>Prazo Instalação: </strong>
                  {propostaVersao.prazoInstalacao
                    ? new Intl.DateTimeFormat('pt-BR').format(
                        new Date(propostaVersao.prazoInstalacao),
                      )
                    : null}
                </Col>
                <Col sm="4">
                  <strong>Local de Descarregamento dos Materiais: </strong>
                  {propostaVersao.tipoDadoGeodesico === 'S'
                    ? 'Sede'
                    : propostaVersao.tipoDadoGeodesico === 'C'
                    ? 'Centro Pivô'
                    : propostaVersao.tipoDadoGeodesico === 'E'
                    ? 'Endereço do Cliente'
                    : 'Outro'}
                </Col>
                <Col sm="4">
                  <strong>Coordenadas Geográficas: </strong>
                  {propostaVersao.valorDadoGeodesico ?? ''}
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
