/* eslint-disable no-nested-ternary */
import Select from 'react-select';
import Questao from 'models/Questao';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { iTipoCampoTabelaGenerica } from 'staticData/TipoCampoTabelaGenerica';
import {
  iColunasGenerica,
  iColunasGenericaDependencia,
  iTabelaGenerica,
  iTabelaGenericaResposta,
} from 'models/TabelaGenerica';
import { useEffect, useState } from 'react';
import { FaPencilAlt, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import api from 'services/api';
import { Td, Th, ComponentTitle } from './style';
import './styles.css';

interface ValorAssociacao {
  questaoValorSelecaoId: number;
  descricao: string;
  valoresDependencia: {
    id: number;
    questaoId: number;
    descricao: string;
  }[];
}

interface iRespostaColuna {
  colunaId: number;
  resposta: string;
  tabelaId?: number;
}

interface iResposta {
  id: number;
  questaoId: number;
  respostas: iRespostaColuna[];
}

interface Props {
  questao: Questao;
  questoes: Questao[] | undefined;
  tabelaGenerica: any;
  respostas?: iTabelaGenericaResposta;
  configuracaoTabela: iTipoCampoTabelaGenerica[];
  valoresAssociacao: ValorAssociacao[];
  handleRespostaChange: (questaoId: number, resposta: any[]) => void;
}

function GenericTableRender({
  questao,
  questoes,
  respostas,
  valoresAssociacao,
  tabelaGenerica,
  configuracaoTabela,
  handleRespostaChange,
}: Props): JSX.Element {
  const [respostasTabela, setRespostasTabela] = useState<iResposta[]>([]);
  const [valoresTabelas, setValoresTabelas] = useState<any[]>([]);
  const [respostaEditing, setRespostaEditing] = useState<iResposta>(
    {} as iResposta,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [valoresTabelasCarregados, setValoresTabelasCarregados] =
    useState(false);

  useEffect(() => {
    if (respostas && respostas.resposta.length > 0) {
      setRespostasTabela(respostas.resposta);
    }
  }, [respostas]);

  async function handleCreate() {
    setRespostaEditing({
      id: 0,
      questaoId: questao.id,
      respostas: tabelaGenerica.TabelaGenericaColuna?.map((it: any) => {
        return {
          colunaId: it.id,
          resposta: '',
        };
      }),
    });
    setModalOpen(true);
  }

  function isEmpty(obj: any) {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
  }

  function checkVisible(item: iColunasGenerica) {
    if (!item.dependencia || item.dependencia.length === 0) {
      return true;
    }

    const { dependencia } = item;

    return dependencia.every(resp => {
      const { coluna, valor, condicao } = resp;

      if (condicao === '==') {
        return (
          String(valor ?? '').toUpperCase() ===
          String(
            respostaEditing?.respostas?.find(it => it.colunaId === coluna)
              ?.resposta ?? '',
          ).toUpperCase()
        );
      }
      return true;
    });
  }

  async function buscarDadosTabelas() {
    setValoresTabelas([]);
    try {
      const itensBuscar = tabelaGenerica.TabelaGenericaColuna?.filter(
        (item: any) => {
          return (
            item.associacao.tabelaOrigem !== undefined &&
            !Number.isNaN(item.associacao.tabelaOrigem ?? 0)
          );
        },
      );

      const itensEnviar = itensBuscar.map((it: any) => {
        return {
          coluna: it.id,
          tabela: it.associacao?.tabelaOrigem,
        };
      });

      const response = await api.post(
        `/tabela-generica/dados-tabelas-questao`,
        [...itensEnviar],
      );

      setValoresTabelas(response.data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setValoresTabelasCarregados(true);
    }
  }

  useEffect(() => {
    if (valoresTabelasCarregados === false) {
      buscarDadosTabelas();
    }
  }, [valoresTabelasCarregados]);

  if (!questao) {
    return <></>;
  }

  /*
  console.log(data);
  const utcDate = new Date(data);
  console.log(utcDate);

  const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
  });

  auxRespostaEditing[id] = data ? dateTimeFormat.format(utcDate) : ''; */

  async function handleUpdate(item: any) {
    const auxRespostaEditing = item;

    // eslint-disable-next-line no-restricted-syntax
    for await (const coluna of tabelaGenerica.TabelaGenericaColuna) {
      if (coluna.tipo === 'D') {
        const { id } = coluna;

        if (item && item[id]) {
          const data = item[id];
          auxRespostaEditing[id] = data ? data?.split('T')[0] : '';
        }
      }
      if (coluna.tipo === 'DH') {
        const { id } = coluna;

        if (item && item[id]) {
          const data = item[id];
          auxRespostaEditing[id] = data || '';
        }
      }
    }

    setRespostaEditing(auxRespostaEditing);
    setModalOpen(true);
  }

  async function handleDelete(item: any) {
    const deletar = await Swal.fire({
      title: `Deseja excluir esse registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    if (deletar.isConfirmed) {
      setRespostasTabela(respostasTabela.filter(it => it.id !== item.id));
      handleRespostaChange(
        questao.id,
        respostasTabela.filter(it => it.id !== item.id),
      );
      Swal.close();
    }
  }

  async function handleCancel() {
    setRespostaEditing({
      id: 0,
      questaoId: questao.id,
      respostas: tabelaGenerica.TabelaGenericaColuna?.map((it: any) => {
        return {
          colunaId: it.id,
          resposta: '',
        };
      }),
    });
    setModalOpen(!modalOpen);
  }

  function checkAssociation(questaoId: number, objeto: any) {
    // Conferir se o objeto está vinculado, de alguma forma, com o objeto sendo alterado
    const associado = tabelaGenerica.TabelaGenericaColuna?.filter(
      (item: any) => item.id === objeto.colunaId,
    )?.filter((item: any) => {
      if (isEmpty(item.associacao)) return false;

      return String(item.associacao?.questaoFiltro) === String(questaoId);
    });

    if (associado && associado.length > 0) {
      return {
        colunaId: objeto.colunaId,
        resposta: '',
      };
    }

    return objeto;
  }
  async function handleSave() {
    const questoesVisiveis = tabelaGenerica.TabelaGenericaColuna?.filter(
      (item: any) => checkVisible(item),
    );

    const questoesObrigatorias = questoesVisiveis?.filter(
      (item: any) => item?.regramento?.obrigatorio === true,
    );

    const respValidar = respostaEditing.respostas;

    if (questoesObrigatorias.length > 0) {
      const naoPreenchidos = questoesObrigatorias.filter(
        (item: any) =>
          !respValidar.find(it => it.colunaId === item.id)?.resposta,
      );

      if (naoPreenchidos.length > 0) {
        toast.error('Todos os campos com * são de preenchimento obrigatório');
        return;
      }
    }

    const rangeNumeroValido: string[] = [];
    const decimaisInvalidos: string[] = [];

    let auxRespostaEditing: any = {};

    // eslint-disable-next-line no-restricted-syntax
    for await (const coluna of tabelaGenerica.TabelaGenericaColuna?.filter(
      (it: any) => checkVisible(it),
    )) {
      if (coluna.tipo === 'N') {
        const { valorMinimo, valorMaximo, casasDecimais, id } =
          coluna.regramento;
        const resposta = Number(
          respValidar.find(it => it.colunaId === id)?.resposta ?? 0,
        );

        if (respValidar.find(it => it.colunaId === id)?.resposta) {
          const totalDecimal = String(
            respValidar.find(it => it.colunaId === id)?.resposta ?? '',
          )?.split('.');

          if (casasDecimais && totalDecimal.length > 1) {
            if ((totalDecimal[1]?.length ?? 0) > (casasDecimais ?? 0)) {
              decimaisInvalidos.push(
                `<strong>${coluna.nome}</strong>: máximo ${
                  casasDecimais ?? 0
                } casas decimais`,
              );
            }
          }
        }

        if ((valorMinimo ?? 0) !== 0 && (valorMaximo ?? 0) !== 0) {
          const invalido =
            resposta < (valorMinimo ?? 0) || resposta > (valorMaximo ?? 0);

          if (invalido)
            rangeNumeroValido.push(
              `<strong>${coluna.nome}</strong>: valor entre ${
                valorMinimo ?? 0
              } e ${valorMaximo ?? 0}`,
            );
        }

        if ((valorMinimo ?? 0) === 0 && (valorMaximo ?? 0) !== 0) {
          const invalido = resposta > (valorMaximo ?? 0);

          if (invalido)
            rangeNumeroValido.push(
              `<strong>${coluna.nome}</strong>: valor máximo ${
                valorMaximo ?? 0
              }`,
            );
        }

        if ((valorMinimo ?? 0) !== 0 && (valorMaximo ?? 0) === 0) {
          const invalido = resposta < (valorMinimo ?? 0);

          if (invalido)
            rangeNumeroValido.push(
              `<strong>${coluna.nome}</strong>: Valor mínimo ${
                valorMinimo ?? 0
              }`,
            );
        }
      }

      if (coluna.tipo === 'D') {
        // Para campos de tipo Data (Futuramente campos de data e hora), aplicar o timezone
        const { id } = coluna;
        if (respValidar.find(it => it.colunaId === id)?.resposta ?? '') {
          const data =
            respValidar.find(it => it.colunaId === id)?.resposta ?? '';
          auxRespostaEditing = {
            id: respostaEditing.id,
            questaoId: respostaEditing.questaoId,
            respostas: respValidar.map(it => {
              if (it.colunaId === id) {
                return {
                  ...it,
                  resposta: `${data}T00:00:00`,
                };
              }
              return it;
            }),
          };
        }
      } else {
        // Se houver resposta. Então, salvo.
        // Então, caso a coluna não exista, não preenche.
        const { id } = coluna;
        if (respValidar.find(it => it.colunaId === id)?.resposta ?? '')
          auxRespostaEditing = {
            id: respostaEditing.id,
            questaoId: respostaEditing.questaoId,
            respostas: respValidar,
          };
      }
    }

    if (rangeNumeroValido.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Campos numéricos inválidos!',
        html: `<p>Algum dos campos numéricos não respeitam o valor mínimo e máximo permitido: <br />${rangeNumeroValido.join(
          '<br />',
        )}<p>`,
      });

      return;
    }

    if (decimaisInvalidos.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Campos numéricos inválidos!',
        html: `<p>Algum dos campos numéricos não respeitam o máximo de casas decimais: <br />${decimaisInvalidos.join(
          '<br />',
        )}<p>`,
      });

      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const coluna of tabelaGenerica.TabelaGenericaColuna?.filter(
      (it: any) => !checkVisible(it),
    )) {
      auxRespostaEditing = {
        ...respostaEditing,
        respostas:
          auxRespostaEditing.respostas?.map((it: any) => {
            if (it.colunaId === coluna.id) {
              return {
                colunaId: it.colunaId,
                resposta: '',
              };
            }
            return it;
          }) ?? [],
      };
    }
    const maxId = respostasTabela.reduce(
      (max: any, current: any) => (max && max > current.id ? max : current.id),
      0,
    );

    if (auxRespostaEditing.id) {
      setRespostasTabela(
        respostasTabela?.map(item => {
          if (item.id === auxRespostaEditing.id) {
            return {
              ...auxRespostaEditing,
            };
          }
          return item;
        }),
      );

      handleRespostaChange(
        questao.id,
        respostasTabela?.map(item => {
          if (item.id === auxRespostaEditing.id) {
            return {
              ...auxRespostaEditing,
            };
          }
          return item;
        }),
      );
    } else {
      setRespostasTabela([
        ...respostasTabela,
        {
          ...auxRespostaEditing,
          questaoId: questao.id,
          id: maxId + 1,
        },
      ]);

      handleRespostaChange(questao.id, [
        ...respostasTabela,
        {
          ...auxRespostaEditing,
          questaoId: questao.id,
          id: maxId + 1,
        },
      ]);
    }

    setRespostaEditing({
      id: 0,
      questaoId: questao.id,
      respostas: tabelaGenerica.TabelaGenericaColuna?.map((it: any) => {
        return {
          colunaId: it.id,
          resposta: '',
        };
      }),
    });
    setModalOpen(false);
  }

  function renderField(item: iColunasGenerica) {
    const larguraUsar =
      tabelaGenerica.TabelaGenericaColuna?.length <= 6 ? 12 : 4;

    const configTable = configuracaoTabela.find(
      item2 => item2.sigla === item.tipo,
    );

    if (!configTable) return <></>;

    if (configTable.sigla === 'S' || configTable.sigla === 'MS') {
      let opcoesUsar: {
        value: any;
        label: string;
      }[] = [];

      let tabelaExterna = false;

      if (isEmpty(item.associacao)) {
        const varSplit = item.regramento?.opcoes?.split(';');
        opcoesUsar =
          varSplit?.map((it: any) => {
            return { label: it, value: it };
          }) ?? [];
      } else {
        const { coluna, colunaFiltro, tabelaOrigem, questaoFiltro } =
          item.associacao;

        tabelaExterna = true;
        const dadosUsar = valoresTabelas.find(it => it.coluna === item.id);

        if (dadosUsar && dadosUsar.dados.length > 0) {
          if (questaoFiltro && colunaFiltro) {
            const respAux = respostaEditing?.respostas?.find(
              it => it.colunaId === questaoFiltro,
            );

            const resp = !isEmpty(respAux)
              ? respAux?.tabelaId ?? respAux?.resposta
              : '';

            opcoesUsar =
              dadosUsar.dados
                .filter(
                  (it: any) =>
                    resp && String(it[colunaFiltro]) === String(resp),
                )
                ?.map((it: any) => {
                  return {
                    value: it.value,
                    label: it[coluna] ?? it.label,
                  };
                }) ?? [];
          } else {
            opcoesUsar =
              dadosUsar.dados.map((it: any) => {
                return {
                  value: it.value,
                  label: it[coluna] ?? it.label,
                };
              }) ?? [];
          }
        }
      }

      if (
        configTable.sigla === 'MS' ||
        item.regramento?.selecionarMultiplos === true
      ) {
        const resp =
          respostaEditing?.respostas?.find(it => it.colunaId === item.id)
            ?.resposta ?? '';

        const arrayRespostas = resp?.split(',') ?? [];

        const valores =
          opcoesUsar.filter((objeto: any) =>
            arrayRespostas.includes(objeto.value),
          ) ?? [];

        return (
          <Col sm={larguraUsar}>
            <FormGroup className="mb-3">
              <span>{`${item.nome} ${
                item?.regramento?.obrigatorio ? '*' : ''
              }`}</span>
              <InputGroup className="input-group-alternative">
                <div style={{ width: '100%' }}>
                  <Select
                    closeMenuOnSelect={false}
                    placeholder="Selecione"
                    isMulti
                    value={valores}
                    options={opcoesUsar}
                    onChange={(e: any) => {
                      const resposta = e.map((it: any) => it.value)?.join(',');
                      const respostaV = e.map((it: any) => it.label)?.join(',');

                      setRespostaEditing({
                        id: respostaEditing.id,
                        questaoId: respostaEditing.questaoId,
                        respostas: respostaEditing.respostas?.map(it => {
                          if (it.colunaId !== item.id)
                            return checkAssociation(item.id, it);
                          return {
                            colunaId: item.id,
                            resposta: String(respostaV),
                            tabelaId: tabelaExterna ? resposta : undefined,
                          };
                        }),
                      });
                    }}
                  />
                </div>
              </InputGroup>
            </FormGroup>
          </Col>
        );
      }

      const resp = respostaEditing?.respostas?.find(
        it => it.colunaId === item.id,
      );

      return (
        <>
          <Col sm={larguraUsar}>
            <FormGroup className="mb-3">
              <span>{`${item.nome} ${
                item?.regramento?.obrigatorio ? '*' : ''
              }`}</span>
              <InputGroup className="input-group-alternative">
                <div style={{ width: '100%' }}>
                  <Select
                    closeMenuOnSelect
                    placeholder="Selecione"
                    value={
                      opcoesUsar.find(
                        it =>
                          (it.value === resp?.tabelaId ||
                            it.label === resp?.resposta) ??
                          '',
                      ) ?? {}
                    }
                    options={opcoesUsar}
                    onChange={(event: any) => {
                      const resposta = event.value;
                      const respostaV = event.value !== '' ? event.label : '';

                      setRespostaEditing({
                        id: respostaEditing.id,
                        questaoId: respostaEditing.questaoId,
                        respostas: respostaEditing.respostas?.map(it => {
                          if (it.colunaId !== item.id)
                            return checkAssociation(item.id, it);
                          return {
                            colunaId: item.id,
                            resposta: String(respostaV),
                            tabelaId: tabelaExterna ? resposta : undefined,
                          };
                        }),
                      });
                    }}
                  />
                </div>
              </InputGroup>
            </FormGroup>
          </Col>
        </>
      );
    }

    if (configTable.sigla === 'T') {
      return (
        <>
          <Col sm={larguraUsar}>
            <FormGroup className="mb-3">
              <span>{`${item.nome} ${
                item?.regramento?.obrigatorio ? '*' : ''
              }`}</span>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder={item.nome}
                  value={
                    respostaEditing?.respostas?.find(
                      it => it.colunaId === item.id,
                    )?.resposta ?? ''
                  }
                  type="text"
                  onChange={event => {
                    setRespostaEditing({
                      id: respostaEditing.id,
                      questaoId: respostaEditing.questaoId,
                      respostas: respostaEditing.respostas?.map(it => {
                        if (it.colunaId !== item.id)
                          return checkAssociation(item.id, it);
                        return {
                          colunaId: item.id,
                          resposta:
                            it.colunaId === item.id
                              ? String(event.target.value)
                              : it.resposta,
                        };
                      }),
                    });
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </>
      );
    }

    if (configTable.sigla === 'N') {
      const { valorMinimo, valorMaximo, casasDecimais } = item.regramento;

      const min = valorMinimo !== 0 ? `Mínimo: ${valorMinimo} ` : '';
      const max = valorMaximo !== 0 ? `Máximo: ${valorMaximo} ` : '';
      const step = casasDecimais !== 0 ? casasDecimais : 0;
      const textCasas =
        casasDecimais !== 0 ? `Formato: 9,${''.padStart(step, '9')}` : '';

      return (
        <>
          <Col sm={larguraUsar}>
            <FormGroup className="mb-3">
              <span>{`${item.nome} ${
                item?.regramento?.obrigatorio ? '*' : ''
              }`}</span>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder={item.nome}
                  value={
                    Number(
                      respostaEditing?.respostas?.find(
                        it => it.colunaId === item.id,
                      )?.resposta ?? 0,
                    ) ?? 0
                  }
                  type="number"
                  step={`0.${''.padStart(step > 1 ? step - 1 : 1, '0')}1`}
                  title={`${min} ${max} ${textCasas}`}
                  onChange={event => {
                    let val = event.target.value;
                    if (step === 0) {
                      val = val.replace(/\D/g, '');
                    }
                    setRespostaEditing({
                      id: respostaEditing.id,
                      questaoId: respostaEditing.questaoId,
                      respostas: respostaEditing.respostas?.map(it => {
                        if (it.colunaId !== item.id)
                          return checkAssociation(item.id, it);
                        return {
                          colunaId: item.id,
                          resposta: it.colunaId === item.id ? val : it.resposta,
                        };
                      }),
                    });
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </>
      );
    }

    if (
      configTable.sigla === 'D' ||
      configTable.sigla === 'H' ||
      configTable.sigla === 'DH'
    ) {
      const tipo =
        // eslint-disable-next-line no-nested-ternary
        configTable.sigla === 'D'
          ? 'date'
          : configTable.sigla === 'H'
          ? 'time'
          : 'datetime-local';

      return (
        <>
          <Col sm={larguraUsar}>
            <FormGroup className="mb-3">
              <span>{`${item.nome} ${
                item?.regramento?.obrigatorio ? '*' : ''
              }`}</span>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder={item.nome}
                  value={
                    respostaEditing?.respostas?.find(
                      it => it.colunaId === item.id,
                    )?.resposta ?? ''
                  }
                  type={tipo}
                  onChange={event => {
                    setRespostaEditing({
                      id: respostaEditing.id,
                      questaoId: respostaEditing.questaoId,
                      respostas: respostaEditing.respostas?.map(it => {
                        if (it.colunaId !== item.id)
                          return checkAssociation(item.id, it);
                        return {
                          colunaId: item.id,
                          resposta:
                            it.colunaId === item.id
                              ? String(event.target.value)
                              : it.resposta,
                        };
                      }),
                    });
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </>
      );
    }

    return <></>;
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size={tabelaGenerica.TabelaGenericaColuna?.length <= 6 ? 'md' : 'lg'}
        isOpen={modalOpen}
      >
        <ModalHeader>
          {respostaEditing.id ? `Edição do Registro` : `Criação do Registro`}
        </ModalHeader>
        <ModalBody>
          <Form role="form">
            <Row>
              {tabelaGenerica.TabelaGenericaColuna?.filter((item: any) =>
                checkVisible(item),
              )?.map((item: any) => renderField(item))}
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            type="button"
            disabled={isSaving}
            onClick={handleCancel}
          >
            Fechar
          </Button>

          <Button
            color="primary"
            type="button"
            disabled={isSaving}
            onClick={handleSave}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Row className="mt-2 mb-2 ">
        <Col className="col-sm-12 col-xl-12 div-component">
          <ComponentTitle>
            {`${tabelaGenerica.nome} ${questao.isObrigatorio ? '*' : ''}`}

            <Button
              size="sm"
              className="btn-outline-primary float-right"
              color="primary"
              type="button"
              title="Adicionar novo registro"
              onClick={handleCreate}
            >
              <FaPlusCircle />
            </Button>
          </ComponentTitle>
          <small>{tabelaGenerica.descricao}</small>
          <div className="div-table">
            <Table
              responsive
              bordered
              className="align-items-center  generic_table_render mt-2"
            >
              <thead>
                <tr>
                  {tabelaGenerica.TabelaGenericaColuna?.map((item: any) => (
                    <>
                      <Th>{item.nome}</Th>
                    </>
                  ))}
                  <Th />
                </tr>
              </thead>
              <tbody>
                {respostasTabela?.map(item => (
                  <>
                    <tr key={`tr_${questao.id}_${item.id}`}>
                      {tabelaGenerica.TabelaGenericaColuna?.map((it: any) => {
                        const { tipo } = it;

                        const valor =
                          item.respostas?.find(it2 => it2.colunaId === it.id)
                            ?.resposta ?? '';

                        // const utcDate = new Date(`${data}T00:00:00`);

                        // const dateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
                        //  timeZone: 'America/Sao_Paulo',
                        // });

                        // console.log(dateTimeFormat.format(utcDate));

                        if (tipo === 'D') {
                          const dataUsar = valor
                            ? new Date(`${valor?.split('T')[0]}T00:00:00`)
                            : '';

                          return (
                            <>
                              <Td>
                                {dataUsar
                                  ? new Intl.DateTimeFormat('pt-BR', {
                                      dateStyle: 'short',
                                      timeZone: 'America/Sao_Paulo',
                                    }).format(new Date(dataUsar))
                                  : ''}
                              </Td>
                            </>
                          );
                        }
                        if (tipo === 'DH') {
                          const dataUsar = valor ? new Date(`${valor}`) : '';

                          return (
                            <>
                              <Td>
                                {dataUsar
                                  ? new Intl.DateTimeFormat('pt-BR', {
                                      dateStyle: 'short',
                                      timeStyle: 'medium',
                                      timeZone: 'America/Sao_Paulo',
                                    }).format(new Date(dataUsar))
                                  : ''}
                              </Td>
                            </>
                          );
                        }
                        return (
                          <>
                            <Td>{valor}</Td>
                          </>
                        );
                      })}
                      <Td>
                        <Button
                          size="sm"
                          className="btn-outline-danger float-right"
                          color="danger"
                          type="button"
                          title="Remover registro"
                          onClick={() => handleDelete(item)}
                        >
                          <FaTimesCircle />
                        </Button>
                        <Button
                          size="sm"
                          className="btn-outline-primary mr-1 float-right"
                          color="primary"
                          type="button"
                          title="Editar registro"
                          onClick={() => handleUpdate(item)}
                        >
                          <FaPencilAlt />
                        </Button>
                      </Td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default GenericTableRender;
