import UserSmallHeader from 'components/Headers/UserSmallHeader';

import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropostaVersao } from 'models/PropostaVersao';
import Proposta from 'models/Proposta';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import IndicadoresNegocio from 'models/IndicadoresNegocio';
import formatCurrency from 'utils/formatCurrency';
import api from 'services/api';
import { useQuery } from 'react-query';

import { Col, Row } from 'reactstrap';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import config from 'config';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import Orcamento from 'models/Orcamento';
import { Container, Typography } from './styles';

import Cabecalho from './components/Cabecalho';
import Titulo from './components/Titulo';
import FornecedoraFaturamento from './components/FornecedoraFaturamento';
import Equipamento from './components/Equipamento';
import AceiteClausulas from './components/AceiteClausulas';

import LogoImg from '../assets/img/logo_fck.png';

import './style.css';
import CondicoesFornecimento from './components/CondicoesFornecimento';
import CondicoesPagamento from './components/CondicoesPagamento';
import VendorList from './components/VendorList';
import Parcelas from './components/Parcelas';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
}

interface PropostaImpressaoContext {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  isPropostaExterior: boolean;
  setIsPropostaExterior: (valor: boolean) => void;
  valorDolar: number;
  setValorDolar: (valor: number) => void;
  parcelas: PropostaVersaoParcelas[];
  orcamento: Orcamento;
  propostaConfiguracao: PropostaConfiguracao[];
  tipoIndicador: string;
  setTipoIndicador: (valor: string) => void;
}

export const PropostaImpressaoContext = createContext(
  {} as PropostaImpressaoContext,
);

export default function PropostaImpressao(): JSX.Element {
  const location = useLocation<LocationState>();
  const { proposta: pProposta, propostaVersao: pVersao } = location.state;
  const [parcelas, setParcelas] = useState<PropostaVersaoParcelas[]>([]);
  const [propostaConfiguracoes, setPropostaConfiguracoes] = useState<
    PropostaConfiguracao[]
  >([]);
  const [listaRepresentantes, setListaRepresentantes] = useState(
    {} as IndicadoresNegocio,
  );
  const [propostaVersao, setPropostaVersao] = useState<PropostaVersao>(pVersao);
  const [proposta, setProposta] = useState<Proposta>(pProposta);
  const [orcamento, setOrcamento] = useState({} as Orcamento);

  const [isPropostaExterior, setIsPropostaExterior] = useState(false);
  const [isLoadingPropostaVersao, setIsLoadingPropostaVersao] = useState(false);
  const [tipoIndicador, setTipoIndicador] = useState('');
  const [valorDolar, setValorDolar] = useState(0);
  const orcamentoId = pVersao?.PropostaConfiguracao?.[0]?.orcamentoId;

  const { data: queryPropostaVersaoParcelas, isLoading: isLoadingParcelas } =
    useQuery(
      `parcelas-proposta-versao-${pVersao.id}`,
      async () => {
        async function getParcelas(): Promise<PropostaVersaoParcelas[]> {
          const response = await api.get(
            `/proposta/versao/${pVersao.id}/parcelas`,
          );
          return response.data;
        }

        const dataMeioPagamento = await getParcelas();
        return dataMeioPagamento;
      },
      { staleTime: 1000 },
    );

  /* const { data: queryPropostaVersao, isLoading: isLoadingPropostaVersao } =
    useQuery(
      `proposta-versao-${pVersao.id}`,
      async () => {
        async function getVersao(): Promise<PropostaVersao> {
          const response = await api.get(`/proposta/versao/${pVersao.id}`);
          return response.data;
        }

        const dataVersao = await getVersao();
        return dataVersao;
      },
      { staleTime: 10 },
    ); */

  const { data: queryProposta, isLoading: isLoadingProposta } = useQuery(
    `proposta-${pProposta.id}`,
    async () => {
      async function getProposta(): Promise<Proposta> {
        const response = await api.get(`/proposta/${pProposta.id}`);
        return response.data;
      }

      const data = await getProposta();
      return data;
    },
    { staleTime: 1000 },
  );

  async function getOrcamento() {
    if (orcamentoId) {
      const response = await api.get(`/orcamento/${orcamentoId}`);
      setOrcamento(response.data);
    }
  }

  async function getTipoIndicador() {
    if (orcamentoId) {
      const response = await api.get(
        `/integracao/niveis-indicadores/busca-indicador/${pVersao.id}`,
      );

      if (response.data.length > 0) {
        setTipoIndicador(response.data[0].descricao);
      }
    }
  }

  useEffect(() => {
    getOrcamento();
  }, []);

  useEffect(() => {
    getTipoIndicador();
  }, []);

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    if (queryPropostaVersaoParcelas?.length) {
      setParcelas(queryPropostaVersaoParcelas);
    }
  }, [queryPropostaVersaoParcelas]);

  async function carregarVersao() {
    async function getVersao(): Promise<PropostaVersao> {
      setIsLoadingPropostaVersao(true);
      const response = await api.get(`/proposta/versao/${pVersao.id}`);
      return response.data;
    }

    const queryPropostaVersao = await getVersao();

    setIsLoadingPropostaVersao(false);
    setPropostaVersao(queryPropostaVersao);
    setValorDolar(queryPropostaVersao?.valorDolar || 0);

    setPropostaConfiguracoes(queryPropostaVersao?.PropostaConfiguracao || []);
  }

  useEffect(() => {
    carregarVersao();
  }, []);

  /*
  useEffect(() => {
    if (queryPropostaVersao) {
      setPropostaVersao(queryPropostaVersao);
      setValorDolar(queryPropostaVersao?.valorDolar || 0);

      setPropostaConfiguracoes(queryPropostaVersao?.PropostaConfiguracao || []);
    }
  }, [queryPropostaVersao]); */

  useEffect(() => {
    if (queryProposta) {
      setProposta(queryProposta);
      setIsPropostaExterior(queryProposta.PropostaLocalObra?.exterior || false);
    }
  }, [queryProposta]);

  return (
    <>
      <Container>
        <PropostaImpressaoContext.Provider
          value={{
            proposta,
            propostaVersao,
            parcelas,
            isPropostaExterior,
            setIsPropostaExterior,
            valorDolar,
            setValorDolar,
            orcamento,
            propostaConfiguracao: propostaConfiguracoes,
            tipoIndicador,
            setTipoIndicador,
          }}
        >
          <Cabecalho />
          <div className="mt-5">
            <Titulo />
          </div>
          <FornecedoraFaturamento />
          <Row>
            <Typography variant="h6" className="float-left">
              <strong>1 - EQUIPAMENTO</strong>
            </Typography>
          </Row>
          {propostaConfiguracoes?.map(propostaConfiguracao => (
            <Equipamento
              propostaConfiguracao={propostaConfiguracao}
              isPropostaExterior={isPropostaExterior}
              valorDolar={valorDolar}
              propostaVersao={propostaVersao}
            />
          ))}
          <Row className="my-3">
            <Col>
              <Typography variant="h6" className="float-left">
                <strong>PREÇO TOTAL DA PROPOSTA</strong>
              </Typography>
            </Col>
            <Col>
              <Typography variant="h6" className="float-right">
                <strong>{formatCurrency(propostaVersao?.valorFinal, 2)}</strong>
              </Typography>
            </Col>
          </Row>

          <CondicoesFornecimento />
          <CondicoesPagamento />
          <VendorList />
          {isLoadingParcelas ? (
            <Row>
              <Col className="text-center mb-4">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <Parcelas />
          )}
          <AceiteClausulas />
        </PropostaImpressaoContext.Provider>
      </Container>
    </>
  );
}
