import { useLocation } from 'react-router-dom';
import api from 'services/api';
import Proposta from 'models/Proposta';
import { PropostaVersao } from 'models/PropostaVersao';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import { useAuth } from 'hooks/auth';
import { ProdutoProposta } from 'models/ProdutoProposta';
import { AppBar, CircularProgress, Tab, Tabs } from '@material-ui/core';
import config from 'config';
import './style.css';

import {
  Button,
  CardHeader,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import TabPanel from 'components/TabPanel';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import LogoImg from './components/assets/img/logo_fck.png';
import PropostaImpressaoOld from './components/Impressao';
import PedidoVenda from './components/PedidoVenda';
import CondicoesGeraisFornecimento from './components/CondicoesGeraisFornecimento';
import { Container } from './styles';
import MatrizResponsabilidade from './components/MatrizResponsabilidade';
import FVImpressao from './components/FVImpressao';
import FVSimulacaoViabilidade from './components/FVSimulacaoViabilidade';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  produtoProposta: ProdutoProposta;
}

interface iReportTemplate {
  id: number;
  name: string;
  produtoId?: number;
  produtoCategoriaId?: number;
  ordem: number;
  componente?: string;
}

export default function PropostaImpressao(): JSX.Element {
  const location = useLocation<LocationState>();
  const { usuario } = useAuth();
  const { proposta, propostaVersao, produtoProposta } = location.state;

  const [currentTab, setCurrentTab] = useState(0);
  const [bloquearTudo, setBloquearTudo] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [reportTemplate, setReportTemplate] = useState<iReportTemplate[]>([]);
  const [valoresParametros, setValoresParametros] = useState<any>({});

  async function buscarTemplates() {
    setIsLoadingReport(true);
    const response = await api.get(
      `/impressao/proposta-versao/${propostaVersao.id}`,
    );

    setReportTemplate(response.data.tipos as iReportTemplate[]);

    setValoresParametros(response.data.valores as any[]);
    setIsLoadingReport(false);
  }

  const components = [
    {
      id: 'PropostaImpressaoOld',
      componente: PropostaImpressaoOld,
    },
    {
      id: 'PedidoVenda',
      componente: PedidoVenda,
    },
    {
      id: 'CondicoesGeraisFornecimento',
      componente: CondicoesGeraisFornecimento,
    },
    {
      id: 'MatrizResponsabilidade',
      componente: MatrizResponsabilidade,
    },
    {
      id: 'FVImpressao',
      componente: FVImpressao,
    },
    {
      id: 'FVSimulacaoViabilidade',
      componente: FVSimulacaoViabilidade,
    },
  ];

  useEffect(() => {
    buscarTemplates();
  }, []);

  async function processamentoPrint() {
    window.print();
  }

  function printFunction() {
    processamentoPrint();
  }

  function AvisoPropostaOrientativa({
    position,
  }: {
    position: string;
  }): JSX.Element {
    return (
      <div className={`marca-dagua ${position} text-center`}>
        <h2>
          {config.type === 'develop'
            ? 'AMBIENTE DE DESENVOLVIMENTO'
            : 'AMBIENTE DE HOMOLOGAÇÃO'}{' '}
          <br />
          Informações não oficiais.
        </h2>
      </div>
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Container className="container-print">
        {config.type !== 'production' ? (
          <>
            <AvisoPropostaOrientativa position="top" />
          </>
        ) : (
          <></>
        )}
        <div className="hide-on-print">
          <Row className="mt-2 hide-on-print">
            <Col sm="12">
              <div>
                {`Proposta ${proposta.id} - Versão ${propostaVersao.versao}`}
              </div>
            </Col>
          </Row>
        </div>
        <div className="hide-on-print">
          <Tabs
            value={currentTab}
            hidden={bloquearTudo}
            onChange={(_, newCurrentTab) => setCurrentTab(newCurrentTab)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {reportTemplate.map(item => (
              <Tab label={item.name} style={{ fontWeight: 600 }} />
            ))}
          </Tabs>
        </div>
        <div style={{ pageBreakInside: 'auto' }} hidden={bloquearTudo}>
          {isLoadingReport ? (
            <Row>
              <Col sm="12" className="text-center mt-5">
                <CircularProgress />
              </Col>
            </Row>
          ) : (
            <>
              {reportTemplate.map(item => {
                const currIndex = item.ordem - 1;

                const componenteFiltrado = components.find(
                  item2 => item2.id === item.componente,
                );

                const DynamicComponent = componenteFiltrado
                  ? componenteFiltrado.componente
                  : null;

                if (DynamicComponent)
                  return (
                    <TabPanel value={currentTab} index={currIndex}>
                      {currentTab === currIndex && (
                        <DynamicComponent valores={valoresParametros} />
                      )}
                    </TabPanel>
                  );

                /** /*
        <TabPanel value={currentTab} index={0}>
          {currentTab === 0 && <PropostaImpressaoOld />}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {currentTab === 1 && <PedidoVenda valores={valoresParametros} />}
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          {currentTab === 2 && <CondicoesGeraisFornecimento />}
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          {currentTab === 3 && (
            <MatrizResponsabilidade valores={valoresParametros} />
          )}
        </TabPanel>{' '}
        */

                return <></>;
              })}
            </>
          )}
        </div>

        <div>
          {config.type !== 'production' && (
            <>
              <AvisoPropostaOrientativa position="middle" />
            </>
          )}
        </div>

        <div className="footer-print">
          <img src={LogoImg} alt="Logo" />
        </div>

        <FloatingButton
          disabled={isLoadingReport}
          className="hide-on-print"
          onClick={printFunction}
        >
          <FaPrint />
        </FloatingButton>
      </Container>
    </>
  );
}
