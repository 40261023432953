import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Collapse,
  DropdownItem,
  DropdownMenu,
  Badge,
} from 'reactstrap';

import toast from 'react-hot-toast';

import Header from 'components/Headers/Header.js';

import {
  FaCheckCircle,
  FaEdit,
  FaPencilAlt,
  FaPlusCircle,
  FaRegEyeSlash,
  FaTimesCircle,
} from 'react-icons/fa';

import Swal from 'sweetalert2';

import QuestaoValorSelecao from 'models/QuestaoValorSelecao';
import { Autocomplete } from '@material-ui/lab';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';

import QuestaoSelecaoListImagem from 'components/QuestaoSelecaoListImagem';
import ParteModel from 'models/Parte';
import ProdutoModel from 'models/Produto';
import ModeloSuccaoModel from 'models/ConfiguradorModeloSuccao';
import Questao from 'models/Questao';

import api from 'services/api';

import FloatingButton from 'components/FloatingButton';
import { Select } from 'antd';
import QuestaoRelacao from 'models/QuestaoRelacao';
import { useQueryClient } from 'react-query';
import QuestaoSelecaoImagemSuccao from 'components/QuestaoSelecaoListImagem/QuestaoSelecaoImagemSuccao';
import { iTabelaGenerica } from 'models/TabelaGenerica';
import {
  DivRow,
  AcoesDiv,
  DropdownTipoQuestao,
  DropdownToggleTipoQuestao,
} from '../css/index';

import {
  QuestaoContainerRow,
  ContainerInterno,
  SelecaoRelacaoContainer,
} from './styles';
import Succao from '../OrcamentoResultado/views/AdutoraBombeamento/components/Succao';

type LocationState = {
  produto?: ProdutoModel;
};

type TipoQuestao = {
  key: string;
  value: string;
};

type isOpen = {
  parteId: number;
  value: boolean;
};

type ValoresDependenciaType = {
  id?: number;
  questaoId: number;
  descricao: string;
};

type RelacaoAgrupadoType = {
  questaoValorSelecaoId: number;
  descricao: string;
  valoresDependencia: ValoresDependenciaType[];
};

interface iQuestaoModal extends Questao {
  usarRegraColuna: boolean | false;
  nomeVariavel?: string;
}

const ProdutoPartes: React.FC = () => {
  const location = useLocation<LocationState>();
  const { produto } = location.state;
  const [novaParteNome, setNovaParteNome] = useState('');
  const [dropdownParteState, setDropdownParteState] = useState([] as isOpen[]);

  const queryClient = useQueryClient();

  // arrays
  const [partes, setPartes] = useState([] as ParteModel[]);
  const [questoes, setQuestoes] = useState([] as Questao[]);

  const [questoesTipoTitulo, setQuestoesTipoTitulo] = useState([] as Questao[]);

  const [loading, setLoading] = useState(false);

  // modal states
  const [parteEditing, setParteEditing] = useState({} as ProdutoModel);
  const [modeloSuccao, setModeloSuccao] = useState([] as ModeloSuccaoModel[]);
  const [novoNomeParte, setNovoNomeParte] = useState('');
  const [formModalState, setFormModalState] = useState(false);
  const [tituloSelecionado, setTituloSelecionado] = useState('');
  const [componenteSelecionado, setComponenteSelecionado] = useState('');

  // Modal Questão States - Begin
  const [formQuestaoModalState, setFormQuestaoModalState] = useState(false);

  const [questaoModal, setQuestaoModal] = useState({} as iQuestaoModal);
  const [questaoModalAlterada, setQuestaoModalAlterada] = useState(false);

  const [questoesRelacaoModal, setQuestoesRelacaoModal] = useState<
    QuestaoRelacao[]
  >([]);

  const [questoesAnteriores, setQuestoesAnteriores] = useState<Questao[]>([]);

  const [descricaoNovoValorSelecao, setDescricaoNovoValorSelecao] =
    useState('');

  const [valoresSelecaoQuestaoModal, setValoresSelecaoQuestaoModal] = useState<
    QuestaoValorSelecao[]
  >([]);

  const [questaoValoresSelecaoRelacao, setQuestaoValoresSelecaoRelacao] =
    useState<RelacaoAgrupadoType[]>([]);

  const [tiposQuestao, setTiposQuestao] = useState([] as TipoQuestao[]);
  // Modal Questão States - End

  const [atualizandoOrdem, setAtualizandoOrdem] = useState(false);

  const [ordemAlterada, setOrdemAlterada] = useState(false);

  const [idsQuestoesRelacao, setIdsQuestoesRelacao] = useState<number[]>();
  const [definirDefault, setDefinirDefault] = useState(0);
  const [abrindoModal, setAbrindoModal] = useState(false);
  const [valorDefault, setValorDefault] = useState({} as QuestaoValorSelecao);
  const [configuraPartesEditing, setConfiguraPartesEditing] = useState(
    {} as ParteModel,
  );
  const [listaTabelaGenerica, setListaTabelaGenerica] = useState(
    [] as iTabelaGenerica[],
  );
  const [tabelaSelecionada, setTabelaSelecionada] = useState(
    {} as iTabelaGenerica,
  );

  const [dependenciasSalvar, setDependenciasSalvar] = useState<any[]>([]);

  // (S)eleção e (IM)agem
  const tiposSelecao = ['S', 'IM'];

  const { Option } = Select;

  // Futuramente pode ser uma estrutura em banco
  const componentesProduto: any[] = [
    {
      produtoId: 23,
      componente: 'FotovoltaicaInfoUsina',
      descricao: 'Informações Gerais da Usina',
    },
  ];

  function getQuestoesParte(parteId: number): Array<Questao> {
    const questoesFiltered = questoes.filter(
      questao => questao.parteId === parteId,
    );

    return questoesFiltered;
  }

  async function carregaTiposQuestao() {
    const response = await api.get(`/configuracao/listas/tipo-questao`);

    setTiposQuestao(response.data);
  }

  async function carregarTabelaGenerica() {
    const response = await api.get(`/tabela-generica`);

    const lista = response.data as iTabelaGenerica[];

    setListaTabelaGenerica(lista.filter(item => item.ativo));
  }

  async function listaModeloSuccao() {
    const response = await api.get('/integracao/succao/modelo-succao');

    setModeloSuccao(response.data);
  }

  async function carregaQuestaoValoresSelecaoRelacao(questaoAtual: Questao) {
    const { id, tipo } = questaoAtual;

    if (!tiposSelecao.includes(tipo)) return;

    try {
      setLoading(true);

      const response = await api.get(
        `/questao-valor-selecao-relacao/questao/${id}/agrupado`,
      );

      setQuestaoValoresSelecaoRelacao(response.data);
    } finally {
      setLoading(false);
    }
  }

  async function carregaPartes() {
    if (!produto) {
      return;
    }

    const response = await api.get(`/produto/${produto.id}/partes`);

    if (!response?.data) return;

    setPartes(response.data);

    response.data.forEach((parte: ParteModel) => {
      dropdownParteState.push({ parteId: parte.id, value: false });
    });
  }

  async function carregaQuestoes() {
    setQuestoes([]);

    partes.forEach(async (parte: ParteModel) => {
      const { id } = parte;

      const response = await api.get(`/questao/parte/${id}`);

      const questao = response.data as Questao[];

      setQuestoes(prevData => [...prevData, ...questao]);
    });
  }

  async function getDetalhesQuestao(questao: Questao): Promise<Questao> {
    const response = await api.get(`/questao/${questao.id}`);

    console.log(response.data);
    return response.data;
  }

  function carregaTitulos(parteId: number) {
    const questoesParteArray = getQuestoesParte(parteId);

    setQuestoesTipoTitulo(
      questoesParteArray.filter(item => item.tipo === 'TI'),
    );
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaPartes();
    carregaTiposQuestao();
    carregarTabelaGenerica();
  }, []);

  useEffect(() => {
    if (abrindoModal && valoresSelecaoQuestaoModal.length > 0) {
      const valoresFiltrados = valoresSelecaoQuestaoModal.filter(
        valor => valor.isDefault === true,
      );
      if (valoresFiltrados.length > 0) {
        setDefinirDefault(valoresFiltrados[0].id ?? 0);
      } else {
        setDefinirDefault(0);
      }
      setAbrindoModal(false);
    }
  }, [valoresSelecaoQuestaoModal]);

  useEffect(() => {
    carregaQuestoes();
    listaModeloSuccao();

    return () => {
      setQuestoes([]);
    };
  }, [partes]);

  useEffect(() => {
    if (!questaoModal.id) {
      return;
    }

    const questao = questaoModal;

    setValoresSelecaoQuestaoModal(questao.QuestaoValorSelecao);
    setValorDefault(questao as QuestaoValorSelecao);

    carregaTitulos(questao.parteId);

    (async () => {
      const questoesParteModal = getQuestoesParte(questaoModal.parteId);

      setQuestoesRelacaoModal(questao.questaoRelacoes);

      const novoIdsQuestoesRelacao =
        questao?.questaoRelacoes?.map(
          questaoRelacao => questaoRelacao.questaoRelacaoId,
        ) || [];

      setIdsQuestoesRelacao(novoIdsQuestoesRelacao);

      const questoesAnterioresModal = questoesParteModal?.filter(
        questaoParte =>
          questaoParte.dataHoraFim === null &&
          ['S', 'IM', 'N'].includes(questaoParte.tipo) && // lista questões tipo (S)eleção ou (IM)agem
          questaoParte.ordem < questaoModal.ordem, // somente questões anteriores
      );

      setQuestoesAnteriores(questoesAnterioresModal);

      setAbrindoModal(true);

      setDependenciasSalvar([]);
      setFormQuestaoModalState(true);
    })();
  }, [questaoModal]);

  useEffect(() => {
    if (loading) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [loading]);

  async function handleDesativaParte(parte: ParteModel) {
    async function desativaParte() {
      await api.post(`/parte/${parte.id}/desativa`);

      const newProdutos = partes.filter(
        (part: ParteModel) => part.id !== parte.id,
      );

      setPartes(newProdutos);
    }

    Swal.fire({
      title: `Desativar parte ${parte.nome}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, desativar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        toast.promise(desativaParte(), {
          loading: 'Aguarde...',
          success: <b>Tudo certo!</b>,
          error: <b>Algo de errado aconteceu.</b>,
        });
      }
    });
  }

  async function handleAdicionaNovaParte() {
    if (!novaParteNome || !produto) return;

    const response = await api.post('/parte', {
      nome: novaParteNome,
      produtoId: produto.id,
    });

    setPartes([...partes, response.data]);

    setNovaParteNome('');
  }

  if (!produto) {
    return <></>;
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setQuestaoModal({
      ...questaoModal,
      [name]: value,
    } as iQuestaoModal);

    setQuestaoModalAlterada(true);
  }

  function handleSelectChangeTipo(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const { currentTarget } = event;

    const value = currentTarget.getAttribute('value');

    setQuestaoModal({
      ...questaoModal,
      tipo: value,
    } as iQuestaoModal);

    setQuestaoModalAlterada(true);
  }

  function handleTituloChange(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const { currentTarget } = event;

    const value = currentTarget.getAttribute('value');

    setTituloSelecionado(String(value));
  }

  function handleComponenteChange(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const { currentTarget } = event;

    const value = currentTarget.getAttribute('value');

    setComponenteSelecionado(String(value));

    setQuestaoModal({
      ...questaoModal,
      descricao: String(value),
    } as iQuestaoModal);

    setQuestaoModalAlterada(true);
  }

  function handleTabelaChange(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    const { currentTarget } = event;

    const value = currentTarget.getAttribute('value');

    const listaFiltrada = listaTabelaGenerica.find(
      item => item.id === Number(value ?? 0),
    );
    if (!listaFiltrada) return;

    setQuestaoModal({
      ...questaoModal,
      usarRegraColuna: listaFiltrada.disponibilizarRegras ?? false,
      nomeVariavel: listaFiltrada.disponibilizarRegras
        ? listaFiltrada?.labelRegras
        : '',
      tabelaGenericaId: listaFiltrada.id,
      descricao: `${listaFiltrada.nome}`,
    } as iQuestaoModal);
    setTabelaSelecionada(listaFiltrada);

    setQuestaoModalAlterada(true);
  }

  function handleEditaParte(parte: ParteModel) {
    setNovoNomeParte(parte.nome);
    setParteEditing(parte);
    setConfiguraPartesEditing(parte);
    setFormModalState(!formModalState);
  }

  async function handleUpdateParte() {
    try {
      setLoading(true);
      await api.put(`/parte/${parteEditing.id}`, {
        nome: novoNomeParte,
        ordem: configuraPartesEditing.ordem,
        avancarPara: configuraPartesEditing.avancarPara,
        componente: configuraPartesEditing.componente,
        requisito: configuraPartesEditing.requisito,
        requisitoResultado: configuraPartesEditing.requisitoResultado,
        executarValidacaoRegras: configuraPartesEditing.executarValidacaoRegras,
      });

      setFormModalState(false);
      setConfiguraPartesEditing({} as ParteModel);
      carregaPartes();
    } finally {
      setLoading(false);
    }
  }

  async function handleNovaQuestao(parteId: number, keepTitulo = false) {
    const questoesParteArray = getQuestoesParte(parteId);

    carregaTitulos(parteId);

    if (!keepTitulo) setTituloSelecionado('');

    setQuestaoModal({
      parteId,
      ordem: questoesParteArray.length,
    } as iQuestaoModal);

    setQuestoesRelacaoModal([]);

    carregaPartes();

    setAbrindoModal(true);

    setComponenteSelecionado('');
    setTabelaSelecionada({} as iTabelaGenerica);

    setDependenciasSalvar([]);
    setFormQuestaoModalState(true);

    setQuestaoModalAlterada(true);
  }

  function handleNovaQuestaoAgrupada(questao: Questao) {
    if (!questao) return;

    setTituloSelecionado(String(questao.id));

    handleNovaQuestao(questao.parteId, true);
  }

  async function salvarVariaveis(
    questaoId: number,
    usarRegras: boolean,
    produtoId: number,
    valorVariavel?: string,
  ) {
    const response = await api.post(`/questao/salvar-variaveis`, {
      questaoId,
      usarRegras,
      valorVariavel,
      produtoId,
    });
    return response.data;
  }

  async function insereQuestao() {
    const response = await api.post(`/questao`, questaoModal);
    return response.data;
  }

  async function atualizaObrigatorio() {
    const response = await api.put(
      `/questao/obrigatorio/${questaoModal.id}`,
      questaoModal.isObrigatorio,
    );
    setQuestaoModalAlterada(true);
    return response.data;
  }

  async function updateOrdem(
    questoesReordenadas?: Questao[],
  ): Promise<Questao[]> {
    try {
      setAtualizandoOrdem(true);

      toast.loading('Salvando ordenação...');

      const newQuestoes = await api.put(
        `/questao/ordenar`,
        questoesReordenadas ?? questoes,
      );

      setQuestoes(newQuestoes.data);

      setOrdemAlterada(false);

      return newQuestoes.data;
    } finally {
      setAtualizandoOrdem(false);
      toast.dismiss();
    }
  }

  async function adicionaAbaixoQuestaoTitulo(): Promise<Questao[]> {
    const isInsert = !questaoModal.id;

    const questoesParteArray = getQuestoesParte(questaoModal.parteId);

    if (isInsert) questoesParteArray.push(questaoModal);

    let oldIndex = 0;
    let newIndex = 0;

    const newQuestoes = questoesParteArray.map((thisQuestao, ordem) => {
      if (thisQuestao.id === questaoModal.id) {
        oldIndex = ordem;
      } else if (thisQuestao.id === Number(tituloSelecionado)) {
        newIndex = ordem + 1;
      }

      return thisQuestao;
    });

    if (!tituloSelecionado) newIndex = oldIndex;

    const questoesReordenadas = arrayMove(newQuestoes, oldIndex, newIndex);

    setQuestoes(questoesReordenadas);

    const questoesOrdenadas = await updateOrdem(questoesReordenadas);
    return questoesOrdenadas;
  }

  async function salvaQuestao(salvarInfoVariaveis: boolean) {
    let idUsar = 0;
    if (questaoModal.id) {
      console.log(questaoModal);
      await api.put(`/questao/${questaoModal.id}`, {
        ...questaoModal,
      });

      idUsar = questaoModal.id;
    } else {
      const questao = await insereQuestao();
      idUsar = questao.id;
    }

    if (idUsar && salvarInfoVariaveis)
      await salvarVariaveis(
        idUsar,
        questaoModal.usarRegraColuna,
        produto?.id ?? 0,
        questaoModal.nomeVariavel,
      );

    await queryClient.invalidateQueries(`questoes-parteId-${parteEditing?.id}`);
  }

  async function handleSalvaQuestao() {
    let salvarInfoVariaveis = true;

    async function handleSalvaValoresRelacao() {
      if (!questaoValoresSelecaoRelacao.length) return;

      await api.post(`/questao-valor-selecao-relacao`, {
        questaoId: questaoModal.id,
        relacaoAgrupada: questaoValoresSelecaoRelacao,
      });
    }

    if (!questaoModal?.tipo) {
      toast.error('Tipo de questão deve ser informada!');
      return;
    }

    if (
      questaoModal.tipo === 'COMP' &&
      (!componenteSelecionado ||
        componenteSelecionado === undefined ||
        componenteSelecionado === null ||
        componenteSelecionado === 'null')
    ) {
      toast.error('Um componente deve ser selecionado');
      return;
    }

    if (questaoModal.tipo === 'TB' && !questaoModal.tabelaGenericaId) {
      toast.error('Uma tabela deve ser selecionada');
      return;
    }

    if (
      questaoModal.tipo !== 'COMP' &&
      questaoModal.tipo !== 'TB' &&
      !questaoModal?.descricao
    ) {
      toast.error('Descrição deve ser informada!');
      return;
    }

    // Adicionar aqui as validações de obrigatoriedade.
    if (questaoModal.usarRegraColuna === true) {
      if (!questaoModal.nomeVariavel) {
        toast.error(
          'Quando marcada para usar nas regras, deve ser informado um nome para usar essa variável',
        );
        return;
      }

      if (produto && produto.id) {
        // Validar se a variável está sendo usada por outro item.
        const retornoUsar = await api.post(`/questao/validar-regra-item`, {
          produtoId: produto.id,
          nomeVariavel: questaoModal.nomeVariavel,
          questaoId: questaoModal.id,
        });

        let podeSalvar = true;

        podeSalvar = retornoUsar.data.podeSalvar;

        if (!podeSalvar) {
          const { mensagem } = retornoUsar.data;

          if (mensagem === 'msg-existe-regra-mesmo-nome') {
            toast.error(
              'Já existe outra variável de regra com esse nome cadastrada para este produto. Utilize outro.',
            );
            return;
          }

          if (mensagem === 'msg-variavel-usada-em-regra') {
            toast.error(
              'A variável já está sendo usada em regras. Portanto, as modificações dela não serão feitas',
            );
            salvarInfoVariaveis = false;
          }
        }
      }
    }

    if (
      questaoModal?.valorMaximo &&
      Number(questaoModal?.valorMinimo ?? 0) >
        Number(questaoModal?.valorMaximo ?? 0)
    ) {
      toast.error('Valor mínimo não pode ser maior que valor máximo!');
      return;
    }

    try {
      setLoading(true);

      if (tituloSelecionado && !questaoModal?.id) {
        await adicionaAbaixoQuestaoTitulo();
      } else {
        await salvaQuestao(salvarInfoVariaveis);
      }

      await handleSalvaValoresRelacao();

      await carregaQuestoes();
    } finally {
      setComponenteSelecionado('');
      setTabelaSelecionada({} as iTabelaGenerica);
      setLoading(false);
    }

    setAbrindoModal(false);

    setDependenciasSalvar([]);
    setFormQuestaoModalState(false);
  }

  async function handleSetQuestaoAtualModal(questaoBuscar: Questao) {
    if (!questaoBuscar) return;

    setLoading(true);

    const questao = await getDetalhesQuestao(questaoBuscar);

    await carregaQuestaoValoresSelecaoRelacao(questao);

    if (questao.tipo === 'COMP') setComponenteSelecionado(questao.descricao);

    if (questao.tipo === 'TB') {
      const tabela = listaTabelaGenerica.find(
        item => item.id === questao.tabelaGenericaId ?? 0,
      );
      if (tabela) {
        setTabelaSelecionada(tabela);
      }
    }

    const questaoTemVariavel = (questaoBuscar.RegraItemColuna?.length ?? 0) > 0;

    const nomeVariavel = questaoBuscar.RegraItemColuna?.[0]?.nome ?? '';

    console.log(questao);
    setQuestaoModal({
      ...questao,
      nomeVariavel,
      usarRegraColuna: questaoTemVariavel,
    } as iQuestaoModal);

    setLoading(false);
  }

  async function handleDesativaQuestao(questao: Questao) {
    async function desativaQuestao() {
      await api.put(`/questao/${questao.id}/desativar`, questao);

      carregaQuestoes();
    }

    Swal.fire({
      title: `Desativar questão ${questao.descricao}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, desativar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        toast.promise(desativaQuestao(), {
          loading: 'Aguarde...',
          success: <b>Tudo certo!</b>,
          error: <b>Algo de errado aconteceu.</b>,
        });
      }
    });
  }

  function handleToggleDropdownQuestao(parteId: number) {
    const thisObject = dropdownParteState.find(x => x.parteId === parteId);

    if (thisObject) {
      thisObject.value = !thisObject.value;

      setDropdownParteState([...dropdownParteState, thisObject]);
    }
  }

  function montaQuestoes(parteId: number) {
    const questoesParteArray = getQuestoesParte(parteId);

    if (!questoesParteArray.length) {
      return <></>;
    }

    const thisDropdownStateParte =
      dropdownParteState.find(x => x.parteId === parteId)?.value ?? false;

    const SortableItem = SortableElement(({ questao }: any) => {
      return (
        <QuestaoContainerRow
          key={`questao_${questao.id}`}
          enabled={!questao.dataHoraFim}
          tipo={questao.tipo}
        >
          <Col xs="12" sm="9">
            <h4>
              {questao.descricao}
              {questao.unidadeMedida ? ` (${questao.unidadeMedida})` : ''}
            </h4>
          </Col>

          <Col xs="6" sm="1">
            <h3>
              <Badge color="primary">
                {tiposQuestao.find(tipo => tipo.key === questao.tipo)?.value}
                {}
                {questao.isObrigatorio ? ' *' : ''}
              </Badge>
            </h3>
          </Col>

          <Col xs="6" sm="2">
            {!questao.dataHoraFim && (
              <AcoesDiv>
                {questao.tipo === 'TI' && (
                  <Button
                    size="sm"
                    className="btn-icon"
                    color="primary"
                    type="button"
                    onMouseDown={() => {
                      handleNovaQuestaoAgrupada(questao);
                    }}
                  >
                    <FaPlusCircle />
                  </Button>
                )}

                <Button
                  size="sm"
                  className="btn-icon"
                  color="primary"
                  type="button"
                  onMouseDown={() => handleSetQuestaoAtualModal(questao)}
                >
                  <FaPencilAlt />
                </Button>

                <Button
                  size="sm"
                  className="btn-icon"
                  color="danger"
                  type="button"
                  onMouseDown={() => handleDesativaQuestao(questao)}
                >
                  <FaRegEyeSlash />
                </Button>
              </AcoesDiv>
            )}
          </Col>
        </QuestaoContainerRow>
      );
    });

    const SortableList = SortableContainer(({ items }: any) => {
      return (
        <ul>
          {items.map((value: Questao, index: number) => (
            <SortableItem
              key={`item_${index}_${value.id}`}
              index={index}
              questao={value}
              disabled={!!value.dataHoraFim || atualizandoOrdem}
              distance={1}
            />
          ))}
        </ul>
      );
    });

    return (
      <Collapse isOpen={thisDropdownStateParte}>
        <SortableList
          items={questoesParteArray}
          onSortEnd={({ oldIndex, newIndex }: any) => {
            const questoesReordenadas = arrayMove(
              questoesParteArray,
              oldIndex,
              newIndex,
            );

            setQuestoes(questoesReordenadas);

            setOrdemAlterada(true);
          }}
        />
      </Collapse>
    );
  }

  async function handleAdicionaValorSelecao() {
    if (!descricaoNovoValorSelecao) return;

    try {
      setLoading(true);

      let questaoId = questaoModal?.id;

      if (!questaoModal?.descricao || !questaoModal?.tipo) {
        toast.error('Dados incompletos!');
        return;
      }

      if (!questaoId) {
        let questao = null;
        if (tituloSelecionado) {
          const questoesReordenadas = await adicionaAbaixoQuestaoTitulo();
          questao = questoesReordenadas.find(
            questaoReordenada =>
              questaoReordenada.descricao === questaoModal.descricao,
          );
        } else {
          questao = await insereQuestao();
        }
        setQuestaoModal(questao);
        questaoId = questao.id;
      }

      const valorSelecao = await api.post(`/questao-valor-selecao`, {
        questaoId,
        descricao: descricaoNovoValorSelecao,
      });

      setValoresSelecaoQuestaoModal([
        ...valoresSelecaoQuestaoModal,
        valorSelecao.data,
      ]);
      setDescricaoNovoValorSelecao('');
    } finally {
      setLoading(false);
    }
  }

  async function handleChangeValorDefault(
    event: React.ChangeEvent<HTMLInputElement>,
    idDefault: any,
  ) {
    const newQuestaoValoresSelecao = valoresSelecaoQuestaoModal.map(
      thisValorSelecao => {
        if (thisValorSelecao.id !== idDefault) return thisValorSelecao;
        return {
          ...thisValorSelecao,
          editado: true,
          isDefault: event.target.checked === true,
        };
      },
    );

    setValoresSelecaoQuestaoModal(newQuestaoValoresSelecao);
    if (event.target.checked === true) {
      setDefinirDefault(idDefault);
    } else {
      setDefinirDefault(0);
    }
  }

  async function handleSalvaEdicaoValorSelecao(
    valorSelecao: QuestaoValorSelecao,
  ) {
    try {
      setLoading(true);

      const { id, descricao, questaoId } = valorSelecao;

      await api.put(`/questao-valor-selecao/${id}`, {
        descricao,
        questaoId,
        isDefault: id === definirDefault,
      });

      const newValorSelecao = {
        ...valorSelecao,
        editado: false,
        isDefault: id === definirDefault,
      };

      const newQuestaoValoresSelecao = valoresSelecaoQuestaoModal.map(
        thisValorSelecao =>
          thisValorSelecao === valorSelecao
            ? newValorSelecao // altera valor
            : thisValorSelecao, // mantém valor
      );

      setValoresSelecaoQuestaoModal(newQuestaoValoresSelecao);
    } finally {
      setLoading(false);
    }
  }

  async function handleRemoveValorSelecao(valorSelecao: QuestaoValorSelecao) {
    try {
      setLoading(true);

      await api.delete(`/questao-valor-selecao/${valorSelecao.id}`);

      const newQuestaoValoresSelecao = valoresSelecaoQuestaoModal.filter(
        x => x !== valorSelecao,
      );

      setValoresSelecaoQuestaoModal(newQuestaoValoresSelecao);
    } finally {
      setLoading(false);
    }
  }

  function handleChangeDescricaoValorSelecao(
    valorSelecao: QuestaoValorSelecao,
    novaDescricao: string,
  ) {
    const newValorSelecao = {
      ...valorSelecao,
      descricao: novaDescricao,
      editado: true,
    };

    const newQuestaoValoresSelecao = valoresSelecaoQuestaoModal.map(
      thisValorSelecao =>
        thisValorSelecao === valorSelecao
          ? newValorSelecao // altera valor
          : thisValorSelecao, // mantém valor
    );

    setValoresSelecaoQuestaoModal(newQuestaoValoresSelecao);
  }

  function createQuestaoRelacao(questao: Questao): QuestaoRelacao {
    return {
      questaoRelacaoId: questao.id,
      questao,
    };
  }

  function handleChangeQuestoesAssociadas(questoesId: number[]) {
    const questoesAssociadas = questoes.filter(questao =>
      questoesId.includes(questao.id),
    );

    const questoesRelacoesAssociadas = questoesAssociadas.map(
      questaoAssociada => createQuestaoRelacao(questaoAssociada),
    );

    setQuestoesRelacaoModal(questoesRelacoesAssociadas);
  }

  async function handleQuestaoAssociadaSelected(questaoRelacaoId: number) {
    await api.post(`/questao-relacao/questao-selecao-associada`, {
      questaoId: questaoModal.id,
      questaoRelacaoId,
    });

    toast.success('Dependência adicionada!');
  }

  async function handleQuestaoAssociadaDeselected(questaoRelacaoId: number) {
    await api.delete(`/questao-relacao/questao-selecao-associada`, {
      params: {
        questaoId: questaoModal.id,
        questaoRelacaoId,
      },
    });

    toast.success('Dependência removida!');

    const novoQuestaoValoresSelecaoRelacacao = questaoValoresSelecaoRelacao.map(
      valorSelecao => ({
        ...valorSelecao,
        valoresDependencia: valorSelecao.valoresDependencia.filter(
          valor => valor.questaoId !== questaoRelacaoId,
        ),
      }),
    );

    setQuestaoValoresSelecaoRelacao(novoQuestaoValoresSelecaoRelacacao);
  }

  function handleChangeValorRelacao(
    novoValoresDependenciaQuestaoRelacao: QuestaoValorSelecao[],
    relacoesValorSelecao: RelacaoAgrupadoType | undefined,
    questaoValorSelecao: QuestaoValorSelecao,
    questaoId: number,
  ) {
    // copia objeto a ser alterado
    const questaoValoresSelecaoRelacaoExistente =
      questaoValoresSelecaoRelacao.find(
        x =>
          x.questaoValorSelecaoId ===
          relacoesValorSelecao?.questaoValorSelecaoId,
      );

    if (questaoValoresSelecaoRelacaoExistente) {
      const valoresSelecaoRelacaoOutrasQuestoes =
        questaoValoresSelecaoRelacaoExistente.valoresDependencia.filter(
          x => x.questaoId !== questaoId,
        );

      // altera objeto
      questaoValoresSelecaoRelacaoExistente.valoresDependencia = [
        ...novoValoresDependenciaQuestaoRelacao,
        ...valoresSelecaoRelacaoOutrasQuestoes,
      ];

      // remove objeto a ser alterado do array original
      const questaoValoresSelecaoRelacaoAtualizado =
        questaoValoresSelecaoRelacao.filter(
          x =>
            x.questaoValorSelecaoId !==
            relacoesValorSelecao?.questaoValorSelecaoId,
        );

      // adiciona objeto alterado ao array
      questaoValoresSelecaoRelacaoAtualizado.push(
        questaoValoresSelecaoRelacaoExistente,
      );

      setQuestaoValoresSelecaoRelacao(questaoValoresSelecaoRelacaoAtualizado);
    } else {
      const { descricao, id } = questaoValorSelecao;

      const novoQuestaoValoresSelecaoRelacao = {
        questaoValorSelecaoId: id,
        descricao,
        valoresDependencia: novoValoresDependenciaQuestaoRelacao,
      } as RelacaoAgrupadoType;

      setQuestaoValoresSelecaoRelacao([
        ...questaoValoresSelecaoRelacao,
        novoQuestaoValoresSelecaoRelacao,
      ]);
    }

    setQuestaoModalAlterada(true);
  }

  function renderRelacaoEntreValoresSelecao(
    tipo: string,
    questaoId: number,
    questoesValoresSelecao?: QuestaoValorSelecao[],
  ) {
    if (!questoesValoresSelecao) {
      return <></>;
    }

    return (
      <Col>
        {valoresSelecaoQuestaoModal.map(valorSelecaoQuestaoModal => {
          const { id, descricao } = valorSelecaoQuestaoModal;

          const relacoesValorSelecao = questaoValoresSelecaoRelacao.find(
            x => x.questaoValorSelecaoId === id,
          );

          const valoresDependencia =
            relacoesValorSelecao?.valoresDependencia.filter(
              dependencia => dependencia.questaoId === questaoId,
            );

          // não sugerir opções já relacionadas
          const opcoes =
            questoesValoresSelecao?.filter(
              valorSelecao =>
                valorSelecao.id !==
                valoresDependencia?.find(
                  dependencia => dependencia.id === valorSelecao.id,
                )?.id,
            ) ?? [];

          return (
            <SelecaoRelacaoContainer>
              <Autocomplete
                multiple
                id={`tags-standard-${id}`}
                options={opcoes}
                value={valoresDependencia}
                onChange={(_, novaQuestaoValorSelecao) =>
                  handleChangeValorRelacao(
                    novaQuestaoValorSelecao,
                    relacoesValorSelecao,
                    valorSelecaoQuestaoModal,
                    questaoId,
                  )
                }
                filterSelectedOptions
                getOptionLabel={option => option?.descricao}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${descricao} depende dos valores:`}
                    placeholder="Selecione..."
                  />
                )}
              />
            </SelecaoRelacaoContainer>
          );
        })}
      </Col>
    );
  }

  /* function handleChangeQuestaoValoresExibir(
    questaoRelacao: QuestaoRelacao,
    novaSelecaoAssociadaIds: number[],
  ) {
    async function updateQuestaoSelecaoAssociadaId() {
      api.put(`/questao-relacao/questao-selecao-associada`, {
        questaoId: questaoModal.id,
        questaoRelacaoId: questaoRelacao.questaoRelacaoId,
        questaoSelecaoAssociadaIds: novaSelecaoAssociadaIds,
      });

      toast.success('Alteração salva!');
    }

    const newQuestoesRelacaoModal = questoesRelacaoModal.map(
      questaoRelacaoModal =>
        questaoRelacaoModal.questaoRelacaoId === questaoRelacao.questaoRelacaoId
          ? {
              ...questaoRelacaoModal,
              questaoSelecaoAssociadaIds: novaSelecaoAssociadaIds,
            }
          : questaoRelacaoModal,
    );

    setQuestoesRelacaoModal(newQuestoesRelacaoModal);

    updateQuestaoSelecaoAssociadaId();
  } */

  function handleChangeDependencia(
    questaoId: number,
    tipo: string,
    questaoRelacao: QuestaoRelacao,
    campo: string,
    valor: string | any,
    novaSelecaoAssociadaIds?: number[] | number | string,
  ) {
    const buscar = dependenciasSalvar.find(
      (item: any) => item.questaoId === questaoId,
    );

    if (buscar) {
      // monta objeto
      setDependenciasSalvar([
        ...dependenciasSalvar.filter(
          (item: any) => item.questaoId !== questaoId,
        ),
        {
          tipo,
          questaoId,
          novaSelecaoAssociadaIds:
            campo === 'selecao'
              ? novaSelecaoAssociadaIds
              : buscar.novaSelecaoAssociadaIds,
          condicao: campo === 'condicao' ? valor : buscar.condicao,
          valor: campo === 'valor' ? valor : buscar.valor,
        },
      ]);
    } else {
      // monta objeto
      setDependenciasSalvar([
        ...dependenciasSalvar.filter(
          (item: any) => item.questaoId !== questaoId,
        ),
        {
          tipo,
          questaoId,
          novaSelecaoAssociadaIds,
          condicao: campo === 'condicao' ? valor : questaoRelacao.condicao,
          valor: campo === 'valor' ? valor : questaoRelacao.valor,
        },
      ]);
    }
  }

  async function handleSaveDependencia(questaoId: number) {
    const buscar = dependenciasSalvar.find(
      (item: any) => item.questaoId === questaoId,
    );

    if (buscar) {
      if (
        !buscar.novaSelecaoAssociadaIds ||
        buscar.novaSelecaoAssociadaIds.length === 0
      ) {
        if (buscar.condicao === 'N' && !buscar.valor) {
          toast.error(
            'Dependência de um campo numérico depende de informar uma condição e um valor',
          );
          return;
        }
      }

      // eslint-disable-next-line no-inner-declarations
      async function updateQuestaoSelecaoAssociadaId() {
        await api.put(`/questao-relacao/questao-selecao-associada`, {
          questaoId: questaoModal.id,
          questaoRelacaoId: buscar.questaoId,
          questaoSelecaoAssociadaIds: buscar.novaSelecaoAssociadaIds,
          condicao: buscar.condicao,
          valor: buscar.valor,
        });

        toast.success('Alteração salva!');
      }

      if (buscar.novaSelecaoAssociadaIds) {
        const newQuestoesRelacaoModal = questoesRelacaoModal.map(
          questaoRelacaoModal =>
            questaoRelacaoModal.questaoRelacaoId === buscar.questaoId
              ? {
                  ...questaoRelacaoModal,
                  questaoSelecaoAssociadaIds: buscar.novaSelecaoAssociadaIds,
                  condicao: buscar.condicao,
                  valor: buscar.valor,
                }
              : questaoRelacaoModal,
        );

        setQuestoesRelacaoModal(newQuestoesRelacaoModal);

        setDependenciasSalvar([
          ...dependenciasSalvar.filter(
            (item: any) => item.questaoId !== questaoId,
          ),
        ]);
      } else {
        const newQuestoesRelacaoModal = questoesRelacaoModal.map(
          questaoRelacaoModal =>
            questaoRelacaoModal.questao.id === buscar.questaoId
              ? {
                  ...questaoRelacaoModal,
                  condicao: buscar.condicao,
                  valor: buscar.valor,
                }
              : questaoRelacaoModal,
        );

        setQuestoesRelacaoModal(newQuestoesRelacaoModal);

        setDependenciasSalvar([
          ...dependenciasSalvar.filter(
            (item: any) => item.questaoId !== questaoId,
          ),
        ]);
      }

      await updateQuestaoSelecaoAssociadaId();
    } else {
      toast.error('Não foi possível disparar o processo para salvar');
    }
  }

  function renderQuestoesRelacionadas() {
    if (!questoesAnteriores.length) return <></>;

    return (
      <ContainerInterno>
        <h5 className="text-muted">Dependência de questões</h5>

        <FormGroup className="mt-3">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Selecione as questões"
            onChange={handleChangeQuestoesAssociadas}
            onSelect={handleQuestaoAssociadaSelected}
            onDeselect={handleQuestaoAssociadaDeselected}
            optionLabelProp="label"
            defaultValue={idsQuestoesRelacao}
          >
            {questoesAnteriores.map(questaoAnterior => (
              <Option
                key={`qa_${questaoAnterior.id}`}
                value={questaoAnterior.id}
                label={questaoAnterior.descricao}
              >
                {questaoAnterior.descricao}
              </Option>
            ))}
          </Select>

          {questoesRelacaoModal.map(questaoRelacao => {
            const { questao, questaoSelecaoAssociadaIds } = questaoRelacao;
            const questaoValoresSelecao =
              questao?.QuestaoValorSelecao as QuestaoValorSelecao[];

            const questaoUsar = questoes.find(
              (item: any) => item.id === questaoRelacao.questao.id,
            );

            const questaoRel = questaoRelacao.questao.id;

            const buscarSalvar = dependenciasSalvar.find(
              (item: any) => item.questaoId === questaoRelacao.questao.id,
            );

            const tipo = questaoUsar?.tipo ?? '';

            if (!buscarSalvar) {
              setDependenciasSalvar([
                ...dependenciasSalvar.filter(
                  (item: any) => item.questaoId !== questaoRel,
                ),
                {
                  tipo,
                  questaoId: questaoRel,
                  novaSelecaoAssociadaIds: questaoSelecaoAssociadaIds,
                  condicao: questaoRelacao.condicao,
                  valor: questaoRelacao.valor,
                },
              ]);
            }

            return (
              <>
                <Col className="mt-3">
                  <h4 className="text-muted">
                    Exibir <strong>{questaoModal.descricao}</strong> se resposta
                    de <strong>{questao.descricao}</strong> for:
                  </h4>
                </Col>

                {['IM', 'S'].includes(tipo) && (
                  <FormGroup>
                    <Row>
                      <Col sm="8">
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Sempre exibir"
                          onChange={values =>
                            handleChangeDependencia(
                              questaoRelacao.questao.id,
                              tipo,
                              questaoRelacao,
                              'selecao',
                              '',
                              values,
                            )
                          }
                          optionLabelProp="label"
                          defaultValue={
                            buscarSalvar
                              ? buscarSalvar.questaoSelecaoAssociadaIds
                              : questaoSelecaoAssociadaIds
                          }
                        >
                          {questaoValoresSelecao.map(valorSelecao => (
                            <Option
                              key={`qa_${valorSelecao.id}`}
                              value={valorSelecao?.id ?? 0}
                              label={valorSelecao.descricao}
                            >
                              {valorSelecao.descricao}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col sm="2">
                        <Button
                          color="primary"
                          type="button"
                          hidden={
                            !dependenciasSalvar.find(
                              (item: any) =>
                                item.questaoId === questaoRelacao.questao.id,
                            )
                          }
                          onClick={() =>
                            handleSaveDependencia(questaoRelacao.questao.id)
                          }
                        >
                          <FaCheckCircle />
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                )}

                {tipo === 'N' && (
                  <>
                    <FormGroup>
                      <Row>
                        <Col sm="2">
                          <Select
                            placeholder="Condição"
                            style={{ width: '100%' }}
                            optionLabelProp="label"
                            value={
                              buscarSalvar
                                ? buscarSalvar.condicao
                                : questaoRelacao.condicao
                            }
                            onChange={value =>
                              handleChangeDependencia(
                                questaoRelacao.questao.id,
                                tipo,
                                questaoRelacao,
                                'condicao',
                                String(value),
                                [],
                              )
                            }
                          >
                            <Option
                              key={`condicao_1_${questaoRel}`}
                              value="=="
                              label="=="
                            >
                              ==
                            </Option>
                            <Option
                              key={`condicao_2_${questaoRel}`}
                              value="!="
                              label="!="
                            >
                              !=
                            </Option>
                            <Option
                              key={`condicao_3_${questaoRel}`}
                              value=">="
                              label=">="
                            >{`>=`}</Option>
                            <Option
                              key={`condicao_4_${questaoRel}`}
                              value="<="
                              label="<="
                            >{`<=`}</Option>
                            <Option
                              key={`condicao_5_${questaoRel}`}
                              value=">"
                              label=">"
                            >{`>`}</Option>
                            <Option
                              key={`condicao_6_${questaoRel}`}
                              value="<"
                              label="<"
                            >{`<`}</Option>
                          </Select>
                        </Col>
                        <Col sm="6">
                          <Input
                            placeholder="Valor esperado"
                            type="text"
                            value={
                              buscarSalvar
                                ? buscarSalvar.valor
                                : questaoRelacao.valor
                            }
                            onChange={value =>
                              handleChangeDependencia(
                                questaoRelacao.questao.id,
                                tipo,
                                questaoRelacao,
                                'valor',
                                String(value.target.value),
                                [],
                              )
                            }
                          />
                        </Col>
                        <Col sm="2">
                          <Button
                            color="primary"
                            type="button"
                            hidden={
                              !dependenciasSalvar.find(
                                (item: any) =>
                                  item.questaoId === questaoRelacao.questao.id,
                              )
                            }
                            onClick={() =>
                              handleSaveDependencia(questaoRelacao.questao.id)
                            }
                          >
                            <FaCheckCircle />
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}

                {tipo &&
                  tipo !== 'N' &&
                  renderRelacaoEntreValoresSelecao(
                    tipo,
                    questao.id,
                    questaoRelacao.questao.QuestaoValorSelecao,
                  )}
              </>
            );
          })}
        </FormGroup>
      </ContainerInterno>
    );
  }

  function onClosedModalQuestao() {
    setQuestaoModal({} as iQuestaoModal);
    setValoresSelecaoQuestaoModal([]);
    setQuestaoValoresSelecaoRelacao([]);
    setTituloSelecionado('');
    setQuestoesRelacaoModal([]);
    setIdsQuestoesRelacao(undefined);
    setQuestaoModalAlterada(false);
    setDependenciasSalvar([]);
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-tag" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  placeholder="Nova parte"
                  type="text"
                  value={novaParteNome}
                  onChange={text => setNovaParteNome(text.target.value)}
                />

                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleAdicionaNovaParte}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span className="btn-inner--text">Adicionar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {/* Modal edição parte */}
          <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={formModalState}
            toggle={() => setFormModalState(!formModalState)}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>
                      Edição produto {parteEditing.nome} (#
                      {parteEditing.id})
                    </small>
                  </div>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <small>Nome</small>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-tag" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="Nome do produto"
                          value={novoNomeParte}
                          type="text"
                          onChange={text => setNovoNomeParte(text.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <small>Ordem</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Tooltip title="Usado para ordenar a exibição das partes na tela nova.">
                              <Input
                                placeholder="Ordem"
                                value={configuraPartesEditing.ordem}
                                type="number"
                                onChange={text =>
                                  setConfiguraPartesEditing({
                                    ...configuraPartesEditing,
                                    ordem: Number(text.target.value),
                                  })
                                }
                              />
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <small>Avançar para</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Tooltip title="Indica para onde o sistema irá redirecionar após a conclusão da respectiva parte. Exemplo: Parte 1, quando concluída, vai para a parte 2.">
                              <Input
                                placeholder="Avançar para"
                                type="select"
                                value={Number(
                                  configuraPartesEditing.avancarPara,
                                )}
                                onChange={text =>
                                  setConfiguraPartesEditing({
                                    ...configuraPartesEditing,
                                    avancarPara: Number(text.target.value),
                                  })
                                }
                              >
                                <option value="">Selecione...</option>
                                <option value={0}>Resultado</option>
                                {partes
                                  .filter(
                                    parteFiltro =>
                                      parteFiltro.id !==
                                      configuraPartesEditing.id,
                                  )
                                  .map((parte: ParteModel) => {
                                    return (
                                      <option value={parte.id}>
                                        {parte.nome}
                                      </option>
                                    );
                                  })}
                              </Input>
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <small>Componente</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Tooltip title="São componentes usados ao invés de questões. Portanto, ao ser selecionado, as questões informadas na parte não serão usadas.">
                              <Input
                                placeholder="Componente"
                                type="select"
                                value={String(
                                  configuraPartesEditing.componente,
                                )}
                                onChange={text =>
                                  setConfiguraPartesEditing({
                                    ...configuraPartesEditing,
                                    componente: text.target.value,
                                  })
                                }
                              >
                                <option value="">Selecione...</option>
                                <option value="AdutoraBombeamento">
                                  AdutoraBombeamento
                                </option>
                                <option value="OpcoesLances">
                                  OpcoesLances
                                </option>
                                <option value="VisualizadorGeo">
                                  VisualizadorGeo
                                </option>
                                <option value="FotovoltaicaInfoUsina">
                                  FotovoltaicaInfoUsina
                                </option>
                                <option value="FotovoltaicaCadastroUC">
                                  FotovoltaicaCadastroUC
                                </option>
                              </Input>
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <small>Requisito</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Tooltip title="Indica qual parte é necessária ser concluída para acessar essa. Se null ou vazio, não tem pré-requisito. Exemplo, para acessar a parte 2, preciso concluir a parte 1.">
                              <Input
                                placeholder="Requsito"
                                type="select"
                                value={Number(configuraPartesEditing.requisito)}
                                onChange={text =>
                                  setConfiguraPartesEditing({
                                    ...configuraPartesEditing,
                                    requisito: Number(text.target.value),
                                  })
                                }
                              >
                                <option value="">Selecione...</option>
                                {partes
                                  .filter(
                                    parteFiltro =>
                                      parteFiltro.id !==
                                      configuraPartesEditing.id,
                                  )
                                  .map((parte: ParteModel) => {
                                    return (
                                      <option value={parte.id}>
                                        {parte.nome}
                                      </option>
                                    );
                                  })}
                              </Input>
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <InputGroup>
                            <Tooltip title="Indica se essa parte é obrigatória para acessar a tela de resultados. Exemplo: Para acessar a tela de resultados, preciso que as partes 3 e 4 estejam concluídas.">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      configuraPartesEditing.requisitoResultado ??
                                      false
                                    }
                                    onChange={event =>
                                      setConfiguraPartesEditing({
                                        ...configuraPartesEditing,
                                        requisitoResultado:
                                          event.target.checked,
                                      })
                                    }
                                    color="primary"
                                  />
                                }
                                label="Requisito resultado"
                              />
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <InputGroup>
                            <Tooltip title="Indica se essa parte, ao ser concluída, irá disparar o processo de validação de regras para geração de materiais.">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      configuraPartesEditing.executarValidacaoRegras ??
                                      false
                                    }
                                    onChange={event =>
                                      setConfiguraPartesEditing({
                                        ...configuraPartesEditing,
                                        executarValidacaoRegras:
                                          event.target.checked,
                                      })
                                    }
                                    color="primary"
                                  />
                                }
                                label="Validar regras ao concluir"
                              />
                            </Tooltip>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        disabled={loading}
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={handleUpdateParte}
                      >
                        {loading ? 'Salvando...' : 'Salvar'}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

          {/* Modal questão */}
          <Modal
            className="modal-dialog-centered"
            size="xl"
            isOpen={formQuestaoModalState}
            toggle={() => setFormQuestaoModalState(false)}
            onClosed={onClosedModalQuestao}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>
                      {questaoModal?.id ? 'Edição questão' : 'Criando questão'}
                    </small>
                  </div>
                  <Form role="form">
                    <Row>
                      <Col sm="10">
                        <FormGroup className="mb-3">
                          <DropdownTipoQuestao>
                            <DropdownToggleTipoQuestao caret>
                              {tiposQuestao.find(
                                tipo => tipo.key === questaoModal.tipo,
                              )?.value ?? 'Tipo'}
                            </DropdownToggleTipoQuestao>
                            <DropdownMenu>
                              {tiposQuestao.map(tipoQuestao => {
                                return (
                                  <DropdownItem
                                    key={`tipoQuestao_${tipoQuestao.key}`}
                                    value={tipoQuestao.key}
                                    name="tipo"
                                    onClick={handleSelectChangeTipo}
                                  >
                                    {tipoQuestao.value}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </DropdownTipoQuestao>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={questaoModal.isObrigatorio}
                                onChange={event => {
                                  setQuestaoModal({
                                    ...questaoModal,
                                    isObrigatorio: Boolean(
                                      event.target.checked,
                                    ),
                                  });
                                  atualizaObrigatorio();
                                }}
                                color="primary"
                              />
                            }
                            label="Obrigatório"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row hidden={questaoModal.tipo === 'TB'}>
                      <Col sm="4">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={questaoModal.usarRegraColuna}
                                onChange={event => {
                                  setQuestaoModal({
                                    ...questaoModal,
                                    usarRegraColuna: Boolean(
                                      event.target.checked,
                                    ),
                                  });
                                  setQuestaoModalAlterada(true);
                                }}
                                color="primary"
                              />
                            }
                            disabled={questaoModal.tipo === 'TB'}
                            label="Disponibilizar nas Regras"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup
                          className="mb-3"
                          hidden={!questaoModal.usarRegraColuna}
                        >
                          <Input
                            placeholder="Nome da Variável em Regras"
                            type="text"
                            name="nomeVariavel"
                            value={questaoModal.nomeVariavel ?? ''}
                            onChange={handleInputChange}
                            disabled={questaoModal.tipo === 'TB'}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      hidden={
                        questaoModal.tipo !== 'S' && questaoModal.tipo !== 'IM'
                      }
                    >
                      <Col sm="4">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={questaoModal.permiteMultiSelecao}
                                onChange={event => {
                                  setQuestaoModal({
                                    ...questaoModal,
                                    permiteMultiSelecao:
                                      event.target.checked ?? false,
                                  });
                                  setQuestaoModalAlterada(true);
                                }}
                                color="primary"
                              />
                            }
                            label="Permite Multiseleção"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row
                      hidden={
                        questaoModal.tipo !== 'TB' ||
                        !tabelaSelecionada.disponibilizarRegras
                      }
                    >
                      <Col sm="12">
                        <p>
                          Identificado nas regras como{' '}
                          <strong>{tabelaSelecionada.labelRegras}</strong>
                        </p>
                      </Col>
                    </Row>

                    <FormGroup
                      className="mb-3"
                      hidden={
                        questaoModal.tipo === 'COMP' ||
                        questaoModal.tipo === 'TB'
                      }
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className="ni ni-align-center"
                              style={{
                                alignSelf: 'start',
                                marginTop: '0.3rem',
                              }}
                            />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="Descrição"
                          rows="3"
                          type="textarea"
                          name="descricao"
                          value={questaoModal.descricao ?? ''}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>

                    {questaoModal.tipo === 'TB' && (
                      <>
                        <FormGroup className="mb-3">
                          <Row>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <DropdownTipoQuestao>
                                  <DropdownToggleTipoQuestao caret>
                                    {listaTabelaGenerica.find(
                                      tipo => tipo.id === tabelaSelecionada.id,
                                    )?.nome ?? 'Selecione a Tabela'}
                                  </DropdownToggleTipoQuestao>
                                  <DropdownMenu>
                                    <DropdownItem
                                      // eslint-disable-next-line react/no-array-index-key
                                      key="tabela_0"
                                      value={undefined}
                                      name="tabelaFilho"
                                      onClick={handleTabelaChange}
                                    >
                                      Selecione a Tabela
                                    </DropdownItem>
                                    {listaTabelaGenerica?.map(
                                      (tabela, indx) => {
                                        return (
                                          <DropdownItem
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`tabela_${tabela.id}_${indx}`}
                                            value={tabela.id}
                                            name="tabelaFilho"
                                            onClick={handleTabelaChange}
                                          >
                                            {`${tabela.nome} ${
                                              tabela.descricao
                                                ? `[${tabela.descricao}]`
                                                : ''
                                            }`}
                                          </DropdownItem>
                                        );
                                      },
                                    )}
                                  </DropdownMenu>
                                </DropdownTipoQuestao>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    )}

                    {questaoModal.tipo === 'COMP' && (
                      <>
                        <FormGroup className="mb-3">
                          <Row>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <DropdownTipoQuestao>
                                  <DropdownToggleTipoQuestao caret>
                                    {componentesProduto.find(
                                      tipo =>
                                        tipo.componente ===
                                        componenteSelecionado,
                                    )?.descricao ?? 'Selecione o Componente'}
                                  </DropdownToggleTipoQuestao>
                                  <DropdownMenu>
                                    <DropdownItem
                                      // eslint-disable-next-line react/no-array-index-key
                                      key="componente_0"
                                      value={undefined}
                                      name="componenteFilho"
                                      onClick={handleComponenteChange}
                                    >
                                      Selecione o Componente
                                    </DropdownItem>
                                    {componentesProduto
                                      .filter(
                                        item => item.produtoId === produto.id,
                                      )
                                      .map((questao, indx) => {
                                        return (
                                          <DropdownItem
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`componente_${questao.componente}_${indx}`}
                                            value={questao.componente}
                                            name="componenteFilho"
                                            onClick={handleComponenteChange}
                                          >
                                            {`${questao.descricao} [${questao.componente}]`}
                                          </DropdownItem>
                                        );
                                      })}
                                  </DropdownMenu>
                                </DropdownTipoQuestao>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    )}

                    {tiposSelecao.includes(questaoModal.tipo) && (
                      <>
                        <ContainerInterno>
                          <h5 className="text-muted">Valores da seleção</h5>

                          <FormGroup className="mt-3">
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              {questaoModal.descricao !==
                                'Modelo da sucção' && (
                                <Input
                                  placeholder="Nova opção"
                                  type="text"
                                  name="descricaoOpcao"
                                  value={descricaoNovoValorSelecao}
                                  onChange={text =>
                                    setDescricaoNovoValorSelecao(
                                      text.target.value,
                                    )
                                  }
                                />
                              )}
                              {questaoModal.descricao !==
                                'Modelo da sucção' && (
                                <Button
                                  disabled={loading}
                                  className="btn-icon btn-2"
                                  color="primary"
                                  type="button"
                                  onClick={handleAdicionaValorSelecao}
                                >
                                  <FaPlusCircle />
                                </Button>
                              )}
                            </InputGroup>
                          </FormGroup>
                          {/* Aqui vai editar */}

                          {questaoModal.descricao !== 'Modelo da sucção'
                            ? valoresSelecaoQuestaoModal.map(valorSelecao => (
                                <Col>
                                  <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-tag" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      {/* Aqui abaixo entra a edição das questões */}

                                      <Input
                                        placeholder="Valor"
                                        type="text"
                                        value={
                                          valoresSelecaoQuestaoModal.find(
                                            x => x.id === valorSelecao.id,
                                          )?.descricao
                                        }
                                        onChange={text =>
                                          handleChangeDescricaoValorSelecao(
                                            valorSelecao,
                                            text.target.value,
                                          )
                                        }
                                      />

                                      <Row>
                                        <Col sm="9" />
                                        <Col sm="3">
                                          <FormControlLabel
                                            className="float-right"
                                            control={
                                              <Checkbox
                                                checked={
                                                  definirDefault ===
                                                  valorSelecao.id
                                                }
                                                onChange={event =>
                                                  handleChangeValorDefault(
                                                    event,
                                                    valorSelecao.id,
                                                  )
                                                }
                                                color="primary"
                                              />
                                            }
                                            label="Default"
                                          />
                                        </Col>
                                      </Row>

                                      {valorSelecao.editado && (
                                        <Button
                                          disabled={loading}
                                          className="btn-icon btn-2"
                                          color="warning"
                                          type="button"
                                          size="sm"
                                          onClick={() =>
                                            handleSalvaEdicaoValorSelecao(
                                              valorSelecao,
                                            )
                                          }
                                        >
                                          <FaCheckCircle />
                                        </Button>
                                      )}
                                      <Button
                                        disabled={loading}
                                        className="btn-icon btn-2"
                                        color="danger"
                                        type="button"
                                        size="sm"
                                        onClick={() =>
                                          handleRemoveValorSelecao(valorSelecao)
                                        }
                                      >
                                        <FaTimesCircle />
                                      </Button>
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                              ))
                            : modeloSuccao.map(valorSelecao => (
                                <Col>
                                  <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-tag" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input
                                        placeholder="Valor"
                                        type="text"
                                        value={valorSelecao.nome}
                                        label="Default"
                                      />
                                      <Row>
                                        <Col sm="9" />
                                        <Col sm="3">
                                          <FormControlLabel
                                            className="float-right"
                                            control={
                                              <Checkbox
                                                checked={
                                                  definirDefault ===
                                                  valorSelecao.questaoValorSelecaoId
                                                }
                                                onChange={event =>
                                                  handleChangeValorDefault(
                                                    event,
                                                    valorSelecao.questaoValorSelecaoId,
                                                  )
                                                }
                                                color="primary"
                                              />
                                            }
                                            label="Default"
                                          />
                                        </Col>
                                      </Row>
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                              ))}

                          {questaoModal.descricao !== 'Modelo da sucção'
                            ? questaoModal.tipo === 'IM' && (
                                <QuestaoSelecaoListImagem
                                  valoresSelecao={valoresSelecaoQuestaoModal}
                                  handleChangeValorQuestionario={() =>
                                    undefined
                                  }
                                  handleChangeImageValue={() => undefined}
                                  handleSetaIdImage={() => undefined}
                                  listaValoresSelecao={[]}
                                />
                              )
                            : questaoModal.tipo === 'IM' && (
                                <QuestaoSelecaoImagemSuccao />
                              )}
                        </ContainerInterno>
                      </>
                    )}

                    {questaoModal.tipo !== 'TI' &&
                      questaoModal.tipo !== 'INF' &&
                      questaoModal.tipo !== 'TB' &&
                      questaoModal.tipo !== 'COMP' && (
                        <>
                          <FormGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-diamond" />
                                </InputGroupText>
                              </InputGroupAddon>

                              <Input
                                placeholder="Unidade de medida"
                                type="text"
                                name="unidadeMedida"
                                value={questaoModal.unidadeMedida ?? ''}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormGroup>
                        </>
                      )}

                    {questaoModal.tipo === 'N' && (
                      <>
                        <FormGroup className="mb-3">
                          <Row>
                            <Col sm="6">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-bold-down" />
                                  </InputGroupText>
                                </InputGroupAddon>

                                <Input
                                  placeholder="Valor mínimo"
                                  type="number"
                                  name="valorMinimo"
                                  value={questaoModal.valorMinimo ?? ''}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm="6">
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-bold-up" />
                                  </InputGroupText>
                                </InputGroupAddon>

                                <Input
                                  placeholder="Valor máximo"
                                  type="number"
                                  name="valorMaximo"
                                  value={questaoModal.valorMaximo ?? ''}
                                  onChange={handleInputChange}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    )}

                    {renderQuestoesRelacionadas()}

                    {questaoModal.tipo !== 'TI' &&
                      !questaoModal.id &&
                      questaoModal.tipo !== 'INF' && (
                        <>
                          <h5 className="text-muted">
                            Adicionar abaixo do título
                          </h5>

                          <FormGroup className="mb-3">
                            <DropdownTipoQuestao>
                              <DropdownToggleTipoQuestao caret>
                                {questoesTipoTitulo.find(
                                  tipo => tipo.id === Number(tituloSelecionado),
                                )?.descricao ?? 'Título associado'}
                              </DropdownToggleTipoQuestao>
                              <DropdownMenu>
                                {questoesTipoTitulo.map((questao, indx) => {
                                  return (
                                    <DropdownItem
                                      // eslint-disable-next-line react/no-array-index-key
                                      key={`titulo_${questao.id}_${indx}`}
                                      value={questao.id}
                                      name="tituloSuperior"
                                      onClick={handleTituloChange}
                                    >
                                      {questao.descricao}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </DropdownTipoQuestao>
                          </FormGroup>
                        </>
                      )}

                    <div className="text-center">
                      {questaoModalAlterada && (
                        <Button
                          disabled={loading}
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSalvaQuestao}
                        >
                          {loading ? 'Salvando...' : 'Salvar'}
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Partes do produto {produto.nome} (# {produto.id})
                </h3>
              </CardHeader>

              {partes.map((parte: ParteModel) => (
                <div key={`parte_${parte.id}`}>
                  <DivRow className="mb-2">
                    <Col xs="12" sm="8">
                      <strong>{parte.nome}</strong>
                    </Col>

                    <Col xs="12" sm="4">
                      <AcoesDiv>
                        {getQuestoesParte(parte.id).length ? (
                          <Button
                            size="sm"
                            color="default"
                            onClick={() =>
                              handleToggleDropdownQuestao(parte.id)
                            }
                            id={`toggler-${parte.id}`}
                          >
                            Questões
                          </Button>
                        ) : (
                          <></>
                        )}

                        <Button
                          size="sm"
                          className="btn-icon"
                          color="default"
                          type="button"
                          onClick={() => {
                            handleNovaQuestao(parte.id);
                          }}
                        >
                          <FaPlusCircle />
                        </Button>

                        <Button
                          size="sm"
                          className="btn-icon"
                          color="warning"
                          type="button"
                          onClick={() => {
                            handleEditaParte(parte);
                          }}
                        >
                          <FaEdit />
                        </Button>

                        <Button
                          size="sm"
                          className="btn-icon"
                          color="danger"
                          type="button"
                          onClick={() => {
                            handleDesativaParte(parte);
                          }}
                        >
                          <FaTimesCircle />
                        </Button>
                      </AcoesDiv>
                    </Col>
                  </DivRow>

                  {montaQuestoes(parte.id)}
                </div>
              ))}
            </Card>
          </Col>

          {ordemAlterada && (
            <FloatingButton
              title="Salvar ordenação"
              onClick={() => updateOrdem()}
            >
              <FaCheckCircle />
            </FloatingButton>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ProdutoPartes;
