import { Col, Row } from 'reactstrap';
import './style.css';
import LogoImg from '../assets/img/logo_fck.png';

export default function CondicoesGeraisFornecimento(): JSX.Element {
  return (
    <>
      <div
        style={{
          textAlign: 'justify',
          fontSize: '13px',
          textJustify: 'inter-word',
          lineHeight: '1.1em',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ textDecoration: 'underline' }}>
            Condições Gerais de Fornecimento
          </h3>
          <h4>Rev. Agosto/2024</h4>
        </div>
        <div className="row">
          <div className="column">
            <div>
              1. Por intermédio deste instrumento as Partes identificadas no
              Pedido de Venda, como Vendedora e Comprador, obrigam-se a cumprir
              os termos constantes nestas Condições Gerais de Fornecimento.
            </div>
            <div>
              1.1. São de exclusiva responsabilidade da Vendedora as despesas
              relativas de fabricação dos equipamentos descritos no Pedido de
              Venda.
            </div>
            <div>
              1.2. Nos casos em que o Comprador (a) ficar responsável pela parte
              civil a Vendedora fica isenta de qualquer responsabilidade em
              relação as despesas relativas à infraestrutura,compreendidas por
              materiais de construção civil, serviços de pedreiros, carpinteiros
              e outros inerentes à construção civil, inclusive terraplanagem,
              pinturas em alvenaria, abertura e fechamento de valas, instalação
              de energia elétrica e barragem, que serão de exclusiva
              responsabilidade do(a) Comprador(a).
            </div>
            <div>
              1.3. A Vendedora poderá, a seu critério, empreitar ou contratar
              total ou parcialmente a execução de montagem do equipamento com
              terceiros, desde que assessorados por técnico desta.
            </div>
            <div>
              2. O prazo de entrega está convencionado no Pedido de Venda e
              somente deverá ser considerado desde que sejam disponibilizados os
              recursos financeiros nas datas acordadas e que a infraestrutura
              básica compreendida por construção civil, condições do terreno e
              terraplanagem, energia elétrica e barragem, estejam devidamente
              implementadas pelo menos 30 (trinta) dias antes do prazo de
              entrega, e a energia liberada 14 (quatorze) dias antes do prazo de
              entrega e condições climáticas favoráveis, a dar condições para a
              montagem do equipamento, sob pena de prorrogação da data de
              entrega proporcionalmente aos dias de atraso.
            </div>
            <div>
              2.1. Nos termos do art. 393 do Código Civil (Lei 10.406/2002), a
              Vendedora não será responsável, sob hipótese nenhuma, por atraso
              no cumprimento do prazo contratual em razão de caso fortuito,
              força maior ou atos de autoridades públicas que afetem, inclusive
              com relação aos fornecedores da Vendedora, importação, a aquisição
              e o recebimento de matérias-primas e componentes à fabricação, o
              transporte ou o despacho, ou em razão de acidentes, incêndios,
              atos ou omissões do(a) Comprador(a) e demais circunstâncias que
              estejam fora do controle da Vendedora.
            </div>
            <div>
              2.2. Caberá ainda prorrogação no prazo de entrega do equipamento
              em caso de demora no envio de dados definitivos pelo(a)
              Comprador(a), quando a demora for por motivos de sua
              responsabilidade, resguardando-se a Vendedora o direito de
              prorrogar a entrega do equipamento pelo mesmo número de dias que
              corresponderem ao período da data de emissão do pedido/ aprovação
              do financiamento, até a data de recebimento dos dados do
              levantamento definitivo.
            </div>
            <div>
              2.2.1. Declaro estar ciente e de acordo que, em ocorrendo a
              prorrogação do prazo de instalação dos Equipamentos por minha
              solicitação e/ou em virtude de atraso ocorrido por minha culpa,
              será necessário assinar Termo Aditivo a esse Contrato, que será
              remetido ao Comprador por intermédio da Plataforma de Assinaturas
              Digital D4sing. Passados 10 (dez) dias do envio pela Fockink para
              mim da minuta do dito Termo Aditivo, caso eu não a assine, seja
              pela razão que for, a prorrogação do prazo objeto do referido
              documento estará tacitamente aceita, não podendo ser discutida
              posteriormente.
            </div>
            <div>
              2.3. Fica ainda registrado que havendo atraso na entrega dos dados
              definitivos, divergências ou insuficiência de informações
              porventura existentes nos dados e que necessitem de
              complementação, que sejam de responsabilidade do(a) Comprador(a),
              bem como solicitações de alteração do projeto original, acarretará
              na reformulação dos preços, condições técnicas e prazos de entrega
              dos equipamentos.
            </div>
            <div>
              2.4. Por se tratar de equipamento fabricado sob encomenda em
              função das especificações solicitadas pelo(a) Comprador(a), caso a
              entrega do equipamento venha a ser antecipada, adiada ou
              prejudicada por solicitação do(a) Comprador(a), por recusa em
              receber o equipamento, todas e quaisquer despesas incorridas pela
              Vendedora serão de responsabilidade do(a) Comprador(a) e pagas,
              integralmente, em uma só parcela pelo(a) Comprador(a) antes da
              data da entrega do equipamento, sendo condição essencial para a
              sua efetivação.
            </div>
            <div>
              2.5. A Vendedora não será, sob hipótese nenhuma, responsável por
              falhas, atrasos ou impossibilidades em efetuar o embarque e a
              expedição do equipamento em razão de (i) acidentes, incêndios,
              paralizações ou greves em sua fábrica ou em estabelecimentos de
              seus fornecedores ou prestadores de serviços, (ii) atos ou
              omissões do(a) Comprador(a), inclusive responsabilizando-se por
              despesas de diárias de transporte em não sendo possível sua
              efetivação quando ocasionado por sua causa, ou (iii) ordens
              judiciais ou de autoridades, ou determinações governamentais.
            </div>
            <div>
              2.6. Caso o (a) Comprador (a) realize apenas a compra de partes do
              equipamento (sistema de bombeamento, parte aérea, lances) e não o
              sistema integral, isenta desde já a Vendedora de qualquer
              responsabilidade, incluindo mas não se limitando a
              dimensionamento, topografia e performance do equipamento, bem como
              sob os componentes de partes que não foram fornecidos e instalados
              pela Vendedora, ficando esta responsavel somente por defeitos de
              fabricação pertinentes ao seu escopo de fornecimento.
            </div>
            <div>
              2.7. Se as adutoras forem fornecidas exclusivamente pelo
              Comprador, tendo este decidido sobre fornecedor e características
              dos materiais a serem utilizados, o Comprador isenta a Vendedora
              de toda e qualquer responsabilização a qualquer título sobre as
              adutoras fornecidas por ele, incluindo mas não se limitando a
              problemas de trincas, má qualidade dos materiais, má performance,
              vazamentos ocasionados pela deformação ou quebra dos tubos,
              ficando o Comprador responsável por todos os custos e encargos
              provenientes da correção dessas falhas e das garantias junto ao
              fornecedor de sua escolha, nada tendo a reclamar da Vendedora a
              qualquer tempo, inclusive em relação aos cabos e bombeamentos.
            </div>
            <div>
              2.8. Em ocorrendo danos nos componentes fornecidos pelo Comprador,
              sejam eles oriundos de problemas de fabricação e/ou montagem e
              instalação e que venham a compromenter ou danificar, de qualquer
              forma, os componentes da Vendedora, tais componentes não estarão
              cobertos pela garantia de que trata estas condições gerais de
              fornecimento, assumindo o Comprador toda e qualquer
              responsabilidade decorrente disso, inclusive em relação a eventual
              ônus de substituição de peças e demais componentes.
            </div>
            <div>
              3.Em contraprestação, o(a) Comprador(A) pagará a Vendedora pelo
              objeto contratado, o que for convencionado no Pedido de Venda,
              inclusive em relação a sua forma e condições.
            </div>
          </div>
          <div className="column">
            <div>
              3.1. O(A) Comprador(a) se compromete a efetuar os pagamentos
              estritamente nos prazos e condições pactuadas se os eventos não
              puderem ser cumpridos pela Vendedora por responsabilidade do(a)
              Comprador(a) e/ou seus terceiros, desvinculando-se as parcelas dos
              eventos, mantendo-se desta forma os pagamentos das parcelas nas
              datas previstas originalmente neste contrato.
            </div>
            <div>
              3.2. Caso, paralelamente a transação definida neste pedido, o
              Comprador busque um financiamento junto ao Sistema Financeiro
              Nacional, a Vendedora poderá aguardar o recebimento dos
              respectivos documentos e autorização de faturamento por um prazo
              máximo de 180 (cento e oitenta) dias. Havendo a liberação dos
              fundos para a Vendedora, pela respectiva instituição financeira,
              será efetuado um encontro de contas entre o valor já pago e valor
              liberado, devendo ser devolvido ao Comprador, se for o caso, o
              valor excedente. Na eventual ocorrência deste evento, o Comprador
              deste já concorda com a cobrança de despesas administrativas e de
              refaturamento de até 4,5% do valor total faturado, que será
              apresentado pela Vendedora através de nota de débito e que poderá
              ser descontada do saldo a ser devolvido pela mesma, conforme o
              caso.
            </div>
            <div>
              3.2.1. Se aprovado o financiamento bancario e a instituição
              financeira liberar os valores deste diretamente para o (a)
              Comprador (a), este se obriga a realizar o pagamento integral das
              parcelas faltantes para a Vendedora, independentemente das
              condições de pagamento descrita no pedido.
            </div>
            <div>
              3.3. Eventual negativa de pagamento das despesas administrativas e
              de refaturamento por parte do Comprador desobriga a Vendedora de
              tomar toda e qualquer providência acerca de eventual financiamento
              envolvendo os equipamentos.
            </div>
            <div>
              3.4. Os preços ofertados consideram as aliquotas, benefícios e
              incentivos fiscais previstos na legislação tributária vigente
              neste momento, assim sendo, quaisquer tributos, aumento de
              impostos, taxas, contribuições fiscais ou parafiscais ou outros
              encargos legais criados, alterados ou extintos, bem como a
              superveniência de disposições legais, quando ocorridas após a data
              da apresentação da proposta e pedido, de comprovada repercussão
              nos preços contratados, implicarão a revisão destes, ficando
              estipulado que o pagamento das diferenças apuradas deverão ser
              adimplidas junto a última parcela. Salvo se o pagamento integral
              ocorrer dentro de 45 (quarenta e cinco) dias após a assinatura
              deste pedido.
            </div>
            <div>
              3.5. Fica estabelecido que, em ocorrendo após a data do pedido, a
              majoração de custos de insumos, mão de obra, materiais e
              componentes, ou por fatores de mercado nacional e internacional
              (lei da oferta e procura) com aumentos que extrapolam a curva de
              índices inflacionários fora do controle da Vendedora, incluindo
              mas não se limitando a casos de força maior ou caso fortuito e
              falta de fornecimento de matéria prima, onde se configure o
              desequilíbrio econômico e financeiro do pedido, estes serão
              repassados ao (à) Comprador(a).
            </div>
            <div>
              3.5.1. Após apresentação do reajuste mencionado no item 3.5, o
              cliente terá o prazo de 5 (cinco) dias para manifestar-se sobre a
              concordância ou não, podendo aceitar o reajuste no preço,
              realizando o pagamento dos valores apresentados ou poderá desistir
              da compra sem aplicação de multa, arcando com os custos já
              absorvidos pela Fockink até o momento da desistência.
            </div>
            <div>
              3.6. A responsabilidade pela aprovação e liberação dos recursos
              que porventura sejam objeto de financiamento bancário, é
              unicamente do(a) Comprador(a), estando ciente que
              independentemente da aprovação/liberação pelo banco, o(a)
              Comprador(a) compromete-se a efetuar o pagamento do valor
              financiado à Vendedora, juntamente com as demais parcelas,
              conforme data de vencimento determinada neste instrumento, não
              sendo os prazos suscetíveis de alteração ou prorrogação.
            </div>
            <div>
              3.7. O atraso do pagamento de quaisquer das parcelas acima
              referidas, ensejará ao(à) Comprador(a), o pagamento de acréscimo
              de 2% (dois por cento) ao mês, referente 1% de juros de mora e 1%
              de despesas de cobrança, ficando a Vendedora autorizada a enviar o
              título bancário vencido ao Cartório de Protestos e realizar a
              inclusão do devedor em cadastros ou serviços legalmente
              constituídos e destinados à proteção de crédito – SPC/SERASA,
              sendo que o não pagamento de uma das parcelas poderá ensejar o
              vencimento antecipado de toda a dívida, a critério da Vendedora.
            </div>
            <div>
              3.8. Na hipótese de haver cobrança judicial, o(a) Comprador(a)
              pagará, além das despesas processuais, os honorários de advogado
              de 20% (vinte por cento) sobre o valor do débito, (artigo 389 do
              Código Civil), que será corrigido a partir do vencimento de cada
              uma das parcelas até a data da liquidação, correção esta que
              incidirá também sobre as multas contratuais e legais.
            </div>
            <div>
              3.9. O valor correspondente ao IPI relativo a presente compra e
              venda, encontra-se incluso no valor acima, sendo o diferencial de
              ICMS, quando devido, de responsabilidade do(a) Comprador(a).
            </div>
            <div>
              3.10. O(A) Comprador(a) assume todos os riscos decorrentes de caso
              fortuito ou força maior, comprometendo-se a efetuar os pagamentos
              estritamente nos prazos e condições pactuadas.
            </div>
            <div>
              3.11. A Vendedora poderá ceder, transferir, caucionar, descontar
              ou outorgar, a qualquer título e sob qualquer forma, total ou
              parcialmente, os direitos creditórios do presente contrato,
              ficando expressamente avençado entre as partes que as obrigações
              provenientes do presente instrumento não serão objeto de cessão,
              continuando estas exclusivamente a cargo e responsabilidade da
              Vendedora.
            </div>
            <div>
              3.12. O(A) Comprador(a) declara ter ciência e concordar que em
              havendo a cessão de direitos creditórios a qualquer instituição
              financeira/bancária, sendo está de escolha exclusiva da Vendedora,
              o pagamento dos valores avençados no presente será realizado por
              intermédio de boletos bancários nas datas especificadas neste
              instrumento.
            </div>
            <div>
              4. A presente venda é realizada sob condição, e com pacto adjeto
              de reserva de domínio (pacta reservati dominii) sobre o
              equipamento em favor da Vendedora, constituindo-se posse precária
              em favor do(a) Comprador(a), até o final e efetivo pagamento do
              preço total fixado no presente instrumento.
            </div>
            <div>
              4.1. A Vendedora não responderá em qualquer tempo, por eventuais
              danos causados pelo uso inadequado de quaisquer componentes do
              equipamento vendido.
            </div>
            <div>
              5. O(A) Comprador(a), seus representantes, mandatários,
              sucessores, cessionários, empregados, administradores, diretores e
              pessoas jurídicas a ele relacionadas deverão manter confidenciais
              e não usar individualmente, as informações relacionadas aos
              equipamentos constantes no Pedido de Venda, bem como demais
              aspectos comerciais, financeiros e técnicos relevantes aos
              negócios da Vendedora, ou que tenha sido fornecida pela Vendedora
              ao(à) Comprador(a).
            </div>
          </div>
        </div>
        <div className="row" style={{ pageBreakBefore: 'always' }}>
          <div className="column">
            <div>
              6. A garantia somente tem validade se os serviços, produtos e/ou
              componentes reclamados, forem comprovadamente identificados pelo
              Departamento Técnico Fockink com defeitos de fabricação.
            </div>
            <div>
              6.1. A Vendedora concede para os equipamentos e produtos por ela
              fabricados e instalados, o prazo de garantia de 12 (doze) meses,
              contados a partir da data de assinatura do cliente no documento
              Termo de Entrega, ou 18 (dezoito) meses, contados a partir da data
              de emissão da nota fiscal do item que apresentou defeito, o que
              ocorrer primeiro.
            </div>
            <div>
              6.2. A Vendedora concede para os serviços de instalação
              eletromecânicas, o prazo de garantia de 12 (doze) meses, contados
              a partir da data de assinatura do cliente no documento Termo de
              Entrega, ou 18 (dezoito) meses, contados a partir da data de
              emissão da nota fiscal de remessa de material ao cliente, o que
              ocorrer primeiro.
            </div>
            <div>
              6.3. Para os equipamentos, acessórios e componentes fabricados por
              terceiros, que compõe o produto da Vendedora, a garantia será
              conforme estipulado por estes fabricantes terceiros, porém
              qualquer reclamação ou pedido de reposição deverá ser dirigida à
              Vendedora.
            </div>
            <div>
              6.4. Para equipamentos e produtos comercializados pela Vendedora,
              e por ela não instalados, será concedido o prazo de garantia de 12
              (doze) meses contados a partir da data de emissão da nota fiscal
              de remessa do equipamento ou produto ao cliente.
            </div>
            <div>6.5. São casos de exclusão da garantia:</div>
            <div>6.5.1. Decurso do prazo de garantia mencionado acima.</div>
            <div>
              6.5.2. Quando o produto for utilizado em desacordo com o projetado
              e/ou especificado nos manuais do produto.
            </div>
            <div>
              6.5.3. Em condições técnicas fora de parâmetros do projeto e
              propósito a que o equipamento foi desenvolvido.
            </div>
            <div>
              6.5.4. Quando o produto for consertado, instalado ou ajustado por
              pessoas não autorizadas, não credenciadas e ou empresas não
              homologadas pela fabricante.
            </div>
            <div>
              6.5.5. Quando o produto apresentar defeito causado pelo Comprador
              ou terceiros, por acidentes ou em decorrência de mau uso ou
              operação inadequada do produto.
            </div>
            <div>
              6.5.6. Desgastes naturais, oriundos de condições naturais de
              funcionamento.
            </div>
            <div>
              6.5.7. Interrupções do fornecimento de energia, sobretensões,
              subtensões, oscilações, afundamentos da tensão elétrica e outros
              distúrbios na qualidade da energia elétrica, quer por deficiências
              das instalações do cliente ou por fornecimento da concessionária
              de energia.
            </div>
            <div>
              6.5.8. Quedas, impactos mecânicos, descargas atmosféricas,
              vendaval, temporal ou inundações provocadas por intempéries, atos
              da natureza, e demais casos que possam configurar caso fortuito
              e/ou força maior;
            </div>
            <div>
              6.5.9. Inobservância das instruções de armazenamento, instalação,
              comissionamento, operação, manutenção periódica e manutenção
              corretiva prescritas no manual do produto.
            </div>
            <div>
              6.5.10. Prolongada utilização do equipamento em desacordo com as
              recomendadas pelo fabricante;
            </div>
            <div>
              6.5.11. Avarias ou problemas de transporte, carga, descarga e
              movimentação, realizados sob responsabilidade do Comprador.
            </div>
            <div>
              6.5.12. Quando verificado qualquer tipo de inadimplência junto a
              Vendedora, a Vendedora e os componentes de terceiros ficam
              automaticamente eximidos de qualquer tipo de responsabilidade
              sobre garantias e alegação de prejuízos de qualquer natureza, e
              quando resolvido a pendencia a garantia somente terá cobertura
              pelo período restante entre datas se o existir.
            </div>
            <div>
              6.5.13. Quando a instalação for realizada pelo próprio(a)
              Comprador(a), ou por terceiros NÃO homologados pela fabricante ou
              pela Vendedora fornecedora, de forma incorreta, divergente ou em
              desacordo com os manuais do produto;
            </div>
            <div>
              6.5.14. Quando o produto apresentar defeito causado pelo manuseio,
              erro na instalação, por qualquer tipo de avaria, correlatos em
              decorrência de mau uso, ou operação inadequada do produto conforme
              descrito no item 6.5.13.
            </div>
            <div>6.6. Limitações de responsabilidade:</div>
            <div>
              6.6.1. A Vendedora não se responsabiliza por eventuais danos,
              prejuízos ou lucros cessantes, oriundos do uso inadequado e/ou
              desgaste natural do componente, da operação do equipamento fora
              dos parâmetros técnicos, ou não funcionamento de peças e
              componentes por ela não fornecidos, que fazem parte do
              equipamento, bem como a garantia destes componentes.
            </div>
            <div>
              6.6.2. A garantia oferecida pela Vendedora limita-se à reparação
              do serviço / produto ou substituição da peça defeituosa,
              respeitadas as ressalvas dos itens anteriores, não cabendo ao (à)
              Comprador(a) pleitear quaisquer outros tipos de indenizações,
              coberturas e danos com quebras de safra, compra de insumos e
              lucros cessantes.
            </div>
            <div>
              6.6.3. Com o reparo do serviço / produto ou a substituição do
              produto ou componente defeituoso, fica plenamente satisfeita a
              garantia, sem responsabilidade adicional para a Vendedora. A
              execução de um serviço ou troca de componentes em garantia não
              provoca a extensão da garantia.
            </div>
            <div>
              6.6.4. Dependendo da anomalia ocorrida no produto, a Vendedora
              reserva-se no direito de realizar uma análise (verificação) e
              gerar um laudo técnico de avaliação. O resultado deste laudo
              validará ou não os itens acima expostos.
            </div>
            <div>6.7. Instrução para o atendimento em garantia:</div>
            <div>
              6.7.1. O Comprador (a) deverá apresentar reclamação formal à
              Vendedora juntamente com o número da nota fiscal do serviço /
              produto adquirido junto à Vendedora. Quando se tratar de
              solicitação de garantia de uma peça / produto também deve ser
              informado o número de série do item que está sendo solicitada a
              garantia.
            </div>
            <div>
              6.7.2. Com o recebimento da solicitação de garantia e dados
              fiscais, a Vendedora irá avaliar e informar ao Comprador (a) se o
              produto reclamado deverá ser enviado até seu parque fabril ou se
              irá enviar um técnico autorizado para realizar o atendimento nas
              dependências do cliente.
            </div>
            <div>
              6.7.3. Somente poderá ser enviado o produto com defeito até o
              parque fabril da Vendedora após autorização. Os custos de
              transporte e seguro ocorrerão por responsabilidade do Comprador
              (a), exceto se identificado pelo departamento técnico Fockink que
              o problema foi proveniente de erro de instalação ou vicio
              atribuível a Vendedora.
            </div>
            <div>
              6.7.4. Caso seja necessário a Vendedora enviar um técnico
              autorizado para realizar o atendimento nas dependências do
              cliente, os custos serão por responsabilidade do Comprador (a),
              exceto se identificado pelo departamento técnico Fockink que o
              problema foi proveniente de erro de instalação ou vicio atribuível
              a Vendedora.
            </div>
            <div>
              6.8. Em havendo o uso de peças de outros sistemas de irrigação
              preexistentes ou peças já existentes no equipamento que já estejam
              fora da garantia para compor a instalação ou modernizações do
              sistema de irrigação, tais peças reutilizadas não estarão cobertas
              pela garantia, arcando o(a) Comprador(a) com todas as despesas
              decorrentes do mal funcionamento e demais vícios que venham a
              ocorrer, isentando desde já a Vendedora de qualquer
              responsabilidade.
            </div>
          </div>
          <div className="column">
            <div>
              7. A Vendedora não será responsável e não fornecerá garantia,
              quando os equipamentos forem operados em terrenos com declividades
              maiores do que 25% ou em desacordo com o projetado/recomendado,
              bem como, a Vendedora não assume nenhuma responsabilidade sobre a
              correta localização do(s) equipamento(s) em uma determinada área
              do Comprador(a).
            </div>
            <div>
              8. Toda e qualquer responsabilidade no que concerne as informações
              referentes a dados altimétricos, local de instalação, captação,
              disponibilização de fonte energia compatível para o bom
              funcionamento dos equipamentos e outros, é de inteira
              responsabilidade do(a) Comprador(a), cabendo a Vendedora, única e
              exclusivamente instalar e montar o equipamento no local, forma e
              condições por este (a) indicado.
            </div>
            <div>
              8.1. As obrigações relativas à mão-de-obra para descarga dos
              materiais e equipamentos no local de sua instalação estão
              estabelecidas na matriz de responsabilidades.
            </div>
            <div>
              8.2. O(A) Comprador(a) compromete-se a zelar pelos
              equipamentos/materiais depositados no canteiro e local da obra,
              responsabilizando-se por eventual ocorrência de roubo ou
              desaparecimento tanto do material como dos demais utilitários
            </div>
            <div>
              8.3. É facultativo ao Comprador(a) realizar a contratação de
              seguro para todos os equipamentos e materiais entregues no local
              da obra, incluindo mas não se limitando, contra furto, roubo,
              avarias decorrentes de vendaval, temporal, inundação ou demais
              eventos climáticos, contudo, fica a Vendedora desde já eximida de
              qualquer responsabilidade em decorrência de tais hipóteses.
            </div>
            <div>
              8.4. O(A) Comprador(a) também deverá providenciar local seguro
              para o depósito dos materiais elétricos e equipamentos sensíveis
              às questões climáticas, até a sua efetiva montagem.
            </div>
            <div>
              8.5. Será de responsabilidade do Comprador o fornecimento de: (i)
              mão-de-obra auxiliar em número suficiente de pessoas e conforme
              previsto no Pedido de Venda e Matriz de Responsabilidades, em
              horário comercial e de acordo com a legislação, para o correto
              desempenho dos trabalhos nas condições e prazos estabelecidos,
              ficando sob responsabilidade do Comprador o pagamento de todas as
              despesas envolvidas, não se estabelecendo em razão disso qualquer
              vínculo trabalhista. (ii) fornecimento de caminhão Munck, em sendo
              necessário, bem como o transporte dos materiais dentro de sua
              propriedade. (iii) fornecimento de alimentação e estadia do
              técnico e funcionários da Vendedora que forem utilizados para
              desempenho dos trabalhos, em condições dignas de habitação e de
              acordo com a legislação pertinente. Fica estabelecido que tais
              obrigações poderão ser ajustadas de forma diversa, desde que
              estejam expressamente estabelecidas na matriz de
              responsabilidades.
            </div>
            <div>
              9. O(A) Comprador(a) está ciente que na área em que o equipamento
              será instalado não podem existir barreiras físicas ou quaisquer
              interferências que impeçam ou dificultem o seu funcionamento. Em
              existindo condições desfavoráveis na área, o(a) Comprador(a)
              deverá efetuar os ajustes necessários antes da instalação/operação
              dos equipamentos, sendo de sua única e exclusiva responsabilidade
              todos os ajustes e custos decorrentes disso.
            </div>
            <div>
              10. Qualquer avaria que vier a ocorrer com os equipamentos por
              ocasião de condições desfavoráveis da área que não tenha sido
              ajustada, não esteja em conformidade para o bom funcionamento ou
              sejam modificadas após a instalação dos equipamentos, não estarão
              contemplados pela garantia, devendo os serviços de substituição,
              reparo e demais que se façam necessários serem custeados
              exclusivamente pelo(a) Comprador(a), não tendo direto a reclamar
              lucros cessantes a qualquer tempo e título, eximindo a Vendedora
              de toda e qualquer responsabilidade.
            </div>
            <div>
              11. Em casos de desmobilização, remobilização ou paradas do
              pessoal em obra por não haver condições de frente de serviço ou
              por motivos que não sejam de responsabilidade da Vendedora, os
              custos desta desmobilização/remobilização/parada (estadia,
              alimentação e deslocamento) serão cobrados do(a) Comprador(a).
            </div>
            <div>
              12. O (a) Comprador (a) ou seu preposto deverá acompanhar e
              assinar diariamente o relatório de obra apresentado pela Vendedora
              (RDO). Em caso de recusa deverá, de forma expressa, expor
              justificativa plausível, ficando ressalvado que a recusa em
              assinar o RDO só poderá ocorrer mediante motivo plausível, técnico
              e devidamente justificado. Caso o (a) Comprador (a) não apresente
              justificativa pláusível para recusa e/ou não realize a assinatura
              do Diário de Obras dentro do prazo máximo e imprrogável de 10
              (dez) dias a contar da emissão e disponibilização do RDO, será
              considerado que o mesmo esta de acordo com as instalações e
              andamento da obra.
            </div>
            <div>
              13. Uma vez instalado os equipamentos constantes no Pedido de
              Venda e em perfeitas condições de funcionamento, o(a) Comprador(a)
              deverá obrigatoriamente assinar os “Termos de Entrega” e de “Teste
              de Performance”, o qual comprova que houve a devida instalação e
              análise daqueles.
            </div>
            <div>
              13.1. Eventual recusa em assinar o “Termo de Entrega” deverá ser
              justificada por escrito à Vendedora, em até 48 (quarenta e oito)
              horas a partir de tal recusa, para que a Vendedora, se for o caso,
              possa tomar as providencias necessárias, ficando ressalvado que a
              recusa por parte do(a) Comprador(a) só poderá ocorrer mediante
              motivo plausível, técnico e devidamente justificado. Caso não haja
              a constatação de qualquer problema com relação ao equipamento,
              o(a) Comprador(a) deverá assinar o Termo.
            </div>
            <div>
              13.2. Caso o comprador se recuse a assinar o “Termo de Entrega”
              deixando de apresentar justificativa de recusa por escrito e
              utilize o equipamento na ausência deste, incorrerá na perda total
              da garantia.
            </div>
            <div>
              13.3. Se eventualmente não tiver sido disponibilizado pelo Cliente
              a eletricidade, água ou outras obrigações de sua responsabilidade
              necessárias para o funcionamento e teste de performance do pivô,
              será considerado igualmente entregue o equipamento e o Cliente
              deverá assinar o termo de entrega.
            </div>
            <div>
              13.4. Caso não seja possível que a Vendedora realize o teste de
              performance pelos motivos descritos na cláusula 10.3, ou seja, não
              tiver sido disponibilizado pelo Cliente a eletricidade, água ou
              outras obrigações de sua responsabilidade, necessárias para o
              funcionamento do pivô e execução do teste, o Cliente não disporá
              de garantia de performance do equipamento enquanto tal teste não
              for realizado por técnico credenciado pela Fockink.
            </div>
            <div>
              13.5. Após o Cliente disponibilizar a energia, água ou outras
              responsábilidades faltantes necessárias para a realização do
              “teste de performance” o Cliente deverá informar a Vendedora para
              que possa efetuar o teste, o prazo para a execução do teste será
              combinado entre as Partes a depender da disponibilidade da
              Vendedora.
            </div>
            <div>
              13.6. Após a conclusão da análise de performance o Cliente deverá
              assinar o “Termo de Realização de Teste de Performance”.
            </div>
          </div>
        </div>
        <div className="row" style={{ pageBreakBefore: 'always' }}>
          <div className="column">
            <div>
              14. Condições Gerais de Cessão de Direito de Uso de Software do
              Portal IoT Fockink
            </div>
            <div>
              14.1 Nos termos desta Cláusula, compreende-se por CEDENTE a
              Vendedora/Fockink e por CESSIONÁRIO o Comprador/Cliente.
            </div>
            <div>
              14.2 A propriedade intelectual, titularidade e todos os direitos
              autorais referentes ao Software não fazem parte do objeto deste
              contrato e são de propriedade exclusiva da CEDENTE. A aquisição de
              licenças de uso do Software não concede ao CESSIONÁRIO qualquer
              direito sobre as marcas de serviços ou soluções relacionadas ao
              software ou à empresa CEDENTE.
            </div>
            <div>
              14.3 Em ocorrendo a contratação do Software Portal IOT Fockink a
              renovação se dará de forma automática, por períodos de 12 meses,
              de acordo com valores vigentes à época da renovação. Em não
              havendo interesse na renovação, fica o cliente, desde já, ciente
              de que deverá comunicar a Fockink no prazo de no mínimo 30 dias
              anteriores ao término do período de cessão.
            </div>
            <div>
              14.4 Para a manutenção do backup dos contratos vigentes por um
              período superior a 5 (cinco) anos, será facultada a CEDENTE a
              cobrança de taxa adicional. desde que a cobrança desta taxa seja
              comunicada ao CESSIONÁRIO.
            </div>
            <div>
              14.5 A CEDENTE não será responsável em nenhuma hipótese por
              eventuais danos, prejuízos ou lucros cessantes, oriundos desta
              cessão de uso, sendo estes dados meramente informativos não se
              tratando de indicação de manuseio, sendo este totalmente a cargo e
              de responsabilidade exclusiva do CESSIONÁRIO.
            </div>
            <div>
              14.6 A impossibilidade de operação do software causada por
              defeitos operacionais e técnicos dos equipamentos necessários para
              aferição dos dados monitorados não caracterizará obrigação
              contratual pela CEDENTE, isentando-a de toda e qualquer
              responsabilidade.
            </div>
            <div>
              14.7 A CEDENTE não será responsabilizada pelo não cumprimento de
              suas obrigações na hipótese de ocorrência de caso fortuito ou
              força maior.
            </div>
            <div>
              14.8 O CESSIONÁRIO será responsável pela proteção e segurança de
              sua rede de computadores e/ou internet, devendo manter seus
              equipamentos e rede devidamente protegidos através da instalação
              de antivírus, firewall ou outro meio eficaz capaz de minimizar os
              riscos de ataque a sua estrutura.
            </div>
            <div>
              14.9 A CEDENTE não será responsável pela invasão de qualquer tipo
              de malware ou outro ataque que possa a ocasionar o vazamento e/ou
              exclusão de dados ou que de algum modo venha a interferir na
              operação do Software.
            </div>
            <div>
              14.10 Em caso de rescisão ou extinção do presente contrato, os
              dados referentes ao CESSIONÁRIO ficarão disponíveis por um período
              de 6 (seis) meses, o CESSIONÁRIO poderá requerer cópia de seus
              dados formalizando via os canais de atendimento durante esse
              período. Em não ocorrendo a solicitação no período supramencionado
              poderá a CEDENTE excluir tais dados definitivamente.
            </div>
            <div>
              15. O(A) Comprador(a) declara, sob as penas da lei: (a) que é
              plenamente capaz e que dispõe de todas as autorizações e
              documentos necessários à instalação do equipamento no local
              designado, sem qualquer responsabilidade da Vendedora; (b) tem
              ciência e concorda de que a Vendedora não é responsável e nem
              assume nenhuma participação nas atividades do(a) Comprador(a), nem
              garante quaisquer resultados econômico-financeiros, principalmente
              com relação a produtividade, quebra de safra ou problema de
              plantio ou colheita. (c) é plenamente capaz e que dispõe de todas
              as autorizações e documentos necessários à instalação do
              equipamento no local designado e, em especial, relativas à outorga
              de água e ao meio ambiente, e que a Vendedora não tem qualquer
              obrigação de verificá-las, cabendo toda e qualquer
              responsabilidade em relação a elas exclusivamente ao(à)
              Comprador(a);
            </div>
            <div>
              16. As tecnologias que integram o(s) equipamento(s) mencionado(s)
              neste contrato é de exclusiva propriedade intelectual da
              Vendedora, sendo vedado totalmente o acesso à terceiros. Caso seja
              constatado eventual modificação sem a devida e expressa
              autorização da Vendedora, sem exceções, esta não se responsabiliza
              por eventuais danos e/ou mal funcionamento do(s) equipamento(s),
              nada tendo o(a) Comprador(a) a reclamar, a qualquer tempo ou
              forma.
            </div>
            <div>
              17. Eventuais atualizações de softwares e/ou liberações de acessos
              em relação que integram os equipamentos da Vendedora não estão
              incluídas no valor do presente contrato, devendo ser negociado de
              forma apartada e por instrumento próprio.
            </div>
          </div>
          <div className="column">
            <div>
              18. O Comprador está ciente e autoriza a Vendedora a utilizar,
              tratar e armazenar os dados pessoais fornecidos para os fins de
              relacionamento comercial decorrentes deste contrato.
            </div>
            <div>
              18.1. A Vendedora fica autorizada a compartilhar os dados pessoais
              com outros parceiros ou subcontratados caso seja necessário para
              as finalidades listadas na cláusula acima, observados os
              princípios e as garantias estabelecidas pela Lei nº 13.709 (Lei
              Geral de Proteção de Dados).
            </div>
            <div>
              18.2. A Vendedora poderá manter e tratar os dados pessoais do
              Comprador durante todo o período em que os mesmos forem
              pertinentes ao alcance das finalidades listadas neste instrumento
              contratual. Dados pessoais anonimizados, sem possibilidade de
              associação ao indivíduo, poderão ser mantidos por período
              indefinido.
            </div>
            <div>
              18.3. O Comprador está ciente de que, a qualquer momento, poderá
              revogar este consentimento, optar pela anonimização, bloqueio,
              retificação ou eliminação de dados desnecessários, excessivos ou
              tratados em desconformidade com a LGPD.
            </div>
            <div>
              19. Fica a Vendedora isenta de toda e qualquer responsabilidade
              relacionada ao uso das informações técnicas colhidas por
              terceiros, não sendo esta responsável por qualquer perda ou dano
              proveniente do uso dessas informações.
            </div>
            <div>
              20. O(A) Comprador(a) declara e garante que nem ele nem seus
              representantes, mandatários, cessionários, empregados,
              administradores, diretores ou agentes (i) fizeram ou farão
              qualquer pagamento ilegal a qualquer funcionário público,
              autoridade, político, ou pessoa de qualquer modo ligada a qualquer
              governo em relação ao presente Contrato ou a suas finalidades, e
              (ii) praticaram ou praticarão qualquer outro ato ou omissão que
              viole qualquer legislação aplicável de combate à corrupção.
            </div>
            <div>
              21. Para todos os fins e efeitos de direito, os contratantes
              declaram aceitar o presente contrato nos expressos termos em que
              foi lavrado, obrigando-se a si, seus herdeiros, representantes
              legais e sucessores a bem e fielmente cumpri-lo.
            </div>
            <div>
              22.Este Instrumento, bem como o Pedido de Venda, não poderão ser
              alterados, modificados, mudados ou emendados, a não ser por
              escrito e devidamente assinado pelas partes.
            </div>
            <div>
              23. Na hipótese de quaisquer das disposições, avenças ou condições
              deste Instrumento se tornarem inválidas, nulas ou inexeqüíveis por
              ordem judicial, o restante do Instrumento deverá continuar em
              vigor e válido, não sendo afetado, impedido ou invalidado de
              qualquer forma, devendo a disposição invalidada ou anulada, ser
              substituída por outra revestida de legalidade, que contemple, na
              medida do possível, a intenção das partes e preserve o equilíbrio
              estipulado neste Instrumento.
            </div>
            <div>
              24.Serão consideradas nulas de pleno direito declarações
              individuais de vontade feitas pelas partes contratantes de forma
              diversa da pactuada neste Instrumento.
            </div>
            <div>
              25. Os termos do pedido e das condições gerais de fornecimento
              prevalecem sob qualquer tratativa anterior, ou documento firmado
              entre as partes, prevalecendo as condições gerais de fornecimento
              sob qualquer negociação verbal ou documento prévio firmado na fase
              de negociação, ao qual as PARTES declaram que os termos contidos
              no presente instrumento são os únicos que regem a relação de
              compra e venda.
            </div>
            <div>
              26. As obrigações ora acordadas estão em perfeito equilíbrio,
              tendo sido assumidas de acordo com os valores vigentes à época de
              sua contratação, foram fruto de negociações amplas e representam
              benefícios para ambas as partes, não gerando qualquer tipo de
              lesão às mesmas.
            </div>
            <div>
              27. Em havendo a revenda do equipamento pelo Comprador (a) este
              assume toda a responsabilidade frente a seu Cliente, devendo
              respeitar as condições de fornecimento aqui estabelecidas e o
              pedido firmado entre as Partes.
            </div>
            <div>
              28. O (a) Comprador (a) declara que esta ciente que os manuais de
              instalação, manutenção e operação estão disponíveis para leitura e
              download no endereço: https://www.fockink.ind.br/downloads.
            </div>
            <div>
              29. Fica eleito como Praça de pagamento e Foro a comarca de
              Panambi/RS, para dirimir eventuais dúvidas ou questões emergentes,
              direta ou indiretamente do presente instrumento, renunciando
              expressamente a qualquer outro, por mais privilegiado que possa
              ser.
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            textAlign: 'center',
            verticalAlign: 'top',
            marginTop: '10px',
          }}
        >
          <strong>Aceite do cliente:</strong>
          <br />
          <br />
          ____________________________ <br />
          Cliente
        </div>
      </div>
    </>
  );
}
