import { useLocation } from 'react-router-dom';
import ReactDOMServer, { renderToString } from 'react-dom/server';
import api from 'services/api';
import Proposta from 'models/Proposta';
import { PropostaVersao } from 'models/PropostaVersao';
import { useAuth } from 'hooks/auth';
import { ProdutoProposta } from 'models/ProdutoProposta';
import config from 'config';

import { useEffect, useRef, useState } from 'react';
import FloatingButton from 'components/FloatingButton';
import { FaPrint } from 'react-icons/fa';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { PropostaMeioPagamento } from 'models/PropostaMeioPagamento';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import FichaTecnica from 'models/FichaTecnica';
import BancoFinanciamento from 'models/BancoFinanciamento';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import formatCurrency from 'utils/formatCurrency';
import OrcamentoProduto from 'models/OrcamentoProduto';
import Header from 'components/Headers/Header.js';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
// eslint-disable-next-line import/extensions
import jsPDF from 'jspdf';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import ReportTemplate from '../ReportTemplate';
import Resultados from '../../Proposta/components/Resultados';
import LancesBalanco from '../../ConfiguracaoEtapas/components/Resultado/components/organisms/DadosCalculados/components/LancesBalanco';
import AtaModel from '../../../../models/AtaBriefing';
import {
  Th,
  ContainerButtons,
} from '../../Proposta/components/Parcelas/styles';
import {
  ColQuestao,
  LabelItem,
  LabelTema,
  Labelcabecalho,
  SpanTitulo,
  TituloDestaque,
  Td,
  Geral,
  CabecalhoFixoAta,
  TituloAta,
  TemaAta,
  CabecalhoEditAta,
  ColunaDados,
  ConteudoDocumentoAta,
  DivFormatada,
} from '../style';

interface LocationState {
  proposta: Proposta;
  propostaVersao: PropostaVersao;
  meioPagamentos: PropostaMeioPagamento[];
  meioPagamentoIdSelecionado: number;
  propostaConfiguracao: PropostaConfiguracao;
  produtoProposta: ProdutoProposta;
  orcamentoProduto: OrcamentoProduto;
  validadeProposta: number;
  fichaTecnica: FichaTecnica;
}

interface ResultadoSintetica {
  conta: string;
  valor: number;
  percentualROL: number;
  limitar: boolean;
}

interface iReportTemplate {
  id: number;
  tituloAta?: string;
  tema?: string;
  cabecalho?: string;
  conteudo?: string;
  editor?: string;
  htmlBase?: string;
}

interface PapeisAta {
  id: number;
  papel: string;
  pessoa: string;
}

interface IPropostaVersaoParcelas extends PropostaVersaoParcelas {
  novo?: boolean;
}

interface iPropostaVersaoParcelas extends PropostaVersaoParcelas {
  dias?: number;
  calculo?: number;
}

export default function ImpressoaAta(): JSX.Element {
  const location = useLocation<LocationState>();
  const { usuario } = useAuth();

  const [htmlBase, SetHtmlBase] = useState('');
  const [isLoadingSintetica, setIsLoadingSintetica] = useState(false);
  const [tabelasLimitadas, setTabelasLimitadas] = useState(false);
  const [resultadoSintetica, setResultadoSintetica] = useState<
    ResultadoSintetica[]
  >([]);
  const [htmlEditado, setHtmlEditado] = useState('');
  const [bloquearTudo, setBloquearTudo] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [reportTemplate, setReportTemplate] = useState<iReportTemplate[]>([]);
  const [papeisAta, setPapeisAta] = useState([] as PapeisAta[]);
  const [valoresParametros, setValoresParametros] = useState<any>({});
  const [ataBriefingEditing, setAtaBriefingEditing] = useState({} as AtaModel);
  const [ataBriefing, setAtaBriefing] = useState([] as AtaModel[]);
  const [dadosBasicosCarregados, setDadosBasicosCarregados] = useState(false);
  const [precisaSalvar, setPrecisaSalvar] = useState(false);
  const [dataBaseJurosEmbarque, setDataBaseJurosEmbarque] = useState<string>();
  const [diasAntesEmbarque, setDiasAntesEmbarque] = useState(0);
  const [diasParaEmbarque, setDiasParaEmbarque] = useState(0);
  const [PMR, setPMR] = useState(0);
  const [dataEmbarque, setDataEmbarque] = useState<any>();
  const [isSaving, setIsSaving] = useState(false);
  const [jurosMensal, setJurosMensal] = useState(0);
  const [diasSomar, setDiasSomar] = useState(0);
  const [isLoadingPrazoMedio, setIsLoadingPrazoMedio] = useState(false);
  const [custoFinanceiro, setCustoFinanceiro] = useState(0);
  const {
    proposta,
    propostaVersao,
    meioPagamentos,
    meioPagamentoIdSelecionado,
    propostaConfiguracao,
    produtoProposta,
    orcamentoProduto,
    validadeProposta,
    fichaTecnica,
  } = location.state;
  const propostaVersaoParcelas = propostaVersao.PropostaVersaoParcelas;
  const fichaCadastrada = propostaVersao.PropostaConfiguracao?.[0].fichaTecnica;
  const orcamento = propostaVersao.PropostaConfiguracao?.[0].orcamento;
  const orcamentoId = propostaVersao.PropostaConfiguracao?.[0].orcamentoId;
  const ownerProposta = propostaVersao.proposta?.usuario?.nome;
  const locationState = location.state as any;
  const marca = 'Marca Fockink';
  const areaIrrigada = fichaTecnica?.areaTotalIrrigada;
  const sistema =
    fichaTecnica?.FichaTecnicaBombeamento?.[0]?.motor || 'NÃO INFORMADO';
  const raio = fichaTecnica?.raioEfetivoAreaIrrigada;
  // const [valorTotalGeral, setValorTotalGeral] = useState(0); Valor total por configuração
  const {
    ncm,
    finame,
    nome: descricaoProduto,
    nomeProposta,
  } = orcamentoProduto?.produto || {};
  const comprimentoTotalTubos = fichaCadastrada?.comprimentoTotalTubos;
  const raio1 = Number(fichaCadastrada?.aspersor1Raio ?? 0);
  const raio2 = Number(fichaCadastrada?.aspersor2Raio ?? 0);
  const incluirAspersorFinal = fichaCadastrada?.incluirAspersorFinal;
  const areaTotalIrrigadaCAF = fichaCadastrada?.areaTotalIrrigadaCAF;
  const areaEfetivaIrrigadaSAF = fichaCadastrada?.areaEfetivaIrrigadaSAF;
  const usaAspersorFinal: boolean =
    String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
    String(incluirAspersorFinal).toUpperCase() === 'S';
  const [bancosFinanciamento, setBancoFinanciamento] = useState<
    BancoFinanciamento[]
  >([]);

  const valorArea = usaAspersorFinal
    ? Number(areaTotalIrrigadaCAF ?? 0)
    : Number(areaEfetivaIrrigadaSAF ?? 0);

  const contextConfiguracao = locationState?.telaConfig || false;
  const meioSelecionado = meioPagamentos?.filter(
    meioPagamento => meioPagamento?.id === meioPagamentoIdSelecionado,
  );

  const quantidadeLances = fichaTecnica?.FichaTecnicaLance?.reduce(
    (total, item) => total + item.quantidade,
    0,
  );

  const isTipoFinanciado = meioSelecionado?.[0]?.financiado ?? false;
  const isParalelo = meioSelecionado?.[0]?.descricao === 'Paralelo' ?? false;
  const isRecursoProprio =
    meioSelecionado?.[0]?.descricao?.toUpperCase() === 'RECURSO PRÓPRIO' ??
    false;

  const meioPagamentoSelecionado = meioPagamentos.filter(
    (meioPagamento: any) =>
      (isTipoFinanciado &&
        isParalelo &&
        meioPagamento.descricao !== 'Paralelo') ||
      (isTipoFinanciado &&
        !isParalelo &&
        meioPagamento.financiado === true &&
        meioPagamento.descricao !== 'Paralelo') ||
      (!isTipoFinanciado &&
        meioPagamento.financiado === false &&
        meioPagamento.descricao !== 'Paralelo'),
  );

  const isFinanciados = meioPagamentos.find(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado,
  )?.financiado;

  const reportTemplateRef = useRef(null);
  // const doHtml = `<body style= "font: 14px sans-serif" >`;
  // const doHtml2 = `</body>`;
  const refPdf = document.getElementById('page2')?.outerHTML;

  // const divForm = `${doHtml + refPdf + doHtml2}`;
  async function handleGeneratePdf() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      format: 'a4',
      orientation: 'portrait',
      unit: 'mm',
    });
    const { id } = ataBriefingEditing;

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    doc.html(refPdf ?? '', {
      // eslint-disable-next-line no-shadow
      async callback(doc) {
        await doc.save('document');
      },
      x: 0,
      y: 0,
      margin: [4, 4, 4, 4], // mm
      autoPaging: 'text',
      width: 210, // 216 = letter paper width in mm, 208 = less the 8mm margin
      windowWidth: 1080, // 816 = letter paper pixel width at 96dpi (web), 786 = less the 30px margin
      html2canvas: {
        logging: false,
        windowWidth: 786, // 816 = letter paper pixel width at 96dpi (web), 786 = less the 30px margin
      },
    });
  }

  async function buscarBancos() {
    setIsLoadingReport(true);
    const response = await api.get(`/integracao/banco`);
    setBancoFinanciamento(response.data);
  }

  async function buscarTemplates() {
    setIsLoadingReport(true);
    const response = await api.get(`/layout-ata/`);

    SetHtmlBase(response.data[0].htmlBase);
  }

  async function listaDadosAta() {
    if (propostaVersao.id) {
      const response = await api.get(
        `/ata-briefing/lista-por-proposta/${propostaVersao.id}`,
      );

      setAtaBriefing(response.data);
      setAtaBriefingEditing(response.data);
    }
  }

  useEffect(() => {
    async function carregarDados() {
      try {
        setIsLoadingSintetica(true);
        const response = await api.get(
          `/proposta-resultado/sintetica?versaoId=${propostaVersao.id}`,
        );

        setResultadoSintetica(response.data as ResultadoSintetica[]);
      } finally {
        setIsLoadingSintetica(false);
      }
    }

    carregarDados();
  }, []);

  useEffect(() => {
    async function carregarDados() {
      setDadosBasicosCarregados(false);
      const response = await api.get(
        `/proposta-resultado/prazo-medio?versaoId=${propostaVersao.id}`,
      );

      const vDataEmbarque = new Date(
        `${String(response.data.dataEmbarque).split('T')[0]}T10:10:10`,
      );

      vDataEmbarque.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      const vDataJuros = new Date(vDataEmbarque);

      vDataJuros.setDate(
        vDataJuros.getDate() - (response.data.diasAntesEmbarque ?? 0),
      );

      const diferenca =
        new Date(vDataEmbarque).getTime() - new Date().getTime();

      let diferencaFinal = 0;

      if (diferenca > 0) diferencaFinal = diferenca / (1000 * 3600 * 24);

      const dataE = `${vDataEmbarque.getFullYear()}-${String(
        vDataEmbarque.getMonth() + 1,
      ).padStart(2, '0')}-${String(vDataEmbarque.getDate()).padStart(2, '0')}`;

      const dataJ = vDataJuros.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });

      setPrecisaSalvar(response.data.existemDados === false ?? true);
      setDataBaseJurosEmbarque(dataJ);
      setDiasParaEmbarque(Math.ceil(diferencaFinal));
      setDataEmbarque(new Date(`${dataE}T10:10:10`));
      setJurosMensal(response.data.jurosMensal ?? 0);
      setDiasAntesEmbarque(response.data.diasAntesEmbarque ?? 0);
      setDiasSomar(response.data.diasSomar ?? 0);
      setDadosBasicosCarregados(true);
    }

    carregarDados();
  }, []);

  async function calcularParcelas(dataEmb: string) {
    const parcelas = propostaVersaoParcelas;

    if (!dataEmb) return [];

    return parcelas.map((item: iPropostaVersaoParcelas) => {
      let diferencaCalcular = 0;

      if (!item.dataPrevista || !dataEmb) diferencaCalcular = 0;
      else {
        const diferenca =
          new Date(
            `${String(item.dataPrevista).split('T')[0]}T10:10:10`,
          ).getTime() - new Date(dataEmb).getTime();

        if (diferenca <= 0) diferencaCalcular = 0;
        else diferencaCalcular = diferenca / (1000 * 3600 * 24);
      }

      const diferencaFinal = Math.floor(diferencaCalcular);

      const divisorFormatado = Number(Number(item.percentual ?? 0) / 100 ?? 0);

      return Math.floor(diferencaFinal * divisorFormatado);
    });
  }

  async function carregarDadosPrazoMedio(
    paramJurosMensal: number,
    paramDiasAntesEmbarque: number,
    paramDataBase?: string | null,
  ) {
    setIsLoadingPrazoMedio(true);

    if (!paramDataBase || !paramDataBase) return;

    const parcelasCalculadas = await calcularParcelas(paramDataBase);

    const totalValorParcelas = propostaVersaoParcelas.reduce(
      (total: number, registro: any) => total + registro.valor ?? 0,
      0,
    );

    const totalSomaProduto = parcelasCalculadas.reduce(
      (tot: number, item: number) => tot + item,
    );

    const precoBruto = resultadoSintetica.find(
      item => item.conta === 'Preço Bruto',
    );

    const calculoPMR =
      totalValorParcelas === (precoBruto?.valor ?? 0) ? totalSomaProduto : 0;

    //= 1*(1+N25)^(P27/30)-1
    const calculoCustoFin =
      1 * (1 + (paramJurosMensal ?? 0) / 100) ** (calculoPMR / 30) - 1;

    const custoFinanceiroFinal = arredondaDecimais(calculoCustoFin * 100, 2);

    // Primeiro filtra para não usar dados que tenham ebitda 2
    const listaNova = resultadoSintetica.filter(
      item => item.conta !== 'Ebitda 2' && item.conta !== 'Custo Financeiro',
    );

    // buscar dados Ebitda
    const ebitdaOriginal = resultadoSintetica.find(
      item => item.conta === 'Ebitda',
    );

    const precoLiquido = resultadoSintetica.find(
      item => item.conta === 'Preço Líquido',
    );

    if (ebitdaOriginal) {
      const valorRob = precoBruto?.valor ?? 0;
      const valorRol = precoLiquido?.valor ?? 0;

      const custoFinanceiroPercentual = custoFinanceiroFinal;
      const custoFinanceiroValor = valorRob * (custoFinanceiroFinal / 100);

      const ebitda2Valor = (ebitdaOriginal.valor ?? 0) - custoFinanceiroValor;
      const ebitda2Percentual = (ebitda2Valor / valorRol) * 100;

      // Efetuar os cálculos para ebitda 2 e adicionar na lista
      listaNova.push({
        conta: 'Custo Financeiro',
        percentualROL: custoFinanceiroPercentual,
        valor: custoFinanceiroValor,
        limitar: true,
      });

      listaNova.push({
        conta: 'Ebitda 2',
        percentualROL: arredondaDecimais(ebitda2Percentual, 2),
        valor: ebitda2Valor,
        limitar: true,
      });

      setResultadoSintetica(listaNova);
    }

    setCustoFinanceiro(custoFinanceiroFinal ?? 0);
    setPMR(calculoPMR ?? 0);
    setJurosMensal(paramJurosMensal ?? 0);
    setDiasAntesEmbarque(paramDiasAntesEmbarque ?? 0);

    const vDataEmbarque = new Date(paramDataBase);
    const vDataJuros = new Date(paramDataBase);

    vDataJuros.setDate(vDataJuros.getDate() - (paramDiasAntesEmbarque ?? 0));

    const diferenca = new Date(vDataEmbarque).getTime() - new Date().getTime();

    let diferencaFinal = 0;

    if (diferenca > 0) diferencaFinal = diferenca / (1000 * 3600 * 24);

    const dataJuro = vDataJuros.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    setDataEmbarque(paramDataBase);
    setDiasParaEmbarque(Math.ceil(diferencaFinal));
    setDataBaseJurosEmbarque(dataJuro);
    setIsLoadingPrazoMedio(false);
  }

  useEffect(() => {
    if (
      dadosBasicosCarregados === true &&
      propostaVersaoParcelas &&
      propostaVersaoParcelas.length > 0 &&
      resultadoSintetica &&
      resultadoSintetica.length > 0 &&
      !resultadoSintetica.find(item => item.conta === 'Ebitda 2')
    ) {
      if (jurosMensal && dataEmbarque) {
        carregarDadosPrazoMedio(jurosMensal, diasAntesEmbarque, dataEmbarque);
      }
    }
  }, [resultadoSintetica, propostaVersaoParcelas, dadosBasicosCarregados]);

  async function listaPapeisAta() {
    if (propostaVersao.id) {
      const response = await api.post(
        `/integracao/niveis-indicadores/tipos-indicadores-ata/`,
        {
          propostaVersaoId: propostaVersao.id,
        },
      );

      setPapeisAta(response.data);
    }
  }

  function formataMoeda(valor?: number | null) {
    if (valor === null || valor === undefined) {
      return '';
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor || 0);
  }
  function DadosDre(): JSX.Element {
    return (
      <>
        <Col sm="6">
          <Row className="ml-0">
            <h6 className="heading-small text-muted mb-1">Visão Sintética</h6>
            {isLoadingSintetica && (
              <CircularProgress
                className="mt-2 ml-2"
                color="inherit"
                size={10}
              />
            )}
          </Row>
          <hr className="mt-2" />

          <Table responsive bordered>
            <thead>
              <tr>
                <Th>Conta</Th>
                <Th>Valores</Th>
                <Th hidden={tabelasLimitadas}>% / ROL</Th>
              </tr>
            </thead>
            <tbody>
              {resultadoSintetica
                .filter((item: ResultadoSintetica) => {
                  if (tabelasLimitadas) return item.limitar === false;
                  return true;
                })
                .map((sintetica, index) => {
                  const { conta, valor, percentualROL } = sintetica;

                  let color = {};
                  if (conta.includes('Ebitda'))
                    color = {
                      backgroundColor: '#afe1fa',
                    };

                  return (
                    <tr key={index} style={{ ...color }}>
                      <Td>
                        <Label className="pt-3 pr-4 text-muted">
                          <strong>{conta}</strong>
                        </Label>
                      </Td>
                      <Td className="text-right">
                        <Label className="pt-3 pr-4 text-muted">
                          <strong>{formatCurrency(valor)}</strong>
                        </Label>
                      </Td>
                      <Td className="text-right" hidden={tabelasLimitadas}>
                        <Label className="pt-3 pr-4 text-muted">
                          <strong>{percentualROL} %</strong>
                        </Label>
                      </Td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </>
    );
  }

  useEffect(() => {
    buscarTemplates();
    listaDadosAta();
    listaPapeisAta();
    buscarBancos();
    // montagemAta();
    // setHtmlEditado(htmlBase);
  }, []);

  const textoProduto = `${
    nomeProposta ?? descricaoProduto
  }, ${marca}, sendo ${sistema} com
  ${quantidadeLances} torres de sustentação, raio
  ${
    Number(comprimentoTotalTubos ?? 0) + (raio1 > raio2 ? raio1 : raio2)
  }M para área irrigada de ${valorArea} HA, composto de:`;

  const dataFormatadaAta = ataBriefingEditing.dataInicio
    ? new Intl.DateTimeFormat('pt-BR').format(
        new Date(ataBriefingEditing.dataInicio),
      )
    : null;
  const bancoSelecionado = bancosFinanciamento?.filter(
    item => item.codigo === propostaVersao.banco,
  );

  const nomeBanco = bancoSelecionado[0]?.razao_social;

  function Parcelas(): JSX.Element {
    return (
      <>
        <Table responsive bordered aria-readonly>
          <thead>
            <tr>
              <Th className="text-center">Percentual</Th>
              <Th className="text-center">Data prevista</Th>
              <Th className="text-center">Meio de pagamento</Th>
              <Th className="text-right">Total</Th>
              <Th className="text-center">Evento</Th>
            </tr>
          </thead>
          <tbody>
            {propostaVersaoParcelas?.map(
              (
                propostaVersaoParcela: IPropostaVersaoParcelas,
                index: number,
              ) => {
                const {
                  id,
                  percentual,
                  dataPrevista,
                  meioPagamentoId,
                  valor,
                  observacao,
                } = propostaVersaoParcela;

                const idRow = index + 1;

                const dataPrevistaSemHora = String(dataPrevista)?.split('T')[0];
                const dataFormatada = dataPrevistaSemHora
                  ? new Intl.DateTimeFormat('pt-BR').format(
                      new Date(dataPrevistaSemHora),
                    )
                  : null;
                return (
                  <tr key={idRow}>
                    <Td className="px-sm-2">{String(percentual)}%</Td>
                    <Td className="px-sm-2">{dataFormatada}</Td>
                    <Td className="px-sm-2">
                      {meioSelecionado?.[0]?.descricao ?? ''}
                    </Td>
                    <Td className="text-right">
                      <Label className="text-muted">
                        <strong>{formatCurrency(valor)}</strong>
                      </Label>
                    </Td>
                    <Td className="text-right">{String(observacao)}</Td>
                  </tr>
                );
              },
            )}
          </tbody>
        </Table>
      </>
    );
  }

  function DadosLocal(): JSX.Element {
    return (
      <>
        <Row className="p-2 mt-1 ml-1">
          <ColQuestao>
            <Labelcabecalho>Data:&nbsp;</Labelcabecalho>&nbsp;
          </ColQuestao>
          <Col>
            {ataBriefingEditing.dataInicio
              ? String(dataFormatadaAta)
              : String(new Intl.DateTimeFormat('pt-BR').format(new Date()))}
          </Col>
        </Row>
        <Row className="p-2 mt-1 ml-1">
          <ColQuestao>
            <Labelcabecalho>Horário:&nbsp;</Labelcabecalho>
          </ColQuestao>
          <Col>{ataBriefingEditing.horario}</Col>
        </Row>
        <Row className="p-2 mt-1 ml-1">
          <ColQuestao>
            <Labelcabecalho>Local:&nbsp;</Labelcabecalho>
          </ColQuestao>
          <Col>{ataBriefingEditing.local}</Col>
        </Row>
      </>
    );
  }

  function Supervisores(): JSX.Element {
    return (
      <>
        <Row className="p-2 mt-1 ml-1">
          <ColQuestao>
            <Labelcabecalho>Participantes:&nbsp;</Labelcabecalho>
          </ColQuestao>
          <Col>{ataBriefingEditing.participantes}</Col>
        </Row>
      </>
    );
  }

  function DadosCopia(): JSX.Element {
    return (
      <>
        <Row className="p-2 mt-1 ml-1">
          <ColQuestao>
            <Labelcabecalho>C/C:&nbsp;</Labelcabecalho>
          </ColQuestao>
          <Col>{ataBriefingEditing.comCopia}</Col>
        </Row>
      </>
    );
  }

  function DadosTitulo(): JSX.Element {
    return (
      <>
        <Row>
          <Col sm="4">
            Proposta:&nbsp;{proposta.id}&nbsp; &nbsp;&nbsp;&nbsp;Versão:
            {propostaVersao.versao}
          </Col>
          <Col sm="8">
            <span className="span-titulo">ATA DE REUNIÃO</span>
          </Col>
        </Row>
      </>
    );
  }

  function DadosTitulo2(): JSX.Element {
    return (
      <>
        DOCUMENTO CONFIDENCIAL E DE CIRCULAÇÃO INTERNA – VALIDADE DESTA ATA
        {validadeProposta} DIAS
      </>
    );
  }

  function DadosTema(): JSX.Element {
    return (
      <>
        <Row sm="12" md="12" xs="12">
          <Col sm="2" className="mt-2 mb-2">
            <LabelTema>TEMA:</LabelTema>
          </Col>

          <Col sm="10" className="mt-2 mb-2">
            {ataBriefingEditing.tema}
          </Col>
        </Row>
      </>
    );
  }

  function DadosEditor(): JSX.Element {
    return (
      <>
        <Row sm="12" md="12" xs="12">
          <Col className="mt-2 mb-2">{ataBriefingEditing.documento}</Col>
        </Row>
      </>
    );
  }

  function DadosFixos(): JSX.Element {
    return (
      <>
        <ConteudoDocumentoAta>
          <div className="space-items mt-6">
            {papeisAta.map(campo => (
              <>
                <Row className="space-items mt-2">
                  <Col>
                    <LabelItem>{campo.papel}: &nbsp;</LabelItem>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SpanTitulo>{campo.pessoa}</SpanTitulo>
                  </Col>
                </Row>
              </>
            ))}
          </div>
          <div className="space-items mt-6">
            <Row style={{ breakBefore: 'auto' }}>
              <Col>
                <LabelItem>Nome do Cliente: &nbsp;</LabelItem>
              </Col>
            </Row>
            <Row style={{ breakBefore: 'auto' }}>
              <Col>
                <SpanTitulo>{proposta.pessoa?.razaoSocial}</SpanTitulo>
              </Col>
            </Row>
          </div>
          <div className="space-items mt-3">
            <Row>
              <Col sm="5">
                <LabelItem>Forma de pagamento:&nbsp;</LabelItem>
              </Col>

              {isFinanciados && (
                <>
                  <Col sm="3">
                    <LabelItem>Banco:&nbsp;</LabelItem>
                  </Col>
                  <Col sm="2">
                    <LabelItem>Formato:&nbsp;</LabelItem>
                  </Col>
                  <Col sm="2">
                    <LabelItem>Linha de Crédito:&nbsp;</LabelItem>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col sm="5">
                <SpanTitulo>{meioSelecionado?.[0]?.descricao ?? ''}</SpanTitulo>
              </Col>

              {isFinanciados && (
                <>
                  <Col sm="3">
                    <SpanTitulo>{nomeBanco}</SpanTitulo>
                  </Col>
                  <Col sm="2">
                    <SpanTitulo>{propostaVersao?.formatoBanco}</SpanTitulo>
                  </Col>
                  <Col sm="2">
                    <SpanTitulo>
                      {propostaVersao?.PropostaLinhaCredito?.nome}
                    </SpanTitulo>
                  </Col>
                </>
              )}
            </Row>
          </div>
          <div className="space-items mt-3" style={{ breakBefore: 'auto' }}>
            <Row>
              <Col>
                <LabelItem>Proposta Fockink ao cliente:&nbsp;</LabelItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <SpanTitulo>{textoProduto}</SpanTitulo>
              </Col>
            </Row>
            <Row>
              {fichaTecnica && (
                <Row>
                  <Col>
                    <LancesBalanco
                      fichaTecnica={fichaTecnica as FichaTecnica}
                    />
                  </Col>
                </Row>
              )}
            </Row>
          </div>
          <div className="space-items mt-3" style={{ breakBefore: 'auto' }}>
            <Row>
              <Col sm="1">
                <LabelItem>Valor:&nbsp;</LabelItem>
              </Col>
              <Col sm="4">
                <LabelItem>
                  (Desconto&nbsp;{propostaVersao.desconto}%)
                </LabelItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <SpanTitulo>
                  {formataMoeda(propostaVersao.valorFinal)}
                </SpanTitulo>
              </Col>
            </Row>
          </div>
          <div className="space-items mt-3" style={{ breakBefore: 'auto' }}>
            <Row>
              <Col>
                <LabelItem>Cronograma:&nbsp;</LabelItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <SpanTitulo>{ataBriefingEditing.cronograma}</SpanTitulo>
              </Col>
            </Row>
          </div>
          <div className="space-items mt-3" style={{ breakBefore: 'auto' }}>
            <Row>
              <Col>
                <Parcelas />
              </Col>
            </Row>
          </div>
          <div className="mt-3 mb-6" style={{ breakBefore: 'always' }}>
            <Row>
              <Col>
                <LabelItem>Dre:&nbsp;</LabelItem>
              </Col>
            </Row>
            {propostaVersao !== null ||
              (propostaVersao !== undefined && (
                <Row style={{ breakBefore: 'auto' }}>
                  <Col>
                    <Resultados
                      propostaVersao={propostaVersao}
                      apenasSintetica
                    />
                  </Col>
                </Row>
              ))}
            <Row>
              <Col>
                <DadosDre />
              </Col>
            </Row>
          </div>
        </ConteudoDocumentoAta>
      </>
    );
  }

  async function processamentoPrint() {
    window.print();
  }

  function printFunction() {
    processamentoPrint();
  }

  function createMarkup() {
    const stringFormatada = htmlBase
      .toString()
      .replace(
        '[titulo]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosTitulo />)}`,
      )
      .replace(
        '[titulo2]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosTitulo2 />)}`,
      )
      .replace(
        '[tema]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosTema />)}`,
      )
      .replace(
        '[dataLocal]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosLocal />)}`,
      )
      .replace(
        '[supervisores]',
        `${ReactDOMServer.renderToStaticMarkup(<Supervisores />)}`,
      )
      .replace(
        '[copia]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosCopia />)}`,
      )
      .replace(
        '[dadosFixos]',
        `${ReactDOMServer.renderToStaticMarkup(<DadosFixos />)}`,
      )
      .replace(
        '[dadosManuaisInformados]',
        `${ataBriefingEditing.documento ?? ''}`,
      )
      .replace('[logo]', '9');
    return { __html: `${stringFormatada}` };
  }

  const css = `
    body {
      font: 14px sans-serif !important;
    }
    span, div {
      font: 14px sans-serif !important;
    }

`;
  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <>
      <div id="page">
        <Header showCards={false} />
        <Container className="mt--7" fluid>
          <Card className="shadow">
            <Form id="page2">
              <header>
                <style>{css}</style>
              </header>
              <body>
                <Row
                  ref={reportTemplateRef}
                  style={{ font: '14px sans-serif' }}
                >
                  <Col className="col">
                    <CardBody>
                      <Geral>
                        <div dangerouslySetInnerHTML={createMarkup()} />
                        <FloatingButton
                          className="hide-on-print"
                          onClick={printFunction}
                        >
                          <FaPrint />
                        </FloatingButton>
                      </Geral>
                    </CardBody>
                  </Col>
                </Row>
              </body>
            </Form>
          </Card>
        </Container>
      </div>
    </>
  );
}
