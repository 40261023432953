import React, { useState, useEffect, useCallback } from 'react';

import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
  Badge,
  CardFooter,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';

import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaTable,
  FaArrowLeft,
} from 'react-icons/fa';

import api from 'services/api';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  iColunasGenerica,
  iTabelaGenerica,
  iTabelaImportacao,
} from 'models/TabelaGenerica';
import generateHash from 'utils/generateHash';
import { useHistory, useLocation } from 'react-router-dom';
import TipoCampoTabelaGenerica, {
  iTipoCampoTabelaGenerica,
} from '../../../staticData/TipoCampoTabelaGenerica';
import './style.css';

interface editTabelaGenerica extends iTabelaGenerica {
  labelValido: boolean;
}

interface editColunasGenerica extends iColunasGenerica {
  labelValido: boolean;
}

type LocationState = {
  stateTabela?: iTabelaGenerica;
};

const TabelaGenericaColuna: React.FC = () => {
  const location = useLocation<LocationState>();
  const { stateTabela } = location.state;

  const history = useHistory();

  const [tabelaGenerica, setTabelaGenerica] = useState(stateTabela);

  const [listaTipoCampo, setListaTipoCampo] = useState(
    [] as iTipoCampoTabelaGenerica[],
  );
  const [listaTabelaGenericaColuna, setListaTabelaGenericaColuna] = useState(
    [] as editColunasGenerica[],
  );
  const [listaTabelaImportacao, setListaTabelaImportacao] = useState(
    [] as iTabelaImportacao[],
  );
  const [mostrarDesativados, setMostrarDesativados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [colunaEditing, setColunaEditing] = useState({} as editColunasGenerica);
  const [formModalState, setFormModalState] = useState(false);

  async function validarRegra(variavelBuscar: string) {
    if (!variavelBuscar) return false;
    return true;
  }

  async function carregarTabelaGenericaColuna() {
    if (!stateTabela) return;

    const response = await api.get(
      `/tabela-generica/${stateTabela.id}/colunas`,
    );

    setListaTabelaGenericaColuna(response.data);
  }

  async function carregarTabelaImportacao() {
    const response = await api.get('/tabela-generica/tabela-importacao');

    setListaTabelaImportacao(response.data);
  }

  async function carregarDados() {
    if (!stateTabela) return;

    setLoading(true);
    setTabelaGenerica(stateTabela);
    setListaTipoCampo(TipoCampoTabelaGenerica);
    await carregarTabelaGenericaColuna();
    await carregarTabelaImportacao();
    setLoading(false);
  }

  useEffect(() => {
    carregarDados();
  }, []);

  async function ativarDesativar(tabelaId: number, ativar: boolean) {
    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      await api.delete(`/tabela-generica/coluna/${tabelaId}?ativo=${ativar}`);
      toast.success('Feito!', {});

      carregarTabelaGenericaColuna();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }
  }

  async function handleAtivar(colunaId: number) {
    Swal.fire({
      title: `Deseja reativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(colunaId, true);
        Swal.close();
      }
    });
  }

  async function handleDelete(colunaId: number) {
    Swal.fire({
      title: `Deseja desativar o Registro?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        ativarDesativar(colunaId, false);
        Swal.close();
      }
    });
  }

  async function handleInsert() {
    setColunaEditing({
      labelValido: false,
    } as editColunasGenerica);
    setFormModalState(!formModalState);
  }

  function handleUpdate(coluna: editColunasGenerica) {
    setColunaEditing({ ...coluna, labelValido: true } as editColunasGenerica);
    setFormModalState(!formModalState);
  }

  async function handleCloseModalColuna() {
    setFormModalState(false);
  }

  async function handleSaveColuna() {
    if (!tabelaGenerica) return;

    if (
      !colunaEditing.nome ||
      !colunaEditing.tipo ||
      (tabelaGenerica.disponibilizarRegras && !colunaEditing.labelRegras)
    ) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (colunaEditing.tipo === 'S') {
      if (
        (colunaEditing.regramento.opcoesOutraOrigem === false &&
          !colunaEditing.regramento.opcoes) ||
        (colunaEditing.regramento.opcoesOutraOrigem === true &&
          (!colunaEditing.associacao.tabelaOrigem ||
            !colunaEditing.associacao.coluna))
      ) {
        toast.error(
          'Todos os campos marcados com * são de preenchimento obrigatório!',
        );
        return;
      }

      if (
        colunaEditing.regramento.opcoesOutraOrigem === true &&
        ((colunaEditing.associacao.colunaFiltro &&
          !colunaEditing.associacao.questaoFiltro) ||
          (!colunaEditing.associacao.colunaFiltro &&
            colunaEditing.associacao.questaoFiltro))
      ) {
        toast.error(
          'Para inclusão de filtro, tanto a coluna quanto a questão devem ser informadas!',
        );
        return;
      }
    }

    const depValidar = colunaEditing.dependencia;

    if (
      depValidar &&
      depValidar.length > 0 &&
      depValidar[0].coluna &&
      !depValidar[0].valor
    ) {
      toast.error(
        'Quando uma regra de exibição for definida, o valor da condição deve ser informada',
      );
      return;
    }

    if (tabelaGenerica.disponibilizarRegras && !colunaEditing.labelValido) {
      const variavel = colunaEditing.labelRegras ?? '';

      const valido = await validarRegra(variavel);
      if (!valido) {
        toast.error(
          'A variável de regra informada já está sendo usada por outro registro',
        );
        return;
      }
    }

    const dependenciaSalvar: any[] = [];

    // Nesse momento, vamos tratar como 1 dependência apenas.
    if (colunaEditing.dependencia && colunaEditing.dependencia.length > 0) {
      dependenciaSalvar.push(colunaEditing.dependencia[0]);

      // Associação abaixo não é necessária
      /* const colunaDep = colunasEditing.find(
        it => String(it.id) === String(colunaEditing.dependenciaColuna),
      );

      if (colunaDep)
        dependenciaSalvar.push({
          coluna: Number(colunaDep.id),
          condicao: '==',
          valor: `${colunaEditing.dependenciaValor}`,
        }); */
    }

    const maxOrder = listaTabelaGenericaColuna.reduce(
      (max: any, current: any) =>
        max && max > current.ordem ? max : current.ordem,
      0,
    );

    setIsSaving(true);
    const toastId = toast.loading('Salvando...');
    try {
      if (colunaEditing.id) {
        await api.put(`/tabela-generica/coluna/${colunaEditing.id}`, {
          ...colunaEditing,
          labelValido: true,
          tabelaGenericaId: tabelaGenerica.id,
          hash: `${tabelaGenerica.id}.${generateHash()}`,
          // ordem: maxOrder + 1, //no update não precisa reordenar
          obrigatorio:
            colunaEditing.regramento.obrigatorio === undefined
              ? false
              : colunaEditing.regramento.obrigatorio,
          dependencia: dependenciaSalvar,
        });
      } else {
        await api.post(`/tabela-generica/coluna`, {
          ...colunaEditing,
          tabelaGenericaId: tabelaGenerica.id,
          ordem: maxOrder + 1,
          hash: !colunaEditing.hash
            ? `${tabelaGenerica.id}.${generateHash()}`
            : colunaEditing.hash,
          labelValido: true,
          dependencia: dependenciaSalvar,
        });
      }

      toast.success('Feito!', {});
      Swal.close();

      setFormModalState(false);
      setColunaEditing({} as editColunasGenerica);
      carregarTabelaGenericaColuna();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text:
          error?.response?.data?.message ??
          'Não foi possível prosseguir com o salvamento',
      });
    } finally {
      toast.dismiss(toastId);
      setIsSaving(false);
    }

    setColunaEditing({
      labelValido: false,
      nome: '',
      obrigatorio: false,
      labelRegras: '',
      tipo: '',
      dependencia: [],
      ordem: 1,
      hash: '',
      id: 0,
      associacao: {},
      regramento: {},
      bloquear: false,
      ativo: true,
      tabelaGenericaId: stateTabela?.id ?? 0,
    } as editColunasGenerica);
  }

  if (!tabelaGenerica) return <></>;

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col sm="6">
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push({
                      pathname: `${'/admin/tabela-generica'}`,
                    });
                  }}
                >
                  <FaArrowLeft />
                  <span className="btn-inner--text">Voltar</span>
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="mb-4 float-right">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  disabled={isSaving || loading}
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={formModalState}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardHeader>
                <Form role="form">
                  <Row>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                        <small>Nome da Coluna *</small>
                        <Input
                          placeholder="Nome da Coluna"
                          value={colunaEditing.nome}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              nome: text.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup className="mb-3">
                        <small>Tipo de Coluna *</small>

                        <Input
                          placeholder="Selecione o Tipo de Coluna *"
                          type="select"
                          value={colunaEditing.tipo}
                          onChange={(event: any) => {
                            setColunaEditing({
                              ...colunaEditing,
                              tipo: event.target.value,
                            });
                          }}
                        >
                          <option value="">Selecione</option>
                          {listaTipoCampo.map(item => (
                            <option value={item.sigla}>{item.nome}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup className="mb-3">
                        <small>Obrigatório?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={colunaEditing.regramento?.obrigatorio}
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  obrigatorio: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      sm="6"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup
                        className="mb-3"
                        hidden={
                          colunaEditing.regramento?.opcoesOutraOrigem ?? false
                        }
                      >
                        <small>Opções (separado por ponto e vírgula)*</small>
                        <Input
                          placeholder="Opções"
                          value={colunaEditing.regramento?.opcoes ?? ''}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                opcoes: text.target.value,
                              },
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup
                        className="mb-3"
                        hidden={
                          !colunaEditing.regramento?.opcoesOutraOrigem ?? false
                        }
                      >
                        <div>
                          <small>Tabela Origem *</small>

                          <Input
                            placeholder="Selecione a Tabela de Origem *"
                            type="select"
                            value={colunaEditing.associacao?.tabelaOrigem}
                            onChange={(event: any) => {
                              setColunaEditing({
                                ...colunaEditing,
                                associacao: {
                                  ...colunaEditing.associacao,
                                  tabelaOrigem: Number(event.target.value),
                                },
                              });
                            }}
                          >
                            <option value="">Selecione</option>
                            {listaTabelaImportacao.map(item => (
                              <option value={item.id}>{item.nome}</option>
                            ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="3"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Selecionar Vários?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={
                              colunaEditing.regramento?.selecionarMultiplos ??
                              false
                            }
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  selecionarMultiplos: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="3"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaOpcoes ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Origem Externa Valores?</small>
                        <br />
                        <label className="custom-toggle">
                          <input
                            checked={
                              colunaEditing.regramento?.opcoesOutraOrigem ??
                              false
                            }
                            type="checkbox"
                            onChange={text =>
                              setColunaEditing({
                                ...colunaEditing,
                                regramento: {
                                  ...colunaEditing.regramento,
                                  opcoesOutraOrigem: text.target.checked,
                                },
                              })
                            }
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="Não"
                            data-label-on="Sim"
                          />
                        </label>
                      </FormGroup>
                    </Col>

                    <Col
                      sm="6"
                      hidden={
                        !colunaEditing.regramento?.opcoesOutraOrigem ||
                        (listaTabelaImportacao.find(it => {
                          return (
                            String(it.id) ===
                            String(colunaEditing.associacao?.tabelaOrigem)
                          );
                        })?.colunas?.length ?? 0) === 0
                      }
                    >
                      <FormGroup className="mb-3">
                        <div>
                          <small>
                            Selecione a coluna em{' '}
                            <i>
                              {listaTabelaImportacao.find(
                                it =>
                                  it.id ===
                                  colunaEditing.associacao?.tabelaOrigem,
                              )?.nome ?? ''}
                            </i>{' '}
                            para exibição *
                          </small>

                          <Input
                            placeholder="Selecione a Coluna"
                            type="select"
                            value={colunaEditing.associacao?.coluna}
                            onChange={(event: any) => {
                              setColunaEditing({
                                ...colunaEditing,
                                associacao: {
                                  ...colunaEditing.associacao,
                                  coluna: event.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">Selecione</option>
                            {listaTabelaImportacao
                              .find(it => {
                                return (
                                  String(it.id) ===
                                  String(colunaEditing.associacao?.tabelaOrigem)
                                );
                              })
                              ?.colunas?.map(item => (
                                <option value={item}>{item}</option>
                              ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col
                      sm="12"
                      hidden={
                        !colunaEditing.regramento?.opcoesOutraOrigem ||
                        (listaTabelaImportacao.find(it => {
                          return (
                            String(it.id) ===
                            String(colunaEditing.associacao?.tabelaOrigem)
                          );
                        })?.colunas?.length ?? 0) === 0
                      }
                    >
                      <Row className="mt-2 mb-2 dados-filtro">
                        <Col sm="12">
                          <p>
                            Filtrar dados mostrados pela{' '}
                            <i>
                              {listaTabelaImportacao.find(
                                it =>
                                  it.id ===
                                  colunaEditing.associacao?.tabelaOrigem,
                              )?.nome ?? ''}
                            </i>
                          </p>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <div>
                              <small>
                                Coluna de{' '}
                                <i>
                                  {listaTabelaImportacao.find(
                                    it =>
                                      it.id ===
                                      colunaEditing.associacao?.tabelaOrigem,
                                  )?.nome ?? ''}
                                </i>{' '}
                                usada para filtro
                              </small>

                              <Input
                                placeholder="Selecione a Coluna"
                                type="select"
                                value={colunaEditing.associacao?.colunaFiltro}
                                onChange={(event: any) => {
                                  setColunaEditing({
                                    ...colunaEditing,
                                    associacao: {
                                      ...colunaEditing.associacao,
                                      colunaFiltro: event.target.value,
                                    },
                                  });
                                }}
                              >
                                <option value="">Selecione</option>
                                {listaTabelaImportacao
                                  .find(it => {
                                    return (
                                      String(it.id) ===
                                      String(
                                        colunaEditing.associacao?.tabelaOrigem,
                                      )
                                    );
                                  })
                                  ?.colunas?.map(item => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <div>
                              <small>
                                Questão onde estará o valor de comparação
                              </small>
                              <Input
                                placeholder="Selecione a questao"
                                type="select"
                                value={colunaEditing.associacao?.questaoFiltro}
                                onChange={(event: any) => {
                                  setColunaEditing({
                                    ...colunaEditing,
                                    associacao: {
                                      ...colunaEditing.associacao,
                                      questaoFiltro: Number(event.target.value),
                                    },
                                  });
                                }}
                              >
                                <option value="">Selecione</option>
                                {listaTabelaGenericaColuna
                                  .filter(item =>
                                    !mostrarDesativados
                                      ? item.ativo === true
                                      : true,
                                  )
                                  .filter(it => it.id !== colunaEditing.id)
                                  .map(item => (
                                    <option value={item.id}>{item.nome}</option>
                                  ))}
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Valor Mínimo (zero se não houver)</small>
                        <Input
                          placeholder="Valor Mínimo"
                          value={colunaEditing.regramento?.valorMinimo}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                valorMinimo: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Valor Máximo (zero se não houver)</small>
                        <Input
                          placeholder="Valor Máximo"
                          value={colunaEditing.regramento?.valorMaximo}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                valorMaximo: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      sm="4"
                      hidden={
                        !colunaEditing.tipo ||
                        !(
                          listaTipoCampo.find(
                            item => item.sigla === colunaEditing.tipo,
                          )?.habilitaCamposNumericos ?? false
                        )
                      }
                    >
                      <FormGroup className="mb-3">
                        <small>Casas Decimais (zero se não houver)</small>
                        <Input
                          placeholder="Casas Decimais"
                          value={colunaEditing.regramento?.casasDecimais}
                          type="number"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              regramento: {
                                ...colunaEditing.regramento,
                                casasDecimais: Number(text.target.value),
                              },
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2 mb-2 dados-filtro">
                    <Col sm="12">
                      <p>Regra para exibição da questão</p>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="mb-3">
                        <small>Exibir apenas quando a questão: </small>
                        <Input
                          placeholder="Mostrar quando"
                          type="select"
                          value={colunaEditing.dependencia?.[0]?.coluna}
                          onChange={(event: any) => {
                            setColunaEditing({
                              ...colunaEditing,
                              dependencia: [
                                {
                                  valor:
                                    colunaEditing.dependencia?.[0]?.valor ?? '',
                                  coluna: Number(event.target.value ?? 0),
                                  condicao: '==',
                                },
                              ],
                            });
                          }}
                        >
                          <option value="">Selecione</option>
                          {listaTabelaGenericaColuna
                            .filter(item =>
                              !mostrarDesativados ? item.ativo === true : true,
                            )
                            .filter(it => it.id !== colunaEditing.id)
                            .map(item => (
                              <option value={item.id}>{item.nome}</option>
                            ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="mb-3">
                        <small>Possuir valor igual à</small>
                        <Input
                          placeholder="Igual à"
                          value={colunaEditing.dependencia?.[0]?.valor}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              dependencia: [
                                {
                                  coluna:
                                    colunaEditing.dependencia?.[0]?.coluna ?? 0,
                                  valor: text.target.value,
                                  condicao: '==',
                                },
                              ],
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6">
                      <FormGroup
                        className="mb-3"
                        hidden={!tabelaGenerica.disponibilizarRegras}
                      >
                        <small>Variável para uso em Regras *</small>
                        <Input
                          placeholder="Variável para uso em Regras"
                          value={colunaEditing.labelRegras ?? ''}
                          type="text"
                          onChange={text =>
                            setColunaEditing({
                              ...colunaEditing,
                              labelRegras: text.target.value,
                              labelValido: false,
                            })
                          }
                        />
                        <div>
                          {colunaEditing.labelRegras && (
                            <small title="Como a variável pode ser chamada na regra">
                              <i>{`#${tabelaGenerica.labelRegras}!${colunaEditing.labelRegras}#`}</i>
                            </small>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardFooter>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="default"
                    type="button"
                    disabled={isSaving || loading}
                    onClick={handleCloseModalColuna}
                  >
                    Fechar
                  </Button>
                  <Button
                    className="my-1"
                    color="primary"
                    type="button"
                    disabled={isSaving || loading}
                    onClick={handleSaveColuna}
                  >
                    Salvar
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Modal>

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col sm="9">
                    <h3 className="mb-0">
                      Colunas da Tabela: {stateTabela?.nome}
                    </h3>
                    <small>{stateTabela?.descricao}</small>
                  </Col>
                  <Col sm="3">
                    <FormControlLabel
                      className="float-right"
                      control={
                        <Checkbox
                          checked={mostrarDesativados}
                          onChange={event =>
                            setMostrarDesativados(event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Mostrar desativados"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th hidden={!tabelaGenerica.disponibilizarRegras}>
                      Identificação nas Regras
                    </th>
                    <th hidden={!mostrarDesativados}>Ativo</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {listaTabelaGenericaColuna
                    .filter(item =>
                      !mostrarDesativados ? item.ativo === true : true,
                    )
                    .map(item => (
                      <tr key={item.id}>
                        <td className="py-2">{item.nome}</td>
                        <td className="py-2">
                          {listaTipoCampo.find(it => it.sigla === item.tipo)
                            ?.nome ?? ''}
                        </td>
                        <td
                          className="py-2"
                          hidden={!tabelaGenerica.disponibilizarRegras}
                        >
                          {item.labelRegras ?? ''}
                        </td>
                        <td className="py-2" hidden={!mostrarDesativados}>
                          <div
                            className={`badge badge-${
                              item.ativo ? 'success' : 'danger'
                            }`}
                          >
                            {item.ativo ? 'Sim' : 'Não'}
                          </div>
                        </td>
                        <td>
                          <div className="float-right">
                            {item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </>
                            )}
                            {!item.ativo && (
                              <>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="info"
                                  type="button"
                                  disabled={isSaving || loading}
                                  onClick={() => {
                                    handleAtivar(item.id);
                                  }}
                                >
                                  <FaCheckCircle />
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TabelaGenericaColuna;
