import api from 'services/api';

interface CurvasBomba {
  seq: number;
  modelo: string;
  fabricante: string;
  faixaVazaoInicial: number | 0;
  faixaVazaoFinal: number | 0;
  faixaAlturaInicial: number | 0;
  faixaAlturaFinal: number | 0;
  seqModeloBomba: number | 0;
  vazao: number | 0;
  alturaInicial: number | 0;
  alturaFinal: number | 0;
  rendAlturaInicial: number | 0;
  rendAltura33p: number | 0;
  rendAltura66p: number | 0;
  rendAlturaFinal: number | 0;
  npshAlturaInicial: number | 0;
  npshAltura33p: number | 0;
  npshAltura66p: number | 0;
  npshAlturaFinal: number | 0;
  rotorAlturaInicial: number | 0;
  rotorAltura33p: number | 0;
  rotorAltura66p: number | 0;
  rotorAlturaFinal: number | 0;
  // calculos
  calcNpsh: number | 0;
  calcRend: number | 0;
  calcRotor: number | 0;
  // Adicionais Jun24
  indicTipo: string;
  codigo: string;
  valor: number | 0;
}

export interface BombaSugeria {
  seq: number;
  modelo: string;
  fabricante: string;
  seqModeloBomba: number;
  alturaDesej: number | 0;
  vazaoDesej: number | 0;
  valido: boolean | false;
  calcAlturaInicial: number | 0;
  calcAlturaFinal: number | 0;
  calcNpsh: number | 0;
  calcRend: number | 0;
  calcRotor: number | 0;
  calcPercOcupacao: number | 0;
  curvas: CurvasBomba[];
  calcPotencia: number | 0;
  valor: number | 0;
  indicTipo: string;
  aplicacao: string;
  grafico: any[];
}

// temporario paa teste
function newBomba() {
  const curvas: CurvasBomba[] = [];
  const grafico: any[] = [];
  return {
    seq: 0,
    modelo: '',
    fabricante: '',
    seqModeloBomba: 0,
    alturaDesej: 0,
    vazaoDesej: 0,
    valido: false,
    calcAlturaInicial: 0,
    calcAlturaFinal: 0,
    calcNpsh: 0,
    calcRend: 0,
    calcRotor: 0,
    calcPercOcupacao: 0,
    curvas,
    valor: 0,
    indicTipo: '',
    aplicacao: '',
    grafico,
  };
}

function cloneJson(estrutura: any) {
  if (estrutura) {
    const varString = JSON.stringify(estrutura);
    return JSON.parse(varString);
  }
  return null;
}

function getGraficoBomba(bombaAnalise: BombaSugeria) {
  const cc: CurvasBomba[] = bombaAnalise.curvas;
  // let chartbb: any[] = [];
  const ponto: any = null;
  const chartbb = cc.map(
    ({ vazao, alturaInicial, alturaFinal, calcNpsh, calcRend, calcRotor }) => ({
      vazao,
      alturaInicial,
      alturaFinal,
      calcNpsh,
      calcRend,
      calcRotor,
      ponto,
    }),
  );

  chartbb.push({
    vazao: bombaAnalise.vazaoDesej,
    alturaInicial: bombaAnalise.calcAlturaInicial,
    alturaFinal: bombaAnalise.calcAlturaFinal,
    calcNpsh: bombaAnalise.calcNpsh,
    calcRend: bombaAnalise.calcRend,
    calcRotor: bombaAnalise.calcRotor,
    ponto: bombaAnalise.alturaDesej,
  });

  return chartbb;
}

function getAreaVariavel(bombaAnalise: BombaSugeria, variavel: string) {
  const bomba: BombaSugeria = cloneJson(bombaAnalise);

  const { alturaDesej, calcAlturaInicial, calcAlturaFinal, curvas } = bomba;

  let AltIni = calcAlturaInicial;
  const AltBase = AltIni;
  let AltFim = calcAlturaFinal;
  const ppAlt = AltFim - AltIni;
  let percAlt = (alturaDesej - AltIni) / ppAlt;

  let rab: any[] = [];
  const ra33: any[] = [];
  const ra66: any[] = [];
  let raa: any[] = [];

  for (let i = 0; i < curvas.length; i += 1) {
    if (variavel === 'npsh') {
      rab.push(curvas[i].npshAlturaInicial);
      ra33.push(curvas[i].npshAltura33p);
      ra66.push(curvas[i].npshAltura66p);
      raa.push(curvas[i].npshAlturaFinal);
    }

    if (variavel === 'rotor') {
      rab.push(curvas[i].rotorAlturaInicial);
      ra33.push(curvas[i].rotorAltura33p);
      ra66.push(curvas[i].rotorAltura66p);
      raa.push(curvas[i].rotorAlturaFinal);
    }

    if (variavel === 'rend') {
      rab.push(curvas[i].rendAlturaInicial);
      ra33.push(curvas[i].rendAltura33p);
      ra66.push(curvas[i].rendAltura66p);
      raa.push(curvas[i].rendAlturaFinal);
    }
  }

  if (percAlt <= 0.3333) {
    raa = ra33;
    AltFim = 0.3333 * ppAlt + AltBase;
  }
  if (percAlt > 0.3333 && percAlt <= 0.6666) {
    AltFim = 0.6666 * ppAlt + AltBase;
    AltIni = 0.3333 * ppAlt + AltBase;
    rab = ra33;
    raa = ra66;
  }
  if (percAlt > 0.6666) {
    AltIni = 0.6666 * ppAlt + AltBase;
    rab = ra66;
  }

  const pontosAlt = AltFim - AltIni;
  percAlt = (alturaDesej - AltIni) / pontosAlt;

  for (let k = 0; k < curvas.length; k += 1) {
    const xfluxo = curvas[k].vazao;

    if (xfluxo > 0) {
      const xraa = raa[k];
      const xrab = rab[k];
      if (variavel === 'rend')
        bomba.curvas[k].calcRend = (xraa - xrab) * percAlt + xrab;
      if (variavel === 'npsh')
        bomba.curvas[k].calcNpsh = (xraa - xrab) * percAlt + xrab;
      if (variavel === 'rotor')
        bomba.curvas[k].calcRotor = (xraa - xrab) * percAlt + xrab;
    }
  }

  return bomba;
}

// funções escala de cores ###################################################################

function calcularPercentuais(arr: number[]): number[] {
  const valoresFiltrados = arr;
  const soma = valoresFiltrados.reduce((acc, valor) => acc + valor, 0);
  const percentuais = valoresFiltrados.map(valor => (valor / soma) * 100);
  return percentuais;
}

function rgbParaHex(r: number, g: number, b: number): string {
  const componenteParaHex = (componente: number) => {
    const hex = componente.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${componenteParaHex(r)}${componenteParaHex(g)}${componenteParaHex(
    b,
  )}`;
}

function interpolarCor(percentual: number): string {
  const verde = { r: 0, g: 255, b: 0 };
  const vermelho = { r: 255, g: 0, b: 0 };

  const r = Math.round(
    vermelho.r * (percentual / 100) + verde.r * (1 - percentual / 100),
  );
  const g = Math.round(
    vermelho.g * (percentual / 100) + verde.g * (1 - percentual / 100),
  );
  const b = Math.round(
    vermelho.b * (percentual / 100) + verde.b * (1 - percentual / 100),
  );

  return rgbParaHex(r, g, b);
}

export function calcularCores(arr: number[]): string[] {
  const percentuais = calcularPercentuais(arr);
  const cores = percentuais.map(interpolarCor);
  return cores;
}

// funções ###################################################################
function getAreaBomba(bombaAnalise: BombaSugeria) {
  const bomba: BombaSugeria = cloneJson(bombaAnalise);
  let ppAltInic: number;
  let ppAltFin: number;
  let ppNpsh: number;
  let ppRend: number;
  let ppRotor: number;
  let ppvazao: number;

  let fxAltInic: number;
  let fxAltFin: number;
  let fxNpsh: number;
  let fxRend: number;
  let fxRotor: number;

  let vazaoMin = 9999;
  let vazaoMax = -9999;
  let qSeg: number;
  let ppDesej: number;
  let percFx: number;
  let difRange = 0;
  let difRangeIni = 0;

  const { vazaoDesej, alturaDesej } = bomba;

  const lista = bomba.curvas;

  for (let i = 0; i < lista.length; i += 1) {
    const xVazao = lista[i].vazao;
    if (xVazao < vazaoMin) vazaoMin = xVazao;
    if (xVazao > vazaoMax) vazaoMax = xVazao;

    const difX = lista[i].alturaFinal - lista[i].alturaInicial;
    if (difRangeIni === 0) difRangeIni = difX;
    if (difX > difRange) difRange = difX;
  }

  qSeg = -1;

  // encontra entre faixas
  for (let i = 0; i < lista.length - 1; i += 1) {
    if (vazaoDesej >= lista[i].vazao && vazaoDesej <= lista[i + 1].vazao) {
      qSeg = i;
      break;
    }
  }

  fxAltInic = 0;
  fxAltFin = 0;

  const I = 10;

  if (qSeg > -1) {
    ppvazao = lista[qSeg + 1].vazao - lista[qSeg].vazao;
    ppAltInic =
      (lista[qSeg + 1].alturaInicial - lista[qSeg].alturaInicial) / ppvazao;

    ppAltFin =
      (lista[qSeg + 1].alturaFinal - lista[qSeg].alturaFinal) / ppvazao;

    ppNpsh = (lista[qSeg + 1].calcNpsh - lista[qSeg].calcNpsh) / ppvazao;
    ppRend = (lista[qSeg + 1].calcRend - lista[qSeg].calcRend) / ppvazao;
    ppRotor = (lista[qSeg + 1].calcRotor - lista[qSeg].calcRotor) / ppvazao;

    ppDesej = vazaoDesej - lista[qSeg].vazao;

    fxAltInic = lista[qSeg].alturaInicial + ppAltInic * ppDesej;
    fxAltFin = lista[qSeg].alturaFinal + ppAltFin * ppDesej;
    fxNpsh = lista[qSeg].calcNpsh + ppNpsh * ppDesej;
    fxRend = lista[qSeg].calcRend + ppRend * ppDesej;
    fxRotor = lista[qSeg].calcRotor + ppRotor * ppDesej;

    bomba.calcAlturaInicial = fxAltInic;
    bomba.calcAlturaFinal = fxAltFin;
    bomba.calcNpsh = fxNpsh;
    bomba.calcRend = fxRend;
    bomba.calcRotor = fxRotor;
    bomba.valido = true;
  }

  if (
    alturaDesej >= fxAltInic &&
    alturaDesej <= fxAltFin &&
    fxAltFin !== fxAltInic
  ) {
    percFx = (alturaDesej - fxAltInic) / (fxAltFin - fxAltInic);

    if (fxAltFin - fxAltInic < difRangeIni) {
      const fxAltInicX = fxAltFin - difRange;
      percFx = (alturaDesej - fxAltInicX) / (fxAltFin - fxAltInicX);
    }

    // rotorEnc = rotorMin + (rotorMax - rotorMin) * percFx;
    bomba.calcPercOcupacao = percFx;
    bomba.valido = true;
  } else {
    bomba.calcAlturaInicial = 0;
    bomba.calcAlturaFinal = 0;
    bomba.calcNpsh = 0;
    bomba.calcRend = 0;
    bomba.calcRotor = 0;
    bomba.calcPercOcupacao = 0;
    bomba.valido = false;
  }

  return bomba;
}

// funções ###################################################################
async function getCurvasBombas(altura: number, vazao: number, modelo: string) {
  // const response = await api.get(`/busca-curvas/${{ altura, vazao }}`);
  // Retornando a coluna INDIC_TIPO, onde seus valores representam o seguinte:
  // P - Padrão
  // B - Balsa
  // S - Submersa

  const url = `/integracao/curvas-bombas/modelos?vazao=${vazao}&altura=${altura}&modelo=${encodeURI(
    modelo,
  )}`;
  const response = await api.get(url);
  console.log(response.data);

  if (response.data) {
    const lista: CurvasBomba[] = response.data;
    const listaBombas: BombaSugeria[] = [];
    let seqAgora = -1;
    let bombaAgora = newBomba();

    for (let i = 0; i < lista.length; i += 1) {
      lista[i].calcNpsh = lista[i].npshAlturaInicial;
      lista[i].calcRend = lista[i].rendAlturaInicial;
      lista[i].calcRotor = lista[i].rotorAlturaInicial;
      if (seqAgora !== lista[i].seqModeloBomba) {
        if (seqAgora > -1) {
          const bb = cloneJson(bombaAgora);
          listaBombas.push(bb);
        }

        // valores inicias
        bombaAgora = newBomba();
        bombaAgora.curvas = [];
        bombaAgora.seq = lista[i].seq;
        bombaAgora.seqModeloBomba = lista[i].seqModeloBomba;
        bombaAgora.modelo = lista[i].modelo;
        bombaAgora.fabricante = lista[i].fabricante;
        bombaAgora.alturaDesej = altura;
        bombaAgora.vazaoDesej = vazao;
        seqAgora = lista[i].seqModeloBomba;
        bombaAgora.valor = i;
        bombaAgora.indicTipo = lista[i]?.indicTipo;
        switch (lista[i].indicTipo) {
          case 'P':
            bombaAgora.aplicacao = 'Bomba Padrão Bombeamento';
            break;
          case 'B':
            bombaAgora.aplicacao = 'Bomba para Balsa';
            break;
          case 'S':
            bombaAgora.aplicacao = 'Bomba para Submersa';
            break;
          default:
            bombaAgora.aplicacao = '';
        }

        bombaAgora.valido = false;
      }
      bombaAgora.curvas.push(lista[i]);
    }
    const bb = cloneJson(bombaAgora);
    listaBombas.push(bb);
    return listaBombas;
  }
  return null;
}

// passo 1 busca todos as bombas possiveis
export async function getListaSugestaoBombas(
  altura: number,
  vazao: number,
  modelo: string,
) {
  let lista: any[] = [];

  try {
    const listaCurvasBombas: any = await getCurvasBombas(altura, vazao, modelo);
    let valorTotalSugeridos = 0;
    if (listaCurvasBombas) {
      for (let i = 0; i < listaCurvasBombas.length; i += 1) {
        let bomba: BombaSugeria = getAreaBomba(listaCurvasBombas[i]);
        bomba = getAreaVariavel(bomba, 'rend');
        bomba = getAreaVariavel(bomba, 'npsh');
        bomba = getAreaVariavel(bomba, 'rotor');
        bomba = getAreaBomba(bomba);
        bomba.calcPotencia = (altura * vazao) / (2.7 * bomba.calcRend);
        if (bomba.valido || bomba.modelo === modelo) {
          bomba.grafico = getGraficoBomba(bomba);
          bomba.curvas = [];
          lista.push(bomba);
          valorTotalSugeridos += bomba.valor;
        }
      }
      // navigator.clipboard.writeText(`${JSON.stringify(lista)}`);
      // return lista;
    }
  } catch (error: any) {
    lista = [];
  }

  return lista;
}
