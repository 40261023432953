import { Row, Table } from 'reactstrap';
import { Container, Label, Td, Th, Typography } from './styles';

export default function CondicoesPagamento(): JSX.Element {
  const data = [
    { id: 1, material: 'Motores Elétricos', fornecedor: 'WEG, ABB;' },
    { id: 2, material: 'Motorredutores ', fornecedor: 'UMC, SEW, WEG;' },
    { id: 3, material: 'Redutores de Rodas', fornecedor: 'UMC, WEG;' },
    { id: 4, material: 'Canhão', fornecedor: 'KOMET, NELSON, SIME;' },
    {
      id: 5,
      material: 'Adutora PVC',
      fornecedor: ' AMANCO, TIGRE, CORR PLASTIK;',
    },
    { id: 6, material: 'Aspersores', fornecedor: 'SENNINGER, NELSON, KOMET;' },
    {
      id: 7,
      material: 'Bombas Centrífugas',
      fornecedor: 'KSB; IMBIL; GRUNDFOS;',
    },
  ];
  function Tabela() {
    return (
      <Table bordered>
        <thead>
          <tr>
            <Th className="text-center">
              <h4>Nº</h4>
            </Th>
            <Th>
              <h4>Material</h4>
            </Th>
            <Th>
              <h4>Fornecedor Base</h4>
            </Th>
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row.id}>
              <Td className="text-center">
                <h4>{row.id}</h4>
              </Td>
              <Td>
                <h4>{row.material}</h4>
              </Td>
              <Td>
                <h4>{row.fornecedor}</h4>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  return (
    <Container>
      <Row>
        <Typography variant="h6" className="float-left mb-3">
          <strong>4 - VENDOR LIST:</strong>
        </Typography>
      </Row>

      <Row>
        <Label>
          Lista dos principais fabricantes considerados na composição da oferta.
          A Fockink se reserva o direito de utilizar fabricantes similares, mas
          que mantenham a mesma qualidade e custos compatíveis, todos
          devidamente homologados pela gestão da qualidade Fockink atendendo às
          normativas e requisitos estabelecidos.
        </Label>
      </Row>
      <div className="mt-4">
        <Tabela />
      </div>
    </Container>
  );
}
