/* eslint-disable no-new */
import { kml } from '@tmcw/togeojson';
import { useContext, useEffect, useState } from 'react';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { Button, Col, Input, Label, Row, Table } from 'reactstrap';
import axios from 'axios';
import api from 'services/api';
import { v4 as uuid } from 'uuid';
import FuncaoEntrada from 'models/FuncaoEntrada';
import { Tooltip, IconButton } from '@material-ui/core';
import Questao from 'models/Questao';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import OrcamentoResposta from 'models/OrcamentoResposta';
import { numbers } from '@material/textfield';
import { AlertTitle } from '@material-ui/lab';
import { MarkunreadTwoTone, Mouse } from '@material-ui/icons';
import Usuario from 'models/Usuario';
import { useAuth } from 'hooks/auth';
import {
  FaInfo,
  FaArrowCircleRight,
  FaUndo,
  FaArrowDown,
  FaInfoCircle,
  FaCalculator,
} from 'react-icons/fa';
import { Th, Td, DivOverflow } from './styles';
import { ConfiguracaoEtapasContext } from '../..';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from '../../../../../variables/charts.js';
import { getAreaEmHectares } from '../ParteQuestoesFormulario/funcoes';

interface elevProp {
  incio: number;
  fim: number;
  diferenca: number;
}

interface PropriedadeKml {
  nome: string;
  valor: string | undefined;
  geometry?: any;
  visivel: boolean;
}

interface Propriedades {
  nome: string;
  valor: string | undefined;
}

interface QuestaoPropriedadeKml {
  nomePropriedadeKml: string;
  questao: Questao | undefined;
  questaoId: number | undefined;
  resposta: string | null | undefined;
}

interface RespostaAlterada {
  questaoId: number | undefined;
  resposta: string | null | undefined;
  questao: Questao | undefined;
  orcamentoParteId: number;
}

interface Props {
  configuracaoId: number;
  orcamentoParteId: number;
  onSave?: any;
  fichaTecnicaId?: number | null;
}

declare let window: any;

window.google.load('visualization', '1', {
  packages: ['columnchart', 'corechart', 'table'],
});
// window.google.charts.setOnLoadCallback(drawChart);

const GOOGLE_MAP_SAMPLE_NUMBER = 500;
let textDrawingMapsPortal: string;
const layerKmlVisivel = true;

let dadosSalvosMapa: boolean;

// eslint-disable-next-line prefer-const
let elevacoes: any = {
  succao: 0,
  bombeamento: 0,
  centro: 0,
  min: 0,
  max: 0,
  ecoDistancia: 0,
  posMin: { lat: 0, lng: 0 },
  posMax: { lat: 0, lng: 0 },

  pImin: 0,
  pImax: 0,
  pIposMin: { lat: 0, lng: 0 },
  pIposMax: { lat: 0, lng: 0 },
};

interface OrcamentoPontosMapaPontos {
  latitude?: number | undefined;
  longitude?: number | undefined;
  elevacao?: number | undefined;
  distancia?: number | undefined;
  ordem?: number | undefined;
  angulo?: number | undefined;
  grausCalculado?: string[];
}

interface OrcamentoPontosMapa {
  orcamentoId: number;
  tipo?: string | undefined;
  pontos?: OrcamentoPontosMapaPontos[];
}

export default function VisualizadorGeo(props: Props): JSX.Element {
  const { onSave, configuracaoId, orcamentoParteId, fichaTecnicaId } = props;
  const { usuario }: { usuario: Usuario } = useAuth();
  const [GoogleSamples, setGoogleSamples] = useState(500);
  const [geoJSON, setGeoJSON] =
    useState<FeatureCollection<Geometry | null, GeoJsonProperties>>();
  const [propriedadesKml, setPropriedadesKml] = useState<PropriedadeKml[]>([]);
  const [propriedades, setPropriedades] = useState<Propriedades[]>([]);
  let [propriedadesDrawing] = useState<PropriedadeKml[]>([]);
  const [funcoesEntradasKml, setFuncoesEntradasKml] = useState<FuncaoEntrada[]>(
    [],
  );
  const [questoesPropriedadesKml, setQuestoesPropriedadesKml] = useState<
    QuestaoPropriedadeKml[]
  >([]);

  const [propriedadeAdutoraKml, setPropriedadeAdutoraKml] = useState('');

  const [salvandoRespostas, setSalvandoRespostas] = useState(false);
  const [salvandoElevacao, setSalvandoElevacao] = useState(false);
  const [caminhoKml, setCaminhoKml] = useState();
  const [respostas, setRespostas] = useState<OrcamentoResposta[]>([]);
  const [globalMap, setGlobalMap] = useState<any>();

  const [showElevationMap, setShowElevationMap] = useState(false);
  const [mapBloqueadoEng, setMapBloqueadoEng] = useState(false);
  const [mapBloqueadoEdicao, setMapBloqueadoEdicao] = useState(false);

  const [pontosElevacao, setPontosElevacao] = useState<
    OrcamentoPontosMapaPontos[]
  >([]);
  const [pontosAdutora, setPontosAdutora] = useState<
    OrcamentoPontosMapaPontos[]
  >([]);
  const [pontosVentosa, setPontosVentosa] = useState<
    OrcamentoPontosMapaPontos[]
  >([]);
  const [localSede, setLocalSede] = useState<OrcamentoPontosMapaPontos[]>([]);

  const [pontosCurvas, setPontosCurvas] = useState<OrcamentoPontosMapaPontos[]>(
    [],
  );
  const [paramElevAdutora, setParamElevAdutora] = useState<elevProp>();

  // Familiar
  const [descricaoProduto, setDescricaoProduto] = useState();
  const [partesConcluidas, setPartesConcluidas] = useState(false);
  const [infoHaLimite, setInfoHaLimite] = useState(0);
  const [raioSemAspFinal, setRaioSemAspFinal] = useState(0);
  const [raioAspFinal, setRaioAspFinal] = useState(0);
  const [areaAspFinal, setAreaAspFinal] = useState(0);
  const [lancesPrevistos, setLancesPrevistos] = useState('');
  const [salvarPeloMapa, setsalvarPeloMapa] = useState(false);
  const [infoAreaManual, setInfoAreaManual] = useState(false);
  const { orcamentoProduto } = useContext(ConfiguracaoEtapasContext);

  const produtoDaConfiguracao = orcamentoProduto?.orcamentoProduto?.[0];

  const grausPossiveisObjeto = [
    {
      valor: 90,
      min: 83,
      max: 97,
      subtarirLiteral: true,
    },
    {
      valor: 60,
      min: 53,
      max: 67,
      subtarirLiteral: true,
    },
    {
      valor: 45,
      min: 38,
      max: 52,
      subtarirLiteral: false,
    },
    {
      valor: 30,
      min: 23,
      max: 37,
      subtarirLiteral: false,
    },
    {
      valor: 15,
      min: 15,
      max: 22,
      subtarirLiteral: false,
    },
    {
      valor: 10,
      min: 8,
      max: 14,
      subtarirLiteral: false,
    },
    {
      valor: 5,
      min: 0,
      max: 7,
      subtarirLiteral: false,
    },
  ];

  const keepItBlocked = false;
  const listaElevacoes: any[] = [];
  let escalaLat = 0.000009119;
  let escalaLng = 0.000010363;

  function sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function respondeSN(pergunta: boolean) {
    if (pergunta) {
      return `Sim`;
    }
    return `Não`;
  }

  async function convertToBase64(
    file: File,
  ): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  }

  // Funções Pivo Familiar e raios rodados
  function setHtmlString(campo: string, valor: string) {
    const htmlLPrv = document.getElementById(campo) as HTMLInputElement;
    if (htmlLPrv) {
      htmlLPrv.setAttribute('value', valor);
      htmlLPrv.innerText = valor;
      htmlLPrv.value = valor;
    }
  }

  function getHtmlString(campo: string) {
    const htmlLPrv = document.getElementById(campo) as HTMLInputElement;
    const infoLPrev = htmlLPrv?.value ?? '';
    return infoLPrev;
  }

  function getHtmlNumber(campo: string): number {
    const htmlLPrv = document.getElementById(campo) as HTMLInputElement;
    const infoLPrev = Number(htmlLPrv?.value) ?? 0;
    return infoLPrev;
  }

  function getPivoFamiliar() {
    const infoPivoFamiliar =
      getHtmlString('descProduto') === 'Pivô AGF - Agric. Familiar';
    return infoPivoFamiliar;
  }

  function getHaLimite() {
    const infoLimite = getHtmlNumber('haLimite');
    setInfoHaLimite(infoLimite);
    return infoLimite;
  }

  async function checkGeodesico(lat: number, lng: number) {
    const toastId = toast.loading(
      'Verificando informações. Por favor, aguarde...',
    );
    const resultado = await api.get(
      `/orcamento/versao/${configuracaoId}/validar-geodesico`,
      {
        params: {
          latitude: lat,
          longitude: lng,
        },
      },
    );

    toast.dismiss(toastId);
    return resultado.data.disponivel ?? false;
  }

  async function checkPosicao() {
    const centro = propriedades.find((item: any) => item.nome === 'centroPivo');

    const localCoord = document.getElementById(
      'coord_input',
    ) as HTMLInputElement;

    const coord = localCoord.value.toString() as string;

    const breakGeo = String(coord).split(',');

    console.log(breakGeo);

    const disp = await checkGeodesico(Number(breakGeo[0]), Number(breakGeo[1]));

    if (disp !== true) {
      toast.error(
        `Os pontos inseridos já estão atribuídos a outro equipamento. Verifique ou contate seu superior.`,
      );
      return false;
    }

    return true;
  }

  async function uploadFile(file: File) {
    try {
      toast.loading('Carregando arquivo....', { position: 'top-right' });
      const base64 = String(await convertToBase64(file))?.split('base64,')[1];
      const filetype = file.name.split('.').pop();
      const response = await api.post('/upload', {
        configuracaoId,
        filetype,
        base64,
      });

      setPropriedadeAdutoraKml('');
      setShowElevationMap(false);
      setPontosAdutora([]);
      setPontosCurvas([]);
      setPontosVentosa([]);
      setLocalSede([]);
      setPontosElevacao([]);

      setCaminhoKml(undefined);
      setCaminhoKml(response.data.url);
    } finally {
      toast.dismiss();
    }
  }

  async function uploadFileNew(file: any) {
    try {
      const fileJsonToString: string = btoa(file);
      // const fileJsonToString: string = btoa('');
      toast.loading('Carregando arquivo...', { position: 'top-right' });
      // const base64 = String(await convertToBase64(file))?.split('base64,')[1];
      const filetype = 'json';
      const response = await api.post('/upload', {
        configuracaoId,
        filetype,
        base64: fileJsonToString,
      });

      await setCaminhoKml(undefined);
      await setCaminhoKml(response.data.url);
    } finally {
      toast.dismiss();
    }
  }

  function convertKmlToGeoJSON(kmlText: string) {
    // const kmlFileToGeoJSON = kml(
    //   new DOMParser().parseFromString(kmlText, 'text/xml'),
    // );
    // const drawxs = document.getElementById('txtMsg') as HTMLInputElement;
    // drawxs.innerText = JSON.stringify(kmlFileToGeoJSON);
    const kmlFileToGeoJSONs = `{"type": "FeatureCollection","features": []}`;
    setGeoJSON(JSON.parse(kmlFileToGeoJSONs));
  }

  function convertKmlToGeoJSONFile(kmlText: string) {
    const kmlFileToGeoJSON = kml(
      new DOMParser().parseFromString(
        kmlText
          .replaceAll('LinearRing', 'LineString')
          .replaceAll('Point', 'XPoint'),
        'text/xml',
      ),
    );
    setGeoJSON(kmlFileToGeoJSON);
  }

  async function handleFileRead(event: React.ChangeEvent<HTMLInputElement>) {
    const file: File | undefined = event?.target?.files?.[0];
    if (!file) return;
    const kmlText = await file.text();
    // await uploadFile(file); ovidio

    convertKmlToGeoJSONFile(kmlText);
  }

  async function carregaRespostas() {
    if (configuracaoId) {
      const response = await api.get(`/orcamento/resposta`, {
        params: { configuracaoId },
      });
      const respostasData: OrcamentoResposta[] = response.data;
      setRespostas(respostasData);
    }
  }

  useEffect(() => {
    async function setFuncoesEntradas() {
      const response = await api.get('/funcao-entrada', {
        params: { kml: true },
      });

      const valores = response.data as FuncaoEntrada[];

      const salvar = valores
        .filter(
          item =>
            (item.funcaoEntradaQuestaoProduto?.length ?? 0) > 0 &&
            item.funcaoEntradaQuestaoProduto?.find(
              it => it.produtoId === produtoDaConfiguracao?.produtoId ?? 0,
            ),
        )
        // eslint-disable-next-line array-callback-return, consistent-return
        .map((item: FuncaoEntrada) => {
          const questaoProduto = item.funcaoEntradaQuestaoProduto?.find(
            it => it.produtoId === produtoDaConfiguracao?.produtoId ?? 0,
          );
          return {
            ...item,
            questaoId: questaoProduto?.questaoId || item.questaoId,
            questao: questaoProduto?.questao || item.questao,
          };
        });

      if (salvar) setFuncoesEntradasKml(salvar);
    }

    setFuncoesEntradas();
    carregaRespostas();
  }, []);

  useEffect(() => {
    async function setDadosConfiguracao() {
      try {
        // limpa memoria
        propriedadesKml.length = 0;
        propriedadesDrawing.length = 0;
        textDrawingMapsPortal = '';

        Swal.fire({
          icon: 'info',
          text: `Aguarde Carregando...`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        const response = await api.get(`/orcamento/${configuracaoId}`);

        if (response.data.caminhoKml) {
          setCaminhoKml(response.data.caminhoKml);
        }
        if (response.data.caminhoKml) {
          const kmlResponse = await axios.get(response.data.caminhoKml);

          if (kmlResponse.data.length > 100) {
            kmlResponse.data = undefined;
          }
          if (kmlResponse.data !== undefined) {
            let drawV = document.getElementById('tagPivo') as HTMLInputElement;

            if (drawV)
              drawV.value = decodeURIComponent(kmlResponse?.data?.nome ?? '');
            drawV = document.getElementById('pac-input') as HTMLInputElement;
            if (drawV)
              drawV.value = decodeURIComponent(
                kmlResponse?.data?.localizacao ?? '',
              );
            drawV = document.getElementById('obsPivo') as HTMLInputElement;
            if (drawV)
              drawV.value = decodeURIComponent(
                kmlResponse?.data?.observacao ?? '',
              );
          }

          const propsObj = Object.keys(kmlResponse.data ?? []);

          setPropriedades(
            propsObj
              .filter(item => item !== 'elements')
              .map((item: any) => {
                return {
                  nome: item,
                  valor: `${kmlResponse.data[item]}`,
                };
              }),
          );

          propriedadesDrawing = kmlResponse.data?.elements;
          textDrawingMapsPortal = JSON.stringify(kmlResponse.data);

          const sede = kmlResponse.data?.elements?.find(
            (item: any) => item.nome === 'sede',
          );

          if (sede && sede.geometry)
            setLocalSede([
              {
                latitude: sede.geometry?.lat,
                longitude: sede.geometry?.lng,
                ordem: 1,
              },
            ]);

          convertKmlToGeoJSON(kmlResponse.data?.elements);
        } else {
          textDrawingMapsPortal = ' ';
          convertKmlToGeoJSON('');
        }

        const drawx = document.getElementById('txtMsg') as HTMLInputElement;
        if (drawx) drawx.innerText = textDrawingMapsPortal;

        propriedadesKml.length = 0;
        // propriedadesKml.splice(0, propriedadesDrawing.length);
        while (propriedadesKml.length > 0) {
          propriedadesKml.pop();
        }

        if (propriedadesDrawing) {
          setPropriedadesKml(propriedadesDrawing);
        } else {
          textDrawingMapsPortal = ' ';
        }
      } finally {
        Swal.close();
      }
    }

    if (configuracaoId) setDadosConfiguracao();
  }, [configuracaoId]);

  function getDifference(p1: number, p2: number) {
    const a1 = p1 > 0 ? p1 : 360 + p1;
    const a2 = p2 > 0 ? p2 : 360 + p2;
    let angle = Math.abs(a1 - a2) + 180;
    if (angle > 180) {
      angle = 360 - angle;
    }
    return Math.abs(angle);
  }

  function displayPathElevation(path: any, elevator: any, map: any) {
    //  samples: GOOGLE_MAP_SAMPLE_NUMBER,

    function curvas() {
      // Curvas
      const curvasDataToSave: OrcamentoPontosMapaPontos[] = [];

      let contadorCurvas = 1;
      let distAcc = 0.0;

      for (let i = 0; i < path.length; i += 1) {
        if (i !== 0 && i !== path.length - 1) {
          const graus: string[] = [];

          const point1 = new google.maps.LatLng({
            lat: path[i - 1].lat,
            lng: path[i - 1].lng,
          });

          const point2 = new google.maps.LatLng({
            lat: path[i].lat,
            lng: path[i].lng,
          });

          const point3 = new google.maps.LatLng({
            lat: path[i + 1].lat,
            lng: path[i + 1].lng,
          });

          distAcc += google.maps.geometry.spherical.computeDistanceBetween(
            point1,
            point2,
          );

          const angle = window.google.maps.geometry.spherical.computeHeading(
            point2,
            point1,
          );

          const angle2 = window.google.maps.geometry.spherical.computeHeading(
            point2,
            point3,
          );

          const retornoAngulo = getDifference(angle, angle2);

          const anguloFormatado = Number(Math.floor(retornoAngulo));

          const grausPossiveisAux = grausPossiveisObjeto;

          let acumulado = anguloFormatado;

          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < grausPossiveisAux.length; j += 1) {
            if (acumulado > 0) {
              const substituirLiteral = grausPossiveisAux[j].subtarirLiteral;

              // Verifica se são iguais
              if (
                acumulado >= grausPossiveisAux[j].min &&
                acumulado <= grausPossiveisAux[j].max
              ) {
                graus.push(`${grausPossiveisAux[j].valor}°`);
                acumulado -= substituirLiteral
                  ? grausPossiveisAux[j].valor
                  : acumulado;
              } else if (
                acumulado >= grausPossiveisAux[j].min &&
                acumulado >= grausPossiveisAux[j].max
              ) {
                graus.push(`${grausPossiveisAux[j].valor}°`);
                acumulado -= substituirLiteral
                  ? grausPossiveisAux[j].valor
                  : acumulado;
              }
            }
          }

          curvasDataToSave.push({
            ordem: contadorCurvas,
            angulo: anguloFormatado,
            grausCalculado: graus,
            distancia: Number(Math.floor(distAcc)),
          });

          // eslint-disable-next-line no-plusplus
          contadorCurvas += 1;
        }
      }

      setPontosCurvas(curvasDataToSave);
    }

    function plotElevation({ results }: any) {
      const chartDiv = document.getElementById('elevation_chart');

      const chart = new window.google.visualization.ComboChart(chartDiv);

      const data = new window.google.visualization.DataTable();

      data.addColumn('string', 'Sample');
      data.addColumn('number', 'Elevação');
      data.addColumn('number', 'Ventosa');

      const comprimentoAdutora =
        window.google.maps.geometry.spherical.computeLength(path);

      // const metroPorPonto = comprimentoAdutora / GOOGLE_MAP_SAMPLE_NUMBER;
      const metroPorPonto = comprimentoAdutora / GoogleSamples;

      const dataToSave: OrcamentoPontosMapaPontos[] = [];
      const ventosaDataToSave: OrcamentoPontosMapaPontos[] = [];
      const sedeDataToSave: OrcamentoPontosMapaPontos[] = [];

      let comprimentoVentosa = 0;
      const BREAK_AT = 700;

      let elevacaoAnterior = 0;
      let isGoingUp = false;

      const paramElev: elevProp = {
        incio: Math.ceil(results[0].elevation),
        fim: Math.ceil(results[results.length - 1].elevation),
        diferenca:
          Math.ceil(results[results.length - 1].elevation) -
          Math.ceil(results[0].elevation),
      };

      for (let i = 0; i < results.length; i += 1) {
        const comprimento = Math.ceil(i * metroPorPonto);
        const elevacao = Math.ceil(results[i].elevation);
        let isStillGoingUp: boolean = isGoingUp;
        let ventosa = null;
        comprimentoVentosa += metroPorPonto;

        dataToSave.push({
          distancia: comprimento,
          elevacao,
          ordem: i + 1,
        });

        if (comprimentoVentosa > BREAK_AT) {
          ventosa = elevacao;
          comprimentoVentosa = 0;
        } else if (elevacaoAnterior > elevacao) {
          isStillGoingUp = false;
          if (isGoingUp !== isStillGoingUp) {
            ventosa = elevacaoAnterior;
            comprimentoVentosa = 0;
          }
        } else if (elevacaoAnterior < elevacao) {
          isStillGoingUp = true;
        }

        if (i + 1 === results.length && isStillGoingUp) {
          ventosa = elevacao;
          comprimentoVentosa = 0;
          isStillGoingUp = true;
        }

        isGoingUp = isStillGoingUp; // Se houver mudança de direção. Altera no valor final

        if (ventosa)
          ventosaDataToSave.push({
            distancia: ventosa,
            elevacao: elevacaoAnterior,
            ordem: i,
          });

        elevacaoAnterior = elevacao; // Define o valor da elevação anterior para o valor corrente, para o próximo loop

        data.addRow([`${comprimento}`, elevacao, ventosa]);
      }
      setParamElevAdutora(paramElev);
      setPontosElevacao(dataToSave);
      setPontosVentosa(ventosaDataToSave);

      // setLocalSede(sedeDataToSave);

      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        legend: 'none',
        titleY: 'Elevação (m)',
        titleX: 'Distância do Início (m)',
        seriesType: 'area',
        series: {
          1: {
            type: 'scatter',
            color: 'red',
            fontSize: 25,
          },
        },
      });

      curvas();
    }

    // Aqui está sendo feito o render da cor da adutora na tabela
    new google.maps.Polyline({
      path,
      strokeColor: '#0000CC',
      strokeOpacity: 0.4,
      map,
    });

    if (path) {
      const xcomprAdutora = google.maps.geometry.spherical.computeLength(path);
      let xGoogleSamples = Math.ceil(xcomprAdutora / 5.8);
      if (xGoogleSamples > 500) {
        xGoogleSamples = 500;
      }
      setGoogleSamples(xGoogleSamples);
    }

    elevator
      .getElevationAlongPath({
        path,
        samples: GoogleSamples,
      })
      .then(plotElevation)
      .catch((e: any) => {
        const chartDiv = document.getElementById('elevation_chart');

        // console.log(e);
      });
  }

  // Continua Programação
  // Funçoes Mapas

  // Ovidio Souza - 28/12/2022
  // Funçoes Pontos

  let mapBloqueado: boolean | false;

  async function gereMap(mapx: any) {
    let trechoSuccao: any;
    let trechoAdutora: any;
    let areaPivo: any;
    let tEnergiaP: any;
    let tEnergiaB: any;
    let areaAspersorFinal: any;
    const areaLances: any[] = [];

    let succao: google.maps.Marker;
    let bombeamento: google.maps.Marker;
    let setorial1: google.maps.Marker;
    let setorial2: google.maps.Marker;
    let energiaP: google.maps.Marker;
    let energiaB: google.maps.Marker;
    let sede: google.maps.Marker;
    let torre: google.maps.Marker;
    let relevoInc: google.maps.Marker;
    let relevoMax: google.maps.Marker;
    let relevoMin: google.maps.Marker;

    let completomove: boolean;
    let completoset: boolean;
    let anguloPivo: any;

    let hectares: any;

    let setorialTriangle: google.maps.Polygon;

    // Funções Estrutura Drawing ##################################################
    function PropDrawing(
      nome: string,
      valor: string | undefined,
      geometry?: any,
      visivel = true,
    ): PropriedadeKml {
      return {
        nome,
        valor,
        geometry,
        visivel,
      };
    }

    function setInnerText(elemet: any, value: any) {
      const msg = document.getElementById(elemet) as HTMLElement;
      if (msg) msg.innerText = value;
    }

    function toRadians(deg: any) {
      return (deg * Math.PI) / 180;
    }

    function distanciaLongLat(p1: any, p2: any) {
      let result = 0;

      if (!(p1.lat === p2.lat && p1.lng === p2.lng)) {
        result =
          Math.acos(
            Math.sin(toRadians(p2.lat)) * Math.sin(toRadians(p1.lat)) +
              Math.cos(toRadians(p2.lat)) *
                Math.cos(toRadians(p1.lat)) *
                Math.cos(toRadians(p1.lng - p2.lng)),
          ) *
          6371 *
          1000;
      }

      return result;
    }

    // altera para formato KML
    function toCoord(coord: any) {
      let coordx = coord.toString() as string;
      coordx = coordx.replace('(', '');
      coordx = coordx.replace(')', '');
      const arry1 = coordx.split(', ');
      return `${arry1[1]},${arry1[0]},0`;
    }

    function toCoordAdd(coord: any, addLat: any, addLng: any) {
      let coordx = coord.toString() as string;
      coordx = coordx.replace('(', '');
      coordx = coordx.replace(')', '');
      const arry1 = coordx.split(', ');
      const coordy = {
        lat: Number(arry1[0]) + addLat,
        lng: Number(arry1[1]) + addLng,
      };
      return coordy;
    }

    function toCoordLatLng(coord: any) {
      let coordx = coord.toString() as string;
      coordx = coordx.replace('(', '');
      coordx = coordx.replace(')', '');
      const arry1 = coordx.split(', ');
      const coordy = {
        lat: Number(arry1[0]),
        lng: Number(arry1[1]),
      };
      return coordy;
    }

    function toCoordXY(coord: any) {
      let coordx = coord.toString() as string;
      coordx = coordx.replace('(', '') as string;
      coordx = coordx.replace(')', '');
      const arry1 = coordx.split(', ');
      // return (arry1[1]+","+arry1[0])+",0";
      return { x: Number(arry1[1]), y: Number(arry1[0]) };
    }

    function find_angle(p0: any, p1: any, c: any) {
      const p0c = Math.sqrt((c.x - p0.x) ** 2 + (c.y - p0.y) ** 2); // p0->c (b)
      const p1c = Math.sqrt((c.x - p1.x) ** 2 + (c.y - p1.y) ** 2); // p1->c (a)
      const p0p1 = Math.sqrt((p1.x - p0.x) ** 2 + (p1.y - p0.y) ** 2); // p0->p1 (c)
      const angle = Math.acos(
        (p1c * p1c + p0c * p0c - p0p1 * p0p1) / (2 * p1c * p0c),
      );
      return angle * (180 / Math.PI);
    }

    function get_Angle_Center(center: any, ponto: any) {
      let graus =
        (Math.atan2(ponto.lat - center.lat, ponto.lng - center.lng) * 180) /
        Math.PI;
      if (ponto.lat - center.lat < 0) {
        graus = 360 + graus;
      }
      return graus;
    }

    function find_angle_Center(pti: any, ptf: any, center: any) {
      const angPti = get_Angle_Center(center, pti);
      const angPtf = get_Angle_Center(center, ptf);

      let xAng = angPti - angPtf;
      if (angPtf > angPti) {
        xAng = 360 - (angPtf - angPti);
      }

      return xAng;
    }

    function getPathLatLng(trechox: any) {
      const polygonBounds = trechox.getPath();
      let sep = '';
      let bounds = '[';
      for (let i = 0; i < polygonBounds.length; i += 1) {
        bounds = `${bounds + sep}{ "lat": ${polygonBounds
          .getAt(i)
          .lat()}, "lng": ${polygonBounds.getAt(i).lng()} } `;
        sep = ', ';
      }
      return JSON.parse(`${bounds}]`);
    }

    function getAnguloSetorial() {
      let fator = 1.0;
      let ang1 = 360;

      if (setorial1.getVisible()) {
        const cp = toCoordAdd(areaPivo.getCenter(), 0, 0);
        const st1 = toCoordAdd(setorial1.getPosition(), 0, 0);
        const st2 = toCoordAdd(setorial2.getPosition(), 0, 0);
        ang1 = 360 - find_angle_Center(st1, st2, cp);

        fator = Number(ang1 / 360);
      }

      const areaa =
        (areaPivo.getRadius() * areaPivo.getRadius() * Math.PI) / 10000;

      return {
        angulo: Number(ang1.toFixed(0)),
        area: Number((fator * areaa).toFixed(2)),
      };
    }

    function verificaDistanciaElementos() {
      let retorno = false;

      const centro = areaPivo.getCenter()?.toJSON();
      const raio = areaPivo.getRadius()?.toFixed(2);
      const st1 = setorial1.getPosition()?.toJSON();
      const st2 = setorial2.getPosition()?.toJSON();
      const angPti = get_Angle_Center(centro, st1);
      let angPtf = get_Angle_Center(centro, st2);
      if (angPti > angPtf) {
        angPtf += 360;
      }

      // Cria estrutura
      const distP: any[] = [];

      distP.push({
        desc: ' O bombeamento',
        dist: distanciaLongLat(centro, bombeamento.getPosition()?.toJSON()),
        ang: get_Angle_Center(centro, bombeamento.getPosition()?.toJSON()),
      });

      distP.push({
        desc: ' A Succão',
        dist: distanciaLongLat(centro, succao.getPosition()?.toJSON()),
        ang: get_Angle_Center(centro, succao.getPosition()?.toJSON()),
      });

      distP.push({
        desc: ' A Energia do Pivo',
        dist: distanciaLongLat(centro, energiaP.getPosition()?.toJSON()),
        ang: get_Angle_Center(centro, energiaP.getPosition()?.toJSON()),
      });

      distP.push({
        desc: ' A Energia do Bombeamento',
        dist: distanciaLongLat(centro, energiaB.getPosition()?.toJSON()),
        ang: get_Angle_Center(centro, energiaB.getPosition()?.toJSON()),
      });

      distP.push({
        desc: ' A Sede',
        dist: distanciaLongLat(centro, sede.getPosition()?.toJSON()),
        ang: get_Angle_Center(centro, sede.getPosition()?.toJSON()),
      });

      if (torre.getVisible()) {
        distP.push({
          desc: ' A Torre ',
          dist: distanciaLongLat(centro, torre.getPosition()?.toJSON()),
          ang: get_Angle_Center(centro, torre.getPosition()?.toJSON()),
        });
      }

      // faz tratamento
      if (!setorial1.getVisible()) {
        distP.forEach(ponto => {
          if (ponto.dist < raio) {
            retorno = true;
            toast(
              `${ponto.desc} deverá estar fora da atuação do raio do pivo!`,
              { icon: '❗' },
            );
          }
        });
      } else {
        distP.forEach(ponto => {
          if (ponto.dist < raio) {
            let angPtfP = ponto.ang;
            if (angPti > ponto.ang) {
              angPtfP += 360;
            }
            if (angPtfP > angPti && angPtfP < angPtf) {
              retorno = true;
              toast(
                `${ponto.desc} deverá estar fora da atuação do raio do pivo!`,
                { icon: '❗' },
              );
            }
          }
        });
      }
      return retorno;
    }

    // Create Objetos no map #######################################################

    function estimaSetorialHec() {
      let fator = 1.0;
      let ang1 = 360;

      if (setorial1.getVisible()) {
        const cp = toCoordAdd(areaPivo.getCenter(), 0, 0);
        const st1 = toCoordAdd(setorial1.getPosition(), 0, 0);
        const st2 = toCoordAdd(setorial2.getPosition(), 0, 0);
        ang1 = 360 - find_angle_Center(st1, st2, cp);

        fator = Number(ang1 / 360);
      }

      const areaa = (
        (areaPivo.getRadius() * areaPivo.getRadius() * Math.PI) /
        10000
      ).toFixed(1);

      const areaFixa = (fator * Number(areaa)).toFixed(1);

      let area2 = `${areaFixa}ha `;
      const areaFat = (fator * Number(areaa)).toFixed(1);

      area2 = `${areaFat} ha, r: ${areaPivo.getRadius().toFixed(1)}m`;

      setInfoHaLimite(Number(areaFat));
      setRaioSemAspFinal(areaPivo.getRadius().toFixed(1));
      return { area: area2, angulo: ang1, areaFixa };
    }

    function getDistBB_CP() {
      let retorno = '';
      try {
        if (trechoAdutora) {
          // alert(computeLengthPath(trechoAdutora));

          // const adut = computeLengthPath(trechoAdutora);
          const adut =
            1 +
            google.maps.geometry.spherical.computeLength(
              trechoAdutora.getPath(),
            );
          const desn = elevacoes.centro - elevacoes.bombeamento;

          retorno = `, Adutora ( ${adut.toFixed(0)}m, ↨ ${desn.toFixed(0)} m)`;
        }
      } catch (error) {
        toast(`Ver comprimento adutora`, { icon: '⚠️' });
        retorno = '';
      }
      return retorno;
    }

    // Animação
    function estimaSetorial() {
      const areaX: any = estimaSetorialHec();
      const node1 = document.getElementById('labelarea') as HTMLElement;
      const htmlHaLimite = document.getElementById(
        'haLimite',
      ) as HTMLInputElement;
      if (htmlHaLimite) {
        htmlHaLimite.value = areaX?.areaFixa;
      }

      if (!node1) return;

      node1.innerText = `Área a ser irrigada : ${
        areaX.area
      }${getDistBB_CP()}, ang<${areaX.angulo.toFixed(0)}º}`;

      hectares.setLabel({
        text: areaX.area,
        color: '#333333',
        fontSize: '16px',
        fontWeight: 'bold',
      });

      hectares.setPosition(areaPivo.getCenter());

      anguloPivo = areaX.angulo.toFixed(0);

      const posLL = toCoordAdd(areaPivo.getCenter(), 0, 0);
      const node2 = document.getElementById('coord_input') as HTMLInputElement;
      node2.value = `${posLL.lat.toFixed(5)},${posLL.lng.toFixed(5)}`;

      const node3 = document.getElementById('linkEarth') as HTMLLinkElement;
      node3.href = `https://earth.google.com/web/@ ${posLL.lat.toFixed(
        5,
      )},${posLL.lng.toFixed(5)},478.98516058a,2162.053936d,35y,0h,60t,0r`;

      const node4 = document.getElementById('linkSentinel') as HTMLLinkElement;
      node4.href = `https://apps.sentinel-hub.com/sentinel-playground/?source=S2L2A&lat=${posLL.lat.toFixed(
        5,
      )}&lng=${posLL.lng.toFixed(
        5,
      )}&zoom=15&preset=1_TRUE_COLOR&layers=B01,B02,B03&maxcc=2&gain=2.2&gamma=1.1&atmFilter=&showDates=true`;
      // https://apps.sentinel-hub.com/sentinel-playground/?source=S2L2A&lat=-23.576554639022564&lng=-52.26013898849487&zoom=15&preset=1_TRUE_COLOR&layers=B01,B02,B03&maxcc=0&gain=2.8&gamma=1.1&atmFilter=&showDates=true
    }

    function createTrechosEnerg(): void {
      // ovidio
      const coord1: google.maps.LatLng[] = [];
      coord1.push(energiaB.getPosition() as google.maps.LatLng);
      coord1.push(bombeamento.getPosition() as google.maps.LatLng);

      if (!tEnergiaB) {
        tEnergiaB = new google.maps.Polyline({
          path: coord1,
          strokeOpacity: 1.0,
          strokeColor: '#ff00aa',
          strokeWeight: 1,
          clickable: false,
          editable: !mapBloqueado,
          zIndex: 1,
        });
        tEnergiaB.setMap(mapx);
      }
      tEnergiaB.setPath(coord1);

      let coord2: google.maps.LatLng[] = [];
      const ptb = bombeamento.getPosition() as google.maps.LatLng;
      const pte = energiaP.getPosition() as google.maps.LatLng;

      const dbe = distanciaLongLat(
        energiaP.getPosition()?.toJSON(),
        bombeamento.getPosition()?.toJSON(),
      );

      coord2.push(energiaP.getPosition() as google.maps.LatLng);
      coord2.push(areaPivo.getCenter() as google.maps.LatLng);

      if (dbe < 10 && trechoAdutora) {
        const polygonBounds = trechoAdutora.getPath();
        coord2 = polygonBounds.h;
      }

      if (!tEnergiaP) {
        tEnergiaP = new google.maps.Polyline({
          path: coord2,
          strokeOpacity: 1.0,
          strokeColor: '#ff010a',
          strokeWeight: 1,
          clickable: false,
          editable: !mapBloqueado,
          zIndex: 1,
        });
        tEnergiaP.setMap(mapx);
      }
      tEnergiaP.setPath(coord2);

      tEnergiaP.addListener('mouseup', () => {
        estimaSetorial();
      });
    }

    // solicita reprocessamento das elevações
    function reprocessarElevacoes() {
      listaElevacoes.length = 0;
      relevoInc?.setVisible(false);
      relevoMax?.setVisible(false);
      relevoMin?.setVisible(false);
      areaAspersorFinal?.setMap(null);
      areaLances.forEach(lance => {
        lance?.setMap(null);
      });
      setLancesPrevistos('');
      // areaAspersorFinal = undefined;
    }

    // Trecho
    function createTrechos(): void {
      const coord1: google.maps.LatLng[] = [];
      coord1.push(succao.getPosition() as google.maps.LatLng);
      coord1.push(bombeamento.getPosition() as google.maps.LatLng);
      // alert('cria trecho');
      if (!trechoSuccao) {
        trechoSuccao = new google.maps.Polyline({
          path: coord1,
          strokeOpacity: 1.0,
          strokeColor: '#00f0ff',
          strokeWeight: 2,
          clickable: false,
          editable: !mapBloqueado,
          zIndex: 1,
        });
        trechoSuccao.setMap(mapx);
      }
      trechoSuccao.setPath(coord1);

      const coord2: google.maps.LatLng[] = [];
      coord2.push(bombeamento.getPosition() as google.maps.LatLng);

      if (trechoAdutora) {
        let coord3: google.maps.LatLng[] = [];
        coord3 = getPathLatLng(trechoAdutora);
        if (coord3.length > 2) {
          for (let pp = 1; pp < coord3.length - 1; pp += 1) {
            coord2.push(coord3[pp]);
          }
        }
      }

      coord2.push(areaPivo.getCenter() as google.maps.LatLng);

      // alert(JSON.stringify(coord2));

      if (!trechoAdutora) {
        trechoAdutora = new google.maps.Polyline({
          path: coord2,
          strokeOpacity: 1.0,
          strokeColor: '#0001ff',
          strokeWeight: 4,
          clickable: false,
          editable: !mapBloqueado,
          zIndex: 1,
        });
        trechoAdutora.setMap(mapx);
      }

      trechoAdutora.setPath(coord2);

      trechoAdutora.addListener('mouseup', () => {
        estimaSetorial();
        reprocessarElevacoes();
      });

      createTrechosEnerg();
      // verificaDistanciaElementos();
    }

    // Criar estrutura Json SALVE #####################################################
    function saveElementsDrawing() {
      // valor = {dist: distancia}
      propriedadesDrawing.length = 0;
      propriedadesDrawing.splice(0, propriedadesDrawing.length);
      while (propriedadesDrawing.length > 0) {
        propriedadesDrawing.pop();
      }

      const comprtrechoS =
        1 +
        google.maps.geometry.spherical.computeLength(trechoSuccao.getPath());

      propriedadesDrawing.push(
        PropDrawing(
          'trechoSuccao',
          comprtrechoS.toFixed(0),
          getPathLatLng(trechoSuccao),
          true,
        ),
      );

      const comprtrechoA =
        1 +
        google.maps.geometry.spherical.computeLength(trechoAdutora.getPath());

      propriedadesDrawing.push(
        PropDrawing(
          'trechoAdutora',
          comprtrechoA.toFixed(0),
          getPathLatLng(trechoAdutora),
          true,
        ),
      ); // Pergunta: Comprimento total da saída da casa de máquinas até o centro do pivô

      const comprtrechoEP =
        1 + google.maps.geometry.spherical.computeLength(tEnergiaP.getPath());

      propriedadesDrawing.push(
        PropDrawing(
          'tEnergiaP',
          comprtrechoEP.toFixed(0),
          getPathLatLng(tEnergiaP),
          true,
        ),
      ); // Pergunta: Distância da Fonte de Energia até o Centro do Pivô

      const comprtrechoEB =
        1 + google.maps.geometry.spherical.computeLength(tEnergiaB.getPath());

      propriedadesDrawing.push(
        PropDrawing(
          'tEnergiaB',
          comprtrechoEB.toFixed(0),
          getPathLatLng(tEnergiaB),
          true,
        ),
      ); // Pergunta: Comprimento de cabo do Disjuntor ao QC

      // valor = {area: area, angulo: angulo, desnivel: desnivel, raio: raio}
      // Pergunta: Área disponível a ser irrigada sem aspersor final
      // Pergunta: Ângulo de rotação a ser irrigado

      const retSet = getAnguloSetorial(); // ovidio verificar para dar o retorno @@@@@@@

      const raio = Number(areaPivo.getRadius().toFixed(2));
      const valor = `{"ha": ${retSet.area}, "raio": ${raio}, "ang": ${retSet.angulo}}`;
      propriedadesDrawing.push(
        PropDrawing('areaPivo', valor, areaPivo.getCenter(), true),
      );

      propriedadesDrawing.push(
        PropDrawing('succao', '', succao.getPosition(), true),
      );
      propriedadesDrawing.push(
        PropDrawing('bombeamento', '', bombeamento.getPosition(), true),
      );
      propriedadesDrawing.push(
        PropDrawing('energiaP', '', energiaP.getPosition(), true),
      );
      propriedadesDrawing.push(
        PropDrawing('energiaB', '', energiaB.getPosition(), true),
      );
      propriedadesDrawing.push(
        PropDrawing('sede', '', sede.getPosition(), true),
      );

      if (sede && sede.getPosition())
        setLocalSede([
          {
            latitude: sede.getPosition()?.lat() ?? 0,
            longitude: sede.getPosition()?.lng() ?? 0,
            ordem: 1,
          },
        ]);

      // Condicional visivel === true;
      propriedadesDrawing.push(
        PropDrawing(
          'setorial1',
          '',
          setorial1.getPosition(),
          setorial1.getVisible(),
        ),
      );
      propriedadesDrawing.push(
        PropDrawing(
          'setorial2',
          '',
          setorial2.getPosition(),
          setorial2.getVisible(),
        ),
      );
      propriedadesDrawing.push(
        PropDrawing('torre', '', torre.getPosition(), torre.getVisible()),
      );

      // Monta cabeçalho
      const nomeX = document.getElementById('tagPivo') as HTMLInputElement;
      if (!nomeX.value) {
        nomeX.value = 'Pivo1';
      }
      const local = document.getElementById('pac-input') as HTMLInputElement;
      const obs = document.getElementById('obsPivo') as HTMLInputElement;
      let cp = areaPivo.getCenter().toString();
      cp = cp.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '');

      const areaC = (
        (areaPivo.getRadius() * areaPivo.getRadius() * Math.PI) /
        10000
      ).toFixed(2);

      let vInc = elevacoes.pImin * -1;
      let vpInc = elevacoes.pImin * -1;

      if (vInc > elevacoes.pImax) {
        vInc = elevacoes.pImin;
        vpInc = elevacoes.pIposMin;
      } else {
        vInc = elevacoes.pImax;
        vpInc = elevacoes.pIposMax;
      }

      // elevações
      if (elevacoes.min > 0 || elevacoes.max > 0) {
        propriedadesDrawing.push(
          PropDrawing('relevoInc', vInc.toString(), vpInc, true),
        );
        propriedadesDrawing.push(
          PropDrawing(
            'relevoMax',
            (elevacoes.max - elevacoes.centro).toFixed(0),
            elevacoes.posMax,
            true,
          ),
        );
        propriedadesDrawing.push(
          PropDrawing(
            'relevoMin',
            (elevacoes.min - elevacoes.centro).toFixed(0),
            elevacoes.posMin,
            true,
          ),
        );
      }

      let coordList: any[] = [];

      for (let ik = 0; ik < listaElevacoes.length; ik += 1) {
        coordList = coordList.concat(listaElevacoes[ik].results);
      }

      let vIncArr = 5;
      let verInc = vInc;

      if (verInc < 0) {
        verInc *= -1;
      }

      if (verInc < 2) {
        alert(
          `Verifique possível erro na busca das declividades, valor encontrado: ${verInc} %`,
        );
      }

      verInc += 0.49;

      if (verInc >= 5 && verInc < 10) {
        vIncArr = 10;
      }
      if (verInc >= 10 && verInc < 15) {
        vIncArr = 15;
      }
      if (verInc >= 15 && verInc < 20) {
        vIncArr = 20;
      }
      if (verInc >= 20 && verInc < 25) {
        vIncArr = 25;
      }
      if (verInc >= 25 && verInc <= 30) {
        vIncArr = 30;
      }
      if (verInc > 30) {
        vIncArr = Number(verInc.toFixed(1));
        alert('Inclinação maior que 30º, Depende de análise!');
      }

      const estrutura = {
        nome: encodeURIComponent(nomeX.value),
        area: Number(retSet.area.toFixed(2)),
        areaCirculo: Number(areaC),
        raio: Number(raio.toFixed(2)),
        angulo: Number(anguloPivo),
        centroPivo: cp,
        trechoSuccao: Number(comprtrechoS.toFixed(0)),
        trechoAdutora: Number(comprtrechoA.toFixed(0)),
        tEnergiaP: Number(comprtrechoEP.toFixed(0)),
        tEnergiaB: Number(comprtrechoEB.toFixed(0)),
        telemetria: respondeSN(torre.getVisible()),
        setorial: respondeSN(setorial2.getVisible()),
        tipo: 'principal',
        deriva: '',
        // elevAdutoraBB: paramElevAdutora?.incio,
        // elevAdutoraCP: paramElevAdutora?.fim,
        // difElevBbCp: paramElevAdutora?.diferenca,
        observacao: encodeURIComponent(obs.value),
        dataModificacao: new Intl.DateTimeFormat('pt-BR').format(new Date()),
        configuracao: configuracaoId,
        userID: usuario.id,
        userName: usuario.username,
        localizacao: encodeURIComponent(local.value),
        relevoInclinPercArred: vIncArr,
        relevoInclinPerc: vInc,
        relevoElevPtAlto: elevacoes.max - elevacoes.centro,
        relevoElevPtBaixo: elevacoes.min - elevacoes.centro,
        relevoElevBombCentro: elevacoes.centro - elevacoes.bombeamento,
        elevacoes,
        elements: propriedadesDrawing,
        coordList,
        // elevacao_adutora: pontosElevacao,
        // pontos_adutora: pontosAdutora,
        // pontos_ventosa: pontosVentosa,

        //   succao: 0,
        // bombeamento: 0,
        // centro: 0,
        // min: 0,
        // max: 0,
        // ecoDistancia: 0,
        // posMin: { lat: 0, lng: 0 },
        // posMax: { lat: 0, lng: 0 },

        // pImin: 0,
        // pImax: 0,
        // pIposMin: { lat: 0, lng: 0 },
        // pIposMax: { lat: 0, lng: 0 },

        // angulos_curvas: pontosCurvas.map((item: OrcamentoPontosMapaPontos) => {
        //   return {
        //     latitude: item.latitude,
        //     longitude: item.longitude,
        //     elevacao: item.elevacao,
        //     distancia: item.distancia,
        //     ordem: item.ordem,
        //     angulo: item.angulo,
        //     curvasCalculadas: item.grausCalculado?.join(','),
        //   };
        // }),
      };

      return estrutura;
    }

    // Geral Create Button *******
    function createButtonControl() {
      const controlButton = document.createElement('button');
      controlButton.style.backgroundColor = '#fff';
      controlButton.style.border = '2px solid #fff';
      controlButton.style.borderRadius = '2px';
      controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlButton.style.color = 'rgb(25,25,25)';
      controlButton.style.cursor = 'pointer';
      controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlButton.style.fontSize = '12px';
      controlButton.style.lineHeight = '25px';
      controlButton.style.margin = '4px 0 16px';
      controlButton.style.padding = '0 5px';
      controlButton.style.textAlign = 'center';
      controlButton.type = 'button';
      controlButton.style.maxWidth = '60px';

      return controlButton;
    }

    function animBounce(marker: any) {
      if (!mapBloqueado) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setInnerText('mapamsg', 'SC = Mova para o local da Sucção');
      }
    }

    function addPosCoordInc(coord: any, valor: any, cor: any, title: any) {
      // alert(valor);
      relevoInc.setTitle(title);
      relevoInc.setLabel({
        text: valor,
        color: cor,
        fontSize: '16px',
        fontWeight: 'bold',
      });
      relevoInc.setVisible(true);
      relevoInc.setPosition(coord);
    }

    function addPosCoordMax(coord: any, valor: any, cor: any, title: any) {
      relevoMax.setTitle(title);
      relevoMax.setLabel({
        text: valor,
        color: cor,
        fontSize: '16px',
        fontWeight: 'bold',
      });
      relevoMax.setVisible(true);
      relevoMax.setPosition(coord);
    }

    function addPosCoordMin(coord: any, valor: any, cor: any, title: any) {
      relevoMin.setTitle(title);
      relevoMin.setLabel({
        text: valor,
        color: cor,
        fontSize: '16px',
        fontWeight: 'bold',
      });
      relevoMin.setVisible(true);
      relevoMin.setPosition(coord);
    }

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Setorial

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Setorial Arc

    function getAngleCenter(center: any, ponto: any) {
      let graus =
        (Math.atan2(ponto.lat - center.lat, ponto.lng - center.lng) * 180) /
        Math.PI;
      if (ponto.lat - center.lat < 0) {
        graus = 360 + graus;
      }
      return graus;
    }

    function distanciaSimples(pt1: any, pt2: any) {
      return Math.sqrt(
        (pt2.lat - pt1.lat) * (pt2.lat - pt1.lat) +
          (pt2.lng - pt1.lng) * (pt2.lng - pt1.lng),
      );
    }

    function getPontoArc(center: any, raio: any, angulo: number) {
      const var1 = toRadians(angulo);
      const pontoLa = Math.sin(var1) * raio * 0.92 + center.lat;
      const pontoLn = Math.cos(var1) * raio + center.lng;

      return { lat: pontoLa, lng: pontoLn };
    }

    function geraPontosArc(center: any, pti: any, ptf: any) {
      let raio = distanciaSimples(center, pti);
      const raio1 = distanciaSimples(center, ptf);
      if (raio1 > raio) {
        raio = raio1;
      }
      const angPti = getAngleCenter(center, pti);
      const angPtf = getAngleCenter(center, ptf);

      const arrayPts: any = [];
      arrayPts.push(center);
      arrayPts.push(pti);

      let xAng = (angPti - angPtf) / 5;
      if (angPtf > angPti) {
        xAng = (360 - angPtf + angPti) / 5;
      }

      let iniAng = angPti;

      for (let i = 0; i < xAng - 1; i += 1) {
        iniAng -= 5;

        if (iniAng < 0) {
          arrayPts.push(getPontoArc(center, raio, 360 + iniAng));
        } else {
          arrayPts.push(getPontoArc(center, raio, iniAng));
        }
      }

      arrayPts.push(ptf);
      arrayPts.push(center);
      return arrayPts;
    }

    function setSetorialArc() {
      // estimaSetorial(index);
      // listarPivos();

      const triangleCoords: google.maps.LatLngLiteral[] = geraPontosArc(
        toCoordAdd(areaPivo.getCenter(), 0, 0),
        toCoordAdd(setorial1.getPosition(), 0, 0),
        toCoordAdd(setorial2.getPosition(), 0, 0),
      );

      if (!setorialTriangle) {
        setorialTriangle = new google.maps.Polygon({
          paths: triangleCoords,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#ff0000',
          fillOpacity: 0.5,
          map: mapx,
        });
      }

      setorialTriangle.setPaths(triangleCoords);
      setorialTriangle.setMap(mapx);

      setInnerText('mapamsg', 'Após posicionar os setoriais, Salvar');

      // alert("fim setSetorial"+index);
      estimaSetorial();
      if (setorialTriangle) {
        setorialTriangle.setOptions({ zIndex: 999 });
      }
    }

    // setoriais
    function createSetorialControl() {
      const cb = createButtonControl();
      cb.textContent = 'Setorial';
      cb.title = 'Indicação de Setorial';

      cb.addEventListener('click', () => {
        if (trechoAdutora) {
          if (!mapBloqueado) {
            const var1 = setorial1.getVisible();
            setorial1.setVisible(!var1);
            setorial2.setVisible(!var1);
            // setSetorial();
            setSetorialArc();

            setorialTriangle.setVisible(!var1);
            if (!mapBloqueado) {
              animBounce(setorial1);
            }
            if (!var1) {
              setInnerText('mapamsg', 'Após posicionar os setoriais, Salvar');
            } else {
              setInnerText('mapamsg', 'Click em Salva');
            }
            estimaSetorial();
          }
        } else {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          }
          // toast.error(`Insira o Pivô na área e posicone os pontos`);
          toast(`Insira o Pivô na área e posicone os pontos`, { icon: '⚠️' });
        }

        reprocessarElevacoes();
      });
      return cb;
    }

    // Salva dados antes de enviar
    async function createSalvarControl() {
      const cb = createButtonControl();
      cb.textContent = 'Salvar';
      cb.title = 'Validar os dados, prossiga no formulário abaixo';
      cb.style.color = '#ff0000';

      cb.addEventListener('click', () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }

        if (!relevoInc?.getVisible()) {
          toast.error('É necessário realizar análise do Relevo!');
        } else {
          if (completomove && !verificaDistanciaElementos()) {
            // txtMsg
            const valor1 = JSON.stringify(saveElementsDrawing());
            textDrawingMapsPortal = valor1;
            setInnerText('txtMsg', textDrawingMapsPortal);
            setInnerText(
              'mapamsg',
              'Click em enviar solicitação abaixo do mapa!',
            );

            const obsPivo = document.getElementById(
              'obsPivo',
            ) as HTMLTextAreaElement;

            if (obsPivo.value === 'limpar') {
              uploadFileNew('');
            } else {
              uploadFileNew(textDrawingMapsPortal);
            }

            // alert('Dados válidos!');

            // recarrega elementos
            const elementosObj = JSON.parse(valor1);
            propriedadesDrawing = elementosObj.elements;
            propriedadesKml.length = 0;
            if (propriedadesDrawing) {
              /* for (
              let index = 0;
              index < propriedadesDrawing.length;
              index += 1
            ) {
              propriedadesKml.push(propriedadesDrawing[index]);
            } */

              const propsObj = Object.keys(elementosObj);

              setPropriedades(
                propsObj
                  .filter(item => item !== 'elements')
                  .map((item: any) => {
                    return {
                      nome: item,
                      valor: `${elementosObj[item]}`,
                    };
                  }),
              );

              setPropriedadesKml(propriedadesDrawing);
            }
            toast.success('Informações salvas!');
            dadosSalvosMapa = true;
          } else {
            // toast.error('Indique todos os pontos!');
            toast.error(`Indique todos os pontos!`);
            setInnerText('mapamsg', 'Mova todos os pontos na tela!');
          }
          estimaSetorial();
          setsalvarPeloMapa(true);
        }
      });

      return cb;
    }

    // Ferramenta
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          // google.maps.drawing.OverlayType.CIRCLE,
          // google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      circleOptions: {
        fillColor: '#00ff00',
        fillOpacity: 0.3,
        strokeWeight: 2,
        clickable: false,
        editable: !mapBloqueado,
        zIndex: 1,
      },
      polylineOptions: {
        strokeColor: '#00f0ff',
        strokeWeight: 2,
        clickable: false,
        editable: !mapBloqueado,
        zIndex: 1,
      },
    });

    // Funções Gerais dp mapa
    function areaPivoMod() {
      let coord = areaPivo.getCenter().toString();
      coord = toCoord(coord);
      const radius1 = areaPivo.getRadius();
      if (setorial1.getVisible()) {
        setSetorialArc();
      }
      estimaSetorial();
      if (completomove) {
        createTrechosEnerg();
        createTrechos();
      }

      reprocessarElevacoes();
      const descProduto = getHtmlString('descProduto');
      const infoLimite: number = getHaLimite();
      const infoRaio = getHtmlNumber('raioSemAspFinal');
      // toast(`${descProduto} ${infoLimite} ha`, { icon: '🆗', });

      if (getPivoFamiliar() && (infoLimite > 50.1 || infoRaio > 399)) {
        Swal.fire({
          icon: 'info',
          text: `Pivô AGF - Agric. Familiar o limite é de 50ha ou raio inferior a 399m!, Favor redimensionar a área!`,
          allowOutsideClick: true,
          showConfirmButton: true,
        });
      }
    }

    function addmarkes(coord: any, raio?: number) {
      if (!areaPivo && raio) {
        const rAspFinal = getHtmlNumber('raioAspFinal');
        if (rAspFinal > 0) {
          areaAspersorFinal = new google.maps.Circle({
            fillColor: '#00ffff',
            fillOpacity: 0.1,
            strokeWeight: 1,
            strokeColor: '#22f499',
            clickable: false,
            editable: false,
            zIndex: 1,
            map: mapx,
            center: toCoordAdd(coord, 0, 0),
            radius: rAspFinal,
          });
        }

        // 'lances'
        const lancesPrv = getHtmlString('lancesPrevistos');
        const arrayLances: any[] = lancesPrv.split(';');
        let somaRaio = 0;
        arrayLances.forEach(lance => {
          somaRaio += Number(lance);
          const areaL = new google.maps.Circle({
            fillColor: '#00ffff',
            fillOpacity: 0,
            strokeWeight: 1,
            strokeColor: '#22f499',
            clickable: true,
            editable: false,
            zIndex: 1,
            map: mapx,
            center: toCoordAdd(coord, 0, 0),
            radius: somaRaio,
          });

          areaLances.push(areaL);
        });

        areaPivo = new google.maps.Circle({
          fillColor: '#00ff00',
          fillOpacity: 0.3,
          strokeWeight: 2,
          clickable: false,
          editable: !mapBloqueado,
          zIndex: 1,
          map: mapx,
          center: toCoordAdd(coord, 0, 0),
          radius: raio,
        });

        drawingManager.setDrawingMode(null);
        areaPivo.addListener('bounds_changed', areaPivoMod);
      }

      if (!sede) {
        setorial1 = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#00492C',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Setorial Inicial |--->',
          label: { text: 'sI', color: '#00ff00' },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, 0.002, -0.002),
        });

        setorial2 = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#00492C',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Setorial Final  |<---',
          label: { text: 'sF', color: '#00ff00' },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, 0.002, 0.002),
        });
        // M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5Z M16 1C16 1 17.5 2 17.5 4C17.5 6 16 7 16 7 M8 1C8 1 6.5 2 6.5 4C6.5 6 8 7 8 7 M7 23L8.11111 19M17 23L15.8889 19M14.5 14L12 5L9.5 14M14.5 14H9.5M14.5 14L15.8889 19M9.5 14L8.11111 19M8.11111 19H15.8889
        torre = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M 53.1 48.1 c 3.9 -5.1 6.3 -11.3 6.3 -18.2 C 59.4 13.7 46.2 0.5 30 0.5 C 13.8 0.5 0.6 13.7 0.6 29.9 c 0 6.9 2.5 13.1 6.3 18.2 C 12.8 55.8 30 77.5 30 77.5 S 47.2 55.8 53.1 48.1 z M 17 58 L 30 11 L 44 58 M 24 12 M 34 16 C 36 12 36 11 33 8 M 24 12 M 36 17 C 39 12 39 11 34 6',
            fillColor: '#FFDC13',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Antena Comunicação',
          label: { text: 'Antena', color: '#000000' },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.002),
        });

        // aqui
        relevoInc = new google.maps.Marker({
          map: mapx,
          icon: {
            // path: 'M 2,2 2,50 0,50 0,0 50,0 50,2z',
            path: 'M 2,2 z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(0, 0),
          },
          draggable: false,
          title: 'Percentual inclinação sentido anti-horário',
          label: {
            text: '?',
            color: '#ff0',
            fontSize: '16px',
            fontWeight: 'bold',
          },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.0),
        });
        relevoInc.setVisible(false);

        relevoMax = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M 2,2 z',
            // path: 'M 2,2 2,50 0,50 0,0 50,0 50,2z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(0, 0),
          },
          draggable: false,
          title: 'Ponto mais alto em relação ao Centro',
          label: {
            text: '?',
            color: '#ff0',
            fontSize: '16px',
            fontWeight: 'bold',
          },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.0),
        });
        relevoMax.setVisible(false);

        relevoMin = new google.maps.Marker({
          map: mapx,
          icon: {
            // path: 'M 2,2 2,50 0,50 0,0 50,0 50,2z',
            path: 'M 2,2 z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(0, 0),
          },
          draggable: false,
          title: 'Ponto mais baixo em relação ao Centro',
          label: {
            text: '?',
            color: '#00ffff',
            fontSize: '16px',
            fontWeight: 'bold',
          },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.0),
        });
        relevoMin.setVisible(false);

        torre.setVisible(false);
        setorial1.setVisible(false);
        setorial2.setVisible(false);

        sede = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#ff0',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Sede Fazenda',
          label: { text: 'Sede', color: '#000000' },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.002),
        });

        energiaP = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#ff3333',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.4,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(32, 50),
          },
          draggable: !mapBloqueado,
          title: `Energia do Pivô`,
          label: `eP`,
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.002, -0.002),
        });

        energiaB = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#ff3333',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.4,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Energia do Bombeamento',
          label: 'eB',
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.001, -0.002),
        });

        bombeamento = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Casa de Bombeamento',
          label: 'BB',
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, 0.0, -0.002),
        });

        succao = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
            fillColor: '#00a0ff',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Ponto de Sucção',
          label: 'SC',
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, 0.001, -0.002),
        });

        hectares = new google.maps.Marker({
          map: mapx,
          icon: {
            path: 'M53,48.1z',
            fillColor: '#ff0',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.5,
            anchor: new google.maps.Point(32, 80),
            labelOrigin: new google.maps.Point(30, 30),
          },
          draggable: !mapBloqueado,
          title: 'Pivô',
          label: {
            text: ' ha ? : r: ?',
            color: '#222222',
            fontSize: '24',
            fontWeight: 'bold',
            fontFamily: 'Arial',
          },
          animation: google.maps.Animation.DROP,
          position: toCoordAdd(coord, -0.0, 0.0),
        });

        estimaSetorial();
      }
    }

    function setParamTrechos(
      elementos: PropriedadeKml[],
      nome: string,
      treho: any,
    ) {
      const ponto = elementos.find(obj => {
        return obj.nome === nome;
      });
      // alert(ponto?.geometry);
      // alert(ponto?.geometry.toString);
      // alert(JSON.parse(ponto?.geometry));
      // alert(JSON.stringify(ponto?.geometry));
      treho.setPath(ponto?.geometry);
    }

    function setParamMarker(
      elementos: PropriedadeKml[],
      nome: string,
      marker: google.maps.Marker,
    ) {
      const ponto = elementos.find(obj => {
        return obj.nome === nome;
      });
      marker.setPosition(ponto?.geometry);
      if (ponto?.visivel) {
        marker.setVisible(true);
        if (setorial1.getVisible()) {
          // setSetorial();
          setSetorialArc();
        }
      }
    }

    // Função move Objeto
    function moveBounce(marker: any, obj: any) {
      marker.setAnimation(null);

      if (!completomove) {
        if (obj === 'succao') {
          animBounce(bombeamento);
          setInnerText('mapamsg', 'BB = Local do Bombeamento próximo a sucção');
        }
        if (obj === 'bombeamento') {
          animBounce(energiaB);
          setInnerText('mapamsg', 'Ponto energia para Bombeamento');
        }
        if (obj === 'energiaB') {
          animBounce(energiaP);
          setInnerText('mapamsg', 'eP = Ponto energia para Pivo');
        }
        if (obj === 'energiaP') {
          animBounce(sede);
          setInnerText('mapamsg', 'SEDE = Sede da fazenda');
        }
        if (obj === 'sede') {
          completomove = true;
          createTrechos();
          setInnerText(
            'mapamsg',
            'Escolha: Setorial para indicar restrições área ou Salvar',
          );
          succao.setAnimation(null);
          energiaB.setAnimation(null);
          energiaP.setAnimation(null);
          bombeamento.setAnimation(null);
          sede.setAnimation(null);
        }
      }
      if (!completoset) {
        if (obj === 'setorial1') {
          animBounce(setorial2);
        }
        if (obj === 'setorial2') {
          completoset = true;
        }
      }

      if (
        (obj === 'bombeamento' || obj === 'succao' || obj === 'areaPivo') &&
        completomove
      ) {
        createTrechos();
      }

      if (
        (obj === 'energiaP' || obj === 'energiaB' || obj === 'areaPivo') &&
        completomove
      ) {
        createTrechosEnerg();
      }

      if (obj === 'setorial1' || obj === 'setorial2') {
        // setSetorial();
        setSetorialArc();
      }

      if (obj === 'energiaP') {
        const dbe = distanciaLongLat(
          energiaP.getPosition()?.toJSON(),
          bombeamento.getPosition()?.toJSON(),
        );
        if (dbe < 20 && tEnergiaP) {
          energiaP.setPosition(bombeamento.getPosition());
          tEnergiaP.setPath(trechoAdutora.getPath());
        }
      }

      estimaSetorial();
    }

    function createAddListener(anime: boolean) {
      // @@@@@@@@@@@@ animação Markes
      succao.addListener('dragend', () => {
        moveBounce(succao, 'succao');
      });
      bombeamento.addListener('dragend', () => {
        moveBounce(bombeamento, 'bombeamento');
        reprocessarElevacoes();
      });
      energiaB.addListener('dragend', () => {
        moveBounce(energiaB, 'energiaB');
      });
      energiaP.addListener('dragend', () => {
        moveBounce(energiaP, 'energiaP');
      });
      sede.addListener('dragend', () => {
        moveBounce(sede, 'sede');
      });
      setorial1.addListener('dragend', () => {
        moveBounce(setorial1, 'setorial1');
        reprocessarElevacoes();
      });
      setorial2.addListener('dragend', () => {
        moveBounce(setorial2, 'setorial2');
        reprocessarElevacoes();
      });
      if (anime) {
        animBounce(succao);
      }
    }

    function loadDrawingText(textFile: string) {
      if (textFile.length > 10) {
        const objFile = JSON.parse(textFile);
        completomove = true;
        completoset = true;
        const elementos: PropriedadeKml[] = objFile.elements;

        const pontoCentral = elementos.find(obj => {
          return obj.nome === 'areaPivo';
        });
        const pontoc: google.maps.LatLng = pontoCentral?.geometry;

        mapx.setCenter(pontoc);
        mapx.setZoom(16);
        mapx.setMapTypeId('hybrid');

        const info = pontoCentral?.valor;
        let raio = 350;

        if (info) {
          const valores = JSON.parse(
            info.replaceAll(String.fromCharCode(39), String.fromCharCode(34)),
          );
          raio = valores.raio;
        }

        addmarkes(`(${pontoc.lat}, ${pontoc.lng})`, raio);
        setParamMarker(elementos, 'succao', succao);
        setParamMarker(elementos, 'bombeamento', bombeamento);
        setParamMarker(elementos, 'setorial1', setorial1);
        setParamMarker(elementos, 'setorial2', setorial2);
        setParamMarker(elementos, 'energiaP', energiaP);
        setParamMarker(elementos, 'energiaB', energiaB);
        setParamMarker(elementos, 'sede', sede);
        setParamMarker(elementos, 'torre', torre);

        createTrechos();
        createTrechosEnerg();
        createAddListener(false);

        setParamTrechos(elementos, 'trechoSuccao', trechoSuccao);
        setParamTrechos(elementos, 'trechoAdutora', trechoAdutora);
        setParamTrechos(elementos, 'tEnergiaP', tEnergiaP);
        setParamTrechos(elementos, 'tEnergiaB', tEnergiaB);

        elevacoes = objFile.elevacoes;

        // elevações
        const ponto = elementos.find(obj => {
          return obj.nome === 'relevoMin';
        });
        addPosCoordMin(
          ponto?.geometry,
          `▼ ${ponto?.valor}m`,
          '#00ffff',
          'Ponto mais baixo em relação ao Centro',
        );

        const ponto1 = elementos.find(obj => {
          return obj.nome === 'relevoMax';
        });

        addPosCoordMax(
          ponto1?.geometry,
          `▲ +${ponto1?.valor}m`,
          '#ff0',
          'Ponto mais alto em relação ao Centro',
        );

        const ponto2 = elementos.find(obj => {
          return obj.nome === 'relevoInc';
        });
        addPosCoordInc(
          ponto2?.geometry,
          `Inc: ${ponto2?.valor}%`,
          '#ffcc00',
          'Percentual inclinação sentido anti-horário',
        );

        estimaSetorial();
      }
    }

    // Load Base
    function loadDrawingFileTest(): void {
      const drawx = document.getElementById('txtMsg') as HTMLInputElement;
      const fileContent: string = drawx.value.toString();
      loadDrawingText(fileContent);
    }

    function desbloqueiaDrawing() {
      areaPivo.setDraggable(!mapBloqueado);
      areaPivo.setEditable(!mapBloqueado);
      trechoSuccao.setDraggable(!mapBloqueado);
      trechoSuccao.setEditable(!mapBloqueado);
      trechoAdutora.setDraggable(!mapBloqueado);
      trechoAdutora.setEditable(!mapBloqueado);
      tEnergiaP.setDraggable(!mapBloqueado);
      tEnergiaP.setEditable(!mapBloqueado);
      tEnergiaB.setDraggable(!mapBloqueado);
      tEnergiaB.setEditable(!mapBloqueado);

      succao.setDraggable(!mapBloqueado);
      bombeamento.setDraggable(!mapBloqueado);
      setorial1.setDraggable(!mapBloqueado);
      setorial2.setDraggable(!mapBloqueado);
      energiaP.setDraggable(!mapBloqueado);
      energiaB.setDraggable(!mapBloqueado);
      sede.setDraggable(!mapBloqueado);
      torre.setDraggable(!mapBloqueado);
    }

    // relevo
    // Função elevação multiplo pontos Espiral

    function getAngleInclinacaoVao2P(comprLance: any, elev1: any, elev2: any) {
      const valor = Math.asin((elev2 - elev1) / comprLance);
      return (valor / Math.PI) * 180;
    }

    function getPercInclinacaoVao2P(comprLance: any, elev1: any, elev2: any) {
      const valor = ((elev2 - elev1) / comprLance) * 100;
      return valor;
    }

    function MaxElevationPath({ results }: any) {
      let min = 5000;
      let max = -5000;
      let posMin = {};
      let posMax = {};
      let centrop = areaPivo.getCenter();
      centrop = toCoordAdd(centrop, 0, 0);

      let pImin = 5000;
      let pImax = -5000;
      let pIposMin = {};
      let pIposMax = {};

      const retorno: any[] = [];

      let rElev = results[0].elevation;
      let rPos = toCoordAdd(results[0].location, 0, 0);

      let distAnterior = 0;
      let rDistCAnt = 0;

      for (let i = 0; i < results.length; i += 1) {
        if (distAnterior > 30) {
          rElev = results[i].elevation;
        }
        const rDist =
          distanciaLongLat(rPos, toCoordAdd(results[i].location, 0, 0)) * 0.92;
        distAnterior = rDist;

        let rAng = 0;
        let rPerc = 0;
        let rAngC = 0;
        let rDistC = 0;

        if (rDist < 30) {
          rAng = getAngleInclinacaoVao2P(rDist, rElev, results[i].elevation);
          rPerc = getPercInclinacaoVao2P(rDist, rElev, results[i].elevation);
          rPos = toCoordAdd(results[i].location, 0, 0);
          // rAngC = parseInt(getAngleCenter(centrop,rPos).toFixed(0));
          rAngC = Number((getAngleCenter(centrop, rPos) / 2).toFixed(0)) * 2;
          // rDistC = parseInt((distanciaLongLat(centrop,rPos)).toFixed(0));
          rDistC = Number((distanciaLongLat(centrop, rPos) / 2).toFixed(0)) * 2;
          const ddr = rDistC - rDistCAnt;
          if (ddr > -5 && ddr < 5) {
            rDistC = rDistCAnt;
          } else {
            rDistCAnt = rDistC;
          }
        } else {
          const posLL = toCoordAdd(results[i].location, 0, 0);
          if (!setorial1.getVisible()) {
            toast(
              `Distancia > 30m : ${rDist} pos (${posLL.lat}, ${posLL.lng} )`,
              { icon: '❗' },
            );
          }
          rPos = toCoordAdd(results[i].location, 0, 0);
        }

        const posLL = toCoordAdd(results[i].location, 0, 0);
        // eslint-disable-next-line no-param-reassign
        // results[i].percInclin = rPerc;
        // eslint-disable-next-line no-param-reassign
        // results[i].angInclin = rAng;
        // eslint-disable-next-line no-param-reassign
        // results[i].dist = rDist;
        // eslint-disable-next-line no-param-reassign
        // results[i].angular = rAngC;
        // eslint-disable-next-line no-param-reassign
        // results[i].raio = rDistC;

        rElev = results[i].elevation;
        // rPos = toCoordAdd(results[i].location, 0, 0);

        retorno.push({
          lat: Number(posLL.lat.toFixed(6)),
          lng: Number(posLL.lng.toFixed(6)),
          elevation: Number(results[i].elevation.toFixed(2)),
          percInclin: Number(rPerc.toFixed(2)),
          angInclin: Number(rAng.toFixed(2)),
          distPts: Number(rDist.toFixed(2)),
          angular: rAngC,
          raio: rDistC,
        });

        if (rPerc > pImax) {
          pImax = rPerc;
          pIposMax = results[i].location;
        }
        if (rPerc < pImin) {
          pImin = rPerc;
          pIposMin = results[i].location;
        }

        if (results[i].elevation > max) {
          max = results[i].elevation;
          posMax = results[i].location;
        }
        if (results[i].elevation < min) {
          min = results[i].elevation;
          posMin = results[i].location;
        }
      }

      // Draw the chart using the data within its DIV.
      listaElevacoes.push({
        max: Number(max.toFixed(0)) + 1,
        min: Number(min.toFixed(0)) - 1,
        posMin,
        posMax,
        pImax: Number(pImax.toFixed(2)),
        pImin: Number(pImin.toFixed(2)),
        pIposMin,
        pIposMax,
        results: retorno,
      });

      return retorno;
    }

    function getElevationPath(path: any) {
      sleep(1000);
      const elevator = new google.maps.ElevationService();
      elevator
        .getElevationForLocations({
          locations: path,
        })
        .then(MaxElevationPath)
        .catch((e: any) => {
          alert(e);
        });
    }

    function displayLocationsElevation(path: any) {
      sleep(1000);
      const elevator = new google.maps.ElevationService();
      const ikk = 0;
      const retorno: any[] = [];
      elevator
        .getElevationForLocations({
          locations: path,
        })
        .then(({ results }) => {
          if (results[0]) {
            elevacoes.centro = Number(results[0].elevation?.toFixed(0)) - 1;
          }
          if (results[1]) {
            elevacoes.bombeamento =
              Number(results[1].elevation?.toFixed(0)) - 1;
          }
          if (results[2]) {
            elevacoes.succao = Number(results[2].elevation?.toFixed(0)) + 1;
          }
        })
        .catch(e => alert('erro busca elevações'));

      return retorno;
    }

    async function getElevationPivo() {
      try {
        Swal.fire({
          icon: 'info',
          text: `Aguarde Carregando Elevações...`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        let center: any = areaPivo.getCenter();
        let raio = areaPivo.getRadius();
        let comSt = false;
        const listaCoord: any[] = [];
        let listaC: any[] = [];

        center = toCoordAdd(center, 0, 0);
        const lat = Number(center.lat);
        const lng = Number(center.lng);

        // averição distancia sd
        const alngx = lng + raio * escalaLng;
        const alatx = lat + raio * escalaLat;
        const dlngx = distanciaLongLat(center, { lat, lng: alngx });
        const dlatx = distanciaLongLat(center, { lat: alatx, lng });

        escalaLng = (alngx - lng) / dlngx;
        escalaLat = (alatx - lat) / dlatx;

        let angSt1: any = 0;
        let angSt2: any = 0;

        if (setorial1.getVisible()) {
          const coordSt1 = toCoordAdd(setorial1.getPosition(), 0, 0);
          const coordSt2 = toCoordAdd(setorial2.getPosition(), 0, 0);
          angSt1 = getAngleCenter(center, coordSt1);
          angSt2 = getAngleCenter(center, coordSt2);
          comSt = true;
        }

        let pts = 0;

        // Arquimedes
        // 200	0,059	972
        // 380	0,0412	1952
        // 500	0,0335	2922
        // 650	0,0288	3930
        // 1000	0,0256	4954
        let ftarq = 0.059;
        if (raio > 220 && raio <= 360) {
          ftarq = 0.0412;
        }
        if (raio > 360 && raio <= 500) {
          ftarq = 0.0335;
        }
        if (raio > 500 && raio <= 620) {
          ftarq = 0.0288;
        }
        if (raio > 620) {
          ftarq = 0.0256;
        }

        let distPontos = raio * ftarq; // 2000pts

        elevacoes.ecoDistancia = distPontos;

        if (distPontos < 10) {
          distPontos = 10;
        }

        let ang = 0;

        while (raio > 2) {
          const perim = 2 * Math.PI * raio;
          const nrPtsPerim = Number((perim / distPontos).toFixed(0)) + 1;
          const fadd = (30 / distPontos) * (distPontos / nrPtsPerim);

          for (let pp = 1; pp <= nrPtsPerim; pp += 1) {
            ang += fadd;
            const rang = (2 * Math.PI * ang) / 30;
            const dang = ((rang * 180) / Math.PI) % 360;

            let xadd = !comSt;
            if (comSt) {
              if (angSt1 < angSt2) {
                if (dang > angSt1 && dang < angSt2) {
                  xadd = true;
                }
              }
              if (angSt2 < angSt1) {
                if (dang > angSt1 || dang < angSt2) {
                  xadd = true;
                }
              }
            }

            if (xadd) {
              const lngx = lng + raio * escalaLng * Math.cos(rang);
              const latx = lat + raio * escalaLat * Math.sin(rang);
              const coordll = {
                lat: Number(latx.toFixed(5)),
                lng: Number(lngx.toFixed(5)),
              };
              pts += 1;

              if (pts > 500) {
                listaC.push(coordll);
                listaCoord.push(listaC);
                listaC = [coordll];
                pts = 1;
              } else {
                listaC.push(coordll);
              }
            }
          }

          raio -= distPontos;
        }

        listaC.push(center);
        listaCoord.push(listaC);

        // listaCoord = pontosEstrela12(30, center, areaPivo.getRadius()); // futuro

        for (let iik = 0; iik < listaCoord.length; iik += 1) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(4000);
          Swal.fire({
            icon: 'info',
            text: `Análise elevações do relevo ${iik + 1} de ${
              listaCoord.length
            }`,
            allowOutsideClick: false,
            showConfirmButton: false,
          });

          // alert(`Elevação busca ${iik + 1} de ${listaCoord.length}`);
          const retorno = getElevationPath(listaCoord[iik]);
        }

        Swal.fire({
          icon: 'info',
          text: `Análise dos Desníveis entre os Pontos!`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        await sleep(4000);

        const xPath: any[] = [];
        xPath.push(areaPivo?.getCenter());
        xPath.push(bombeamento?.getPosition());
        if (succao?.getPosition()) {
          xPath.push(succao?.getPosition());
        }
        xPath.push(areaPivo?.getCenter());

        displayLocationsElevation(xPath);

        await sleep(4000);

        if (listaCoord.length === listaElevacoes.length) {
          // alert('Elevação busca concluída!');

          Swal.fire({
            icon: 'info',
            text: `Concluindo consulta Elevação!`,
            allowOutsideClick: false,
            showConfirmButton: false,
          });

          let max = -5000;
          let min = 5000;
          let pImax = -5000;
          let pImin = 5000;

          for (let iik = 0; iik < listaElevacoes.length; iik += 1) {
            const item = listaElevacoes[iik];
            if (item.min < min) {
              elevacoes.min = item.min;
              elevacoes.posMin = item.posMin;
              min = item.min;
            }

            if (item.max > max) {
              elevacoes.max = item.max;
              elevacoes.posMax = item.posMax;
              max = item.max;
            }

            if (item.pImin < pImin) {
              elevacoes.pImin = item.pImin;
              elevacoes.pIposMin = item.pIposMin;
              pImin = item.pImin;
            }

            if (item.pImax > pImax) {
              elevacoes.pImax = item.pImax;
              elevacoes.pIposMax = item.pIposMax;
              pImax = item.pImax;
            }
          }
          relevoMin?.setVisible(true);
          relevoMax?.setVisible(true);
          relevoInc?.setVisible(true);

          addPosCoordMin(
            elevacoes.posMin,
            `▼ ${(elevacoes.min - elevacoes.centro).toFixed(0)}m`,
            '#00ffff',
            'Ponto mais baixo em relação ao Centro',
          );

          addPosCoordMax(
            elevacoes.posMax,
            `▲ +${(elevacoes.max - elevacoes.centro).toFixed(0)}m`,
            '#ff0',
            'Ponto mais alto em relação ao Centro',
          );

          if (-1 * elevacoes.pImin > elevacoes.pImax) {
            addPosCoordInc(
              elevacoes.pIposMin,
              `Inc: ${elevacoes.pImin}%`,
              '#ffcc00',
              'Percentual inclinação sentido anti-horário',
            );
          } else {
            addPosCoordInc(
              elevacoes.pIposMax,
              `Inc: +${elevacoes.pImax}%`,
              '#ffcc00',
              'Percentual inclinação sentido anti-horário',
            );
          }

          const obsPivo = document.getElementById(
            'obsPivo',
          ) as HTMLTextAreaElement;
          obsPivo.innerText = `${JSON.stringify(
            listaElevacoes,
          )}\n${JSON.stringify(elevacoes)}`;
        } else {
          Swal.close();
          toast.error(
            'Houve um erro na busca das elevações, informe o administrador do sistema!',
          );
        }
      } finally {
        Swal.close();
      }
    }

    async function buscaElevacoesTodos() {
      const retorno = await checkPosicao();

      console.log({ retorno });
      if (!retorno) return;

      if (!relevoInc.getVisible()) {
        if (!trechoAdutora) {
          // alert('Deve ser concluido posicionamento dos pontos e setorial!');
          toast('Deve ser concluido posicionamento dos pontos e setorial!', {
            icon: '❗',
          });
        } else {
          await getElevationPivo();
          estimaSetorial();
        }
      } else {
        alert('Elevações já requeridas para Equipamento!');
      }
    }

    // Recarregar
    function createLoadControl() {
      const cb = createButtonControl();
      cb.textContent = 'Editar';
      cb.title = 'Carregar configuração salvas';

      cb.addEventListener('click', () => {
        if (trechoAdutora) {
          mapBloqueado = !mapBloqueado;
          setMapBloqueadoEdicao(mapBloqueado);
          loadDrawingFileTest();
          desbloqueiaDrawing();
          dadosSalvosMapa = false;
        } else {
          toast(`Insira o Pivô na área e posicone os pontos`, { icon: '⚠️' });
        }
      });
      return cb;
    }

    // Pivo
    function createPivoControl() {
      const cb = createButtonControl();
      cb.textContent = 'Pivô';
      cb.title = 'Insere Pivô Individual';

      cb.addEventListener('click', () => {
        setInfoAreaManual(false);
        setHtmlString('raioInfoManual', '');
        if (areaPivo && !mapBloqueado) {
          const haLimite = getHaLimite();
          const raioHaLimite = Math.sqrt((haLimite * 10000) / Math.PI);
          areaPivo.setRadius(raioHaLimite);
          drawingManager.setDrawingMode(null);
        } else {
          drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
        }
      });
      return cb;
    }

    // Pan
    function createPanControl() {
      const cb = createButtonControl();
      cb.textContent = 'Move';
      cb.title = 'Movimentação do local no mapa';

      cb.addEventListener('click', () => {
        drawingManager.setDrawingMode(null);
      });
      return cb;
    }

    // Pan
    function createRelevoControl() {
      const cb = createButtonControl();

      cb.textContent = 'Relevo';
      cb.title = 'Busca Informações do Relevo';

      cb.addEventListener('click', () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
        const haLimite = getHaLimite();
        const desProduto = getHtmlString('descProduto');

        const familiarNOK = getPivoFamiliar() && haLimite > 50;
        if (familiarNOK) {
          Swal.fire({
            icon: 'info',
            text: `Pivô AGF - Agric. Familiar o limite é de 50ha ou raio inferior a 399m!`,
            allowOutsideClick: true,
            showConfirmButton: true,
          });
        }
        // alert(descricaoProduto);
        // alert(haLimite);
        // toast(`${desProduto} ${haLimite} ha`, { icon: '🆗' });
        if (trechoAdutora && !familiarNOK && !verificaDistanciaElementos()) {
          Swal.fire({
            title: `Já estão posicionados todos os pontos para realizar a análise do Relevo?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: `Sim`,
            confirmButtonColor: '#d33',
            cancelButtonText: `Não`,
          }).then(async result => {
            if (result.isConfirmed) {
              Swal.close();
              buscaElevacoesTodos();
            }
          });
        } else {
          toast.error(`Insira o Pivô na área e posicone os pontos`);
        }
      });
      return cb;
    }

    // torre
    function createTorreControl() {
      const cb = createButtonControl();
      cb.textContent = 'Antenas';
      cb.title = 'Ponto da torre de comunicação Antena';
      cb.addEventListener('click', () => {
        if (trechoAdutora) {
          if (!mapBloqueado) {
            torre.setVisible(!torre.getVisible());
          }
        } else {
          // toast.error(`Insira o Pivô na área e posicone os pontos`);
          toast(`Insira o Pivô na área e posicone os pontos`, { icon: '⚠️' });
        }
      });
      return cb;
    }

    function createSVG(path: any, viewBox: any, width: any) {
      const viewb = `0 0 ${viewBox}`;

      const iconSvg1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
      );
      const iconPath1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      );

      iconSvg1.setAttribute('fill', 'none');
      iconSvg1.setAttribute('stroke', 'currentColor');
      iconSvg1.setAttribute('stroke-width', '2');
      iconSvg1.setAttribute('viewBox', viewb);
      iconSvg1.setAttribute('stroke-linecap', 'round');
      iconSvg1.setAttribute('stroke-linejoin', 'round');
      iconSvg1.setAttribute('stroke', '#333333');
      iconSvg1.setAttribute('height', '1em');
      iconSvg1.setAttribute('width', '1em');
      iconSvg1.setAttribute('width', '1em');
      iconSvg1.setAttribute('border', '0');
      iconSvg1.setAttribute(
        'style',
        'padding: 3px; width: 32px; height: 32px; border: 0px ; border-radius: 2px;',
      );
      iconPath1.setAttribute('d', path);
      iconPath1.setAttribute('stroke-linecap', 'evenodd');
      iconPath1.setAttribute('stroke-linejoin', 'evenodd');
      iconPath1.setAttribute('stroke-width', width);

      iconSvg1.appendChild(iconPath1);

      return iconSvg1;
    }

    function creteDiv() {
      const novaDiv = document.createElement('div') as HTMLDivElement;
      novaDiv.style.margin = '1px';
      novaDiv.style.padding = '1px';
      return novaDiv;
    }
    // incluir Ferramenta
    const pivoControlDiv = creteDiv();
    const loadControlDiv = creteDiv();
    const panControlDiv = creteDiv();
    const setorControlDiv = creteDiv();
    const torreControlDiv = creteDiv();
    const relevoControlDiv = creteDiv();
    const salvarControlDiv = creteDiv();

    const response2 = await api.get(
      `/orcamento/versao/${configuracaoId}/modificacao`,
    );

    const bloqueioConfiguracao = response2.data as any;

    // Verificação Pivo Familiar
    const response3 = await api.get(`/orcamento/versao/${configuracaoId}`, {
      params: { completo: true },
    });
    setDescricaoProduto(response3?.data?.OrcamentoProduto[0]?.nome);

    // Verifica CAF
    const listaPartes: any[] =
      response3?.data?.OrcamentoProduto[0]?.OrcamentoParte;
    let infoParteConcluida = false;
    if (listaPartes.length > 0) {
      listaPartes.forEach(parte => {
        if (parte.nome === 'Adutoras e Bombeamento') {
          infoParteConcluida = parte.parteConcluida;
          setPartesConcluidas(infoParteConcluida);
        }
      });
    }
    setPartesConcluidas(infoParteConcluida);
    setRaioAspFinal(0);
    setAreaAspFinal(0);
    setLancesPrevistos('');
    // navigator.clipboard.writeText(`${JSON.stringify(response3?.data)}`);

    if (infoParteConcluida) {
      const response4 = await api.get(
        `/configuracao/impressao/irrigacao/${configuracaoId}`,
      );
      const fichaTecnicaResponse = response4?.data?.fichaTecnica;

      if (
        fichaTecnicaResponse?.raioEfetivoIrrigadoSAF ||
        fichaTecnicaResponse?.raioAspersorFinalCalculado
      ) {
        const aspFinal =
          fichaTecnicaResponse?.raioEfetivoIrrigadoSAF +
          fichaTecnicaResponse?.raioAspersorFinalCalculado;

        const valorGravar =
          fichaTecnicaResponse?.areaTotalIrrigadaCAF ||
          fichaTecnicaResponse?.aspersor1AreaTotalCAF;

        setRaioAspFinal(aspFinal);
        setAreaAspFinal(valorGravar ?? 0);
      }
      let lancesPrv = '';
      const listaLances: any[] = fichaTecnicaResponse?.FichaTecnicaCambagem;
      listaLances.forEach(lancex => {
        lancesPrv += `${lancex.comprimentoLance};`;
      });
      lancesPrv += fichaTecnicaResponse?.balanco;
      setLancesPrevistos(lancesPrv);
    }

    if (bloqueioConfiguracao?.permiteModificar === true) {
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(pivoControlDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(loadControlDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(panControlDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(setorControlDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(torreControlDiv);
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(
        relevoControlDiv,
      );
      mapx.controls[google.maps.ControlPosition.TOP_LEFT].push(
        salvarControlDiv,
      );
      setMapBloqueadoEng(false);
    } else {
      mapBloqueado = true;
      setMapBloqueadoEng(true);
      setMapBloqueadoEdicao(mapBloqueado);
    }
    // Create the control.
    const pivoControl = createPivoControl();
    const loadControl = createLoadControl();
    const panControl = createPanControl();
    const setorialControl = createSetorialControl();
    const torreControl = createTorreControl();
    const relevoControl = createRelevoControl();
    const salvarControl = createSalvarControl();

    // adiciona icone

    pivoControl.appendChild(
      createSVG(
        'M252.78 20.875c-1.302.012-2.6.03-3.905.063-37.928.974-76.148 11.153-111.28 31.437C25.164 117.285-13.41 261.322 51.5 373.75s208.946 151.036 321.375 86.125c77.7-44.86 120.1-127.513 117.47-211.406-3.563 65.847-35.898 128.573-91 169.374-10.828 9.62-22.774 18.315-35.814 25.844-103.68 59.86-235.983 24.4-295.842-79.282-59.86-103.68-24.43-235.984 79.25-295.844 35.64-20.576 74.67-29.88 112.968-29.03 63.304 1.4 124.623 30.57 165.438 82.53l-32.594 23.032c-33.27-42.835-84.01-66.6-136.063-67-.96-.008-1.91-.012-2.875 0-.964.01-1.943.038-2.906.062-28.006.717-56.222 8.215-82.156 23.188-82.99 47.914-111.508 154.322-63.594 237.312 47.914 82.99 154.32 111.51 237.313 63.594 51.37-29.66 81.862-81.724 86.28-136.78-12.53 45.37-42.32 86.745-85.438 114.186-.02.013-.043.018-.062.03l-.344.22c-3.16 2.147-6.42 4.216-9.78 6.156-74.245 42.865-168.918 17.494-211.782-56.75-42.864-74.243-17.493-168.917 56.75-211.78 23.2-13.396 48.39-20.122 73.375-20.782 47.953-1.266 95.138 19.858 125.968 59.156l-39.844 28.156c-20.232-24.32-50.055-37.79-80.594-38.03-1.17-.01-2.33 0-3.5.03-17.035.432-34.176 4.995-49.938 14.094-50.435 29.12-67.806 93.877-38.687 144.313 29.12 50.434 93.908 67.806 144.344 38.686 21.245-12.267 36.623-30.85 45.124-52.03-18.815 21.064-44.364 36.888-73.938 44.155-.04.013-.084.02-.125.033-37.507 10.787-78.796-4.816-99.217-40.188-24.07-41.688-9.845-94.712 31.843-118.78 13.028-7.523 27.143-11.314 41.156-11.69 25.66-.685 50.898 10.098 68.188 30.25l-41 28.97c-5.497-4.796-12.664-7.72-20.53-7.72-17.277 0-31.283 14.007-31.283 31.282 0 17.276 14.004 31.282 31.282 31.282 17.277 0 31.28-14.007 31.28-31.283 0-1.187-.06-2.347-.188-3.5l120.094-57.312 4.03-1.75-.06-.156 62.25-29.72 9.25-4.438-5.282-8.812-19.97-33.375-5.155-8.625-8.25 5.813-8.095 5.718c-45.9-58.864-116.14-91.053-187.844-90.405z',
        '512 512',
        '20px',
      ),
    );

    loadControl.appendChild(
      createSVG(
        'M0 0h24v24H0z M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z M16 5l3 3 M9 7.07a7 7 0 0 0 1 13.93a7 7 0 0 0 6.929 -6',
        '24 24',
        '2px',
      ),
    );

    panControl.appendChild(
      createSVG(
        'M18 24h-6.55c-1.08 0-2.14-.45-2.89-1.23l-7.3-7.61 2.07-1.83c.62-.55 1.53-.66 2.26-.27L8 14.34V4.79a2.5 2.5 0 013.01-2.45C11.1 1.04 12.18.01 13.5.01c.86 0 1.61.43 2.06 1.09.29-.12.61-.18.94-.18a2.5 2.5 0 012.5 2.5v.28a2.5 2.5 0 013 2.45V20c0 2.21-1.79 4-4 4zM4.14 15.28l5.86 6.1c.38.39.9.62 1.44.62H18c1.1 0 2-.9 2-2V6.15c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V3.42c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V2.51c0-.28-.22-.5-.5-.5s-.5.22-.5.5V12h-2V4.79c0-.28-.22-.5-.5-.5s-.5.23-.5.5v12.87l-5.35-2.83-.51.45z',
        '24 24',
        '1px',
      ),
    );

    setorialControl.appendChild(
      createSVG(
        'M0 0h24v24H0z M4 7m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v7a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z M7 16v4 M7.5 16l9 -9 M13.5 16l6.5 -6.5 M4 13.5l6.5 -6.5 M17 16v4 M5 20h4 M15 20h4 M17 7v-2 M7 7v-2',
        '24 24',
        '2px',
      ),
    );

    torreControl.appendChild(
      createSVG(
        'M2.998 5.58a5.55 5.55 0 0 1 1.62-3.88l-.71-.7a6.45 6.45 0 0 0 0 9.16l.71-.7a5.55 5.55 0 0 1-1.62-3.88zm1.06 0a4.42 4.42 0 0 0 1.32 3.17l.71-.71a3.27 3.27 0 0 1-.76-1.12 3.45 3.45 0 0 1 0-2.67 3.22 3.22 0 0 1 .76-1.13l-.71-.71a4.46 4.46 0 0 0-1.32 3.17zm7.65 3.21l-.71-.71c.33-.32.59-.704.76-1.13a3.449 3.449 0 0 0 0-2.67 3.22 3.22 0 0 0-.76-1.13l.71-.7a4.468 4.468 0 0 1 0 6.34zM13.068 1l-.71.71a5.43 5.43 0 0 1 0 7.74l.71.71a6.45 6.45 0 0 0 0-9.16zM9.993 5.43a1.5 1.5 0 0 1-.245.98 2 2 0 0 1-.27.23l3.44 7.73-.92.4-.77-1.73h-5.54l-.77 1.73-.92-.4 3.44-7.73a1.52 1.52 0 0 1-.33-1.63 1.55 1.55 0 0 1 .56-.68 1.5 1.5 0 0 1 2.325 1.1zm-1.595-.34a.52.52 0 0 0-.25.14.52.52 0 0 0-.11.22.48.48 0 0 0 0 .29c.04.09.102.17.18.23a.54.54 0 0 0 .28.08.51.51 0 0 0 .5-.5.54.54 0 0 0-.08-.28.58.58 0 0 0-.23-.18.48.48 0 0 0-.29 0zm.23 2.05h-.27l-.87 1.94h2l-.86-1.94zm2.2 4.94l-.89-2h-2.88l-.89 2h4.66z',
        '16 16',
        '1px',
      ),
    );

    relevoControl.appendChild(
      createSVG(
        'M0 0h24v24H0z M18 21v-14 M9 15l3 -3l3 3 M15 10l3 -3l3 3 M3 21l18 0 M3 6l3 -3l3 3 M6 21v-18',
        '24 24',
        '2px',
      ),
    );

    (await salvarControl).appendChild(
      createSVG(
        'M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z',
        '448 512',
        '20px',
      ),
    );

    // Append the control to the DIV.
    salvarControlDiv.appendChild(await salvarControl);
    relevoControlDiv.appendChild(relevoControl);
    torreControlDiv.appendChild(torreControl);
    setorControlDiv.appendChild(setorialControl);
    panControlDiv.appendChild(panControl);
    loadControlDiv.appendChild(loadControl);
    pivoControlDiv.appendChild(pivoControl);

    // eventos MAPS
    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      function (event: any) {
        if (event.type === 'circle') {
          areaPivo = event.overlay;
          addmarkes(event.overlay.getCenter());
          areaPivo.addListener('bounds_changed', areaPivoMod);
          areaPivoMod();
          drawingManager.setDrawingMode(null);
        }

        if (event.type === 'marker') {
          event.overlay.setMap(null);
        }
        if (event.type === 'marker' && !mapBloqueado && !areaPivo) {
          const coord = event.overlay.getPosition();

          // Familiar
          const haLimite: number = getHaLimite();
          const raioHaLimite = Math.sqrt((haLimite * 10000) / Math.PI);
          const raioFinal = raioHaLimite < 1 ? 398.95 : raioHaLimite;

          event.overlay.setMap(null);

          areaPivo = new google.maps.Circle({
            fillColor: '#00ff00',
            fillOpacity: 0.3,
            strokeWeight: 2,
            clickable: false,
            editable: !mapBloqueado,
            zIndex: 1,
            map: mapx,
            center: coord,
            radius: raioFinal,
          });

          addmarkes(coord);
          drawingManager.setDrawingMode(null);
          areaPivo.addListener('bounds_changed', areaPivoMod);

          mapx.setCenter(areaPivo?.getCenter());
          mapx.setZoom(16);
          mapx.setMapTypeId('hybrid');
        }

        if (event.type === 'polygon') {
          const polygonBounds = event.overlay.getPath();
          const node1 = document.getElementById('txtarea') as HTMLElement;
          let bounds = '';
          for (let i = 0; i < polygonBounds.length; i += 1) {
            bounds = `${bounds + polygonBounds.getAt(i).lng()},${polygonBounds
              .getAt(i)
              .lat()},0 `;
          }
          node1.innerText = bounds;

          addmarkes(polygonBounds.getAt(0));
          drawingManager.setDrawingMode(null);
        }

        createAddListener(true);
      },
    );

    drawingManager.setMap(mapx);

    // Localizar###################################################################

    const input = document.getElementById('pac-input') as HTMLInputElement;
    const localCoord = document.getElementById(
      'coord_input',
    ) as HTMLInputElement;
    const btnCoord = document.getElementById('coord_btn') as HTMLInputElement;

    const mapamsg = document.getElementById('mapamsg') as HTMLInputElement;
    const mapaha = document.getElementById('mapaha') as HTMLInputElement;

    const autocomplete = new google.maps.places.Autocomplete(input, {
      fields: ['place_id', 'geometry', 'formatted_address', 'name'],
    });

    autocomplete.bindTo('bounds', mapx);
    // mapx.controls[google.maps.ControlPosition.LEFT_TOP].push(input);
    // mapx.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(mapamsg);
    // mapx.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapaha);

    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById(
      'infowindow-content',
    ) as HTMLElement;

    infowindow.setContent(infowindowContent);

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        return;
      }

      if (place.geometry.viewport) {
        mapx.fitBounds(place.geometry.viewport);
      } else {
        mapx.setCenter(place.geometry.location);
        mapx.setZoom(16);
      }
      setInnerText('mapamsg', 'Insira o Ponto no mapa');
      mapx.setMapTypeId('hybrid');
    });

    // let ptCoord: google.maps.Marker;
    btnCoord?.addEventListener('click', event => {
      if (localCoord.value.toString() !== '') {
        const coord = localCoord.value.toString() as string;
        if (coord.substring(1, 0) === '-') {
          infowindow.close();
          const coodxy = new google.maps.LatLng({
            lat: Number(coord.split(',')[0]),
            lng: Number(coord.split(',')[1]),
          });
          mapx.setCenter(coodxy);
          mapx.setZoom(16);
          mapx.setMapTypeId('hybrid');
          setInnerText('labelarea', 'Insira o Ponto no mapa');

          // if (ptCoord) {
          //   ptCoord.setMap(null);
          // }
          // // ovidio
          // const coordx = toCoordAdd(coodxy, -0.0, 0.0);
          // ptCoord = new google.maps.Marker({
          //   map: mapx,
          //   icon: {
          //     path: 'M53.1,48.1c3.9-5.1,6.3-11.3,6.3-18.2C59.4,13.7,46.2,0.5,30,0.5C13.8,0.5,0.6,13.7,0.6,29.9 c0,6.9,2.5,13.1,6.3,18.2C12.8,55.8,30,77.5,30,77.5S47.2,55.8,53.1,48.1z',
          //     fillColor: '#ff0',
          //     fillOpacity: 1,
          //     strokeWeight: 0,
          //     scale: 0.2,
          //     anchor: new google.maps.Point(32, 80),
          //     labelOrigin: new google.maps.Point(30, 30),
          //   },
          //   draggable: false,
          //   title: `Coodenada ${JSON.stringify(coodxy)}`,
          //   label: { text: 'P', color: '#000000' },setGlobalMap.
          //   animation: google.maps.Animation.DROP,
          //   position: coodxy,
          // });

          if (!mapBloqueado && !areaPivo) {
            addmarkes(coodxy, 300);
            drawingManager.setDrawingMode(null);
            areaPivo.addListener('bounds_changed', areaPivoMod);
            createAddListener(true);
          }
        }
      }
      // else if (ptCoord) {
      //   ptCoord.setMap(null);
      // }
      setInnerText('mapamsg', 'Insira o Ponto no mapa');
    });

    mapx.setCenter({ lat: -22, lng: -48 });
    mapx.setZoom(5);

    input?.addEventListener('keyup', async event => {
      if (event.key === 'Enter') {
        const coord = input.value.toString() as string;
        if (coord.substring(1, 0) === '-') {
          infowindow.close();
          const coodxy = new google.maps.LatLng({
            lat: Number(coord.split(',')[0]),
            lng: Number(coord.split(',')[1]),
          });
          mapx.setCenter(coodxy);
          mapx.setZoom(16);
          mapx.setMapTypeId('hybrid');
        }
      }

      setInnerText('mapamsg', 'Insira o Ponto no mapa');
    });

    if (textDrawingMapsPortal) {
      if (textDrawingMapsPortal.length > 50) {
        mapBloqueado = true;
        setMapBloqueadoEdicao(mapBloqueado);
      }
      loadDrawingText(textDrawingMapsPortal);
      // const cmbX = document.getElementById('mapId') as HTMLOptionElement;
      // cmbX.value = 'trechoAdutora';
    }
  }

  useEffect(() => {
    function initMap(): void {
      if (window.google && textDrawingMapsPortal) {
        const map = new window.google.maps.Map(
          document.getElementById('mapId') as HTMLElement,
          {
            zoom: 8,
            location: { lat: -16, lng: -48 },
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_CENTER,
            },
            scaleControl: true,
            fullscreenControl: true,
          },
        );

        // const kmlLayer = new window.google.maps.KmlLayer({
        //   url: caminhoKml,
        //   suppressInfoWindows: false,
        //   map,
        // });

        // }

        gereMap(map);
        setGlobalMap(map);
      }
    }
    initMap();
  }, [textDrawingMapsPortal]);

  // Ovidio Souza - 28/12/2022
  // continue@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  useEffect(() => {
    if (geoJSON?.features.length) {
      // setPropriedadesKml(
      //   geoJSON?.features.map(feature => ({
      //     nome: feature?.properties?.name,
      //     valor: feature?.properties?.description,questoes
      //     geometry: feature?.geometry,
      //     visivel: true,
      //   })),
      // );
      let coordKml: any = [];

      geoJSON?.features.forEach(element => {
        // alert(JSON.stringify(element));
        if (element?.geometry?.type === 'LineString') {
          coordKml = element?.geometry?.coordinates[0];
        }
        if (element?.geometry?.type === 'Point') {
          coordKml = element?.geometry?.coordinates;
        }
      });

      const coodxy = new google.maps.LatLng({
        lat: coordKml[1],
        lng: coordKml[0],
      });

      globalMap.data.addGeoJson(geoJSON);
      globalMap.setCenter(coodxy);
      globalMap.setZoom(15);
      globalMap.setMapTypeId('roadmap');

      globalMap.data.setStyle({
        fillColor: '#777777',
        strokeWeight: 1,
        strokeOpacity: 0.4,
      });

      globalMap.data.addListener('rightclick', function (event: any) {
        globalMap.data.remove(event.feature);
      });

      globalMap.data.addListener('dblclick', function (event: any) {
        Swal.fire({
          title: `Deseja ocultar o KML?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Sim`,
          confirmButtonColor: '#d33',
          cancelButtonText: `Não`,
        }).then(async result => {
          if (result.isConfirmed) {
            globalMap.data.forEach(function (feature: any) {
              globalMap.data.remove(feature);
            });
          }
        });
      });
    }
  }, [geoJSON]);

  useEffect(() => {
    function mapeaValoresKmlComQuestoes() {
      if (propriedadesKml.length && funcoesEntradasKml.length) {
        const questoesPropriedadesKmlData = propriedadesKml.map(
          propriedadeKml => {
            const funcaoEntrada = funcoesEntradasKml.find(
              entrada => entrada.propriedadeKml === propriedadeKml.nome,
            );

            return {
              nomePropriedadeKml: propriedadeKml.nome,
              questaoId: funcaoEntrada?.questaoId,
              resposta: propriedadeKml.valor?.replace('m', ''),
              questao: funcaoEntrada?.questao,
            };
          },
        );
        setQuestoesPropriedadesKml(questoesPropriedadesKmlData);
      }
    }

    mapeaValoresKmlComQuestoes();
  }, [propriedadesKml, funcoesEntradasKml]);

  function handleChangeQuestaoPropriedadeKml(
    propriedadeKmlNome: string,
    questaoId: number | string,
  ): void {
    setQuestoesPropriedadesKml(
      questoesPropriedadesKml.map(item => {
        const questao = funcoesEntradasKml.find(
          funcaoEntrada =>
            Number(funcaoEntrada.questaoId) === Number(questaoId),
        )?.questao;
        return item.nomePropriedadeKml === propriedadeKmlNome
          ? {
              ...item,
              questaoId: questaoId ? Number(questaoId) : undefined,
              questao,
            }
          : item;
      }),
    );
  }

  function handleSelecionarAdutora(propriedadeKmlNome: string): void {
    setPropriedadeAdutoraKml(propriedadeKmlNome);

    if (!propriedadeKmlNome) {
      setShowElevationMap(false);
      setPontosAdutora([]);
      setPontosCurvas([]);
      return;
    }

    const pontoAdutora = propriedadesKml.filter(
      item => item.nome === propriedadeKmlNome,
    );

    // const path = pontoAdutora[0]?.geometry?.coordinates
    //   .filter((item: any) => item[0] !== undefined)
    //   .map((item: any) => {
    //     return {
    //       lng: item[0],
    //       lat: item[1],
    //     };
    //   });
    const path = pontoAdutora[0]?.geometry;
    if (!path.length || path.length < 2) {
      setShowElevationMap(false);
      setPontosAdutora([]);
      setPontosCurvas([]);
      return;
    }

    const elevator = new google.maps.ElevationService();

    const dataToSave: OrcamentoPontosMapaPontos[] = [];

    for (let i = 0; i < path.length; i += 1) {
      dataToSave.push({
        longitude: path[i].lng,
        latitude: path[i].lat,
        ordem: i + 1,
      });
    }
    setPontosAdutora(dataToSave);
    setShowElevationMap(true);
    displayPathElevation(path, elevator, globalMap);
  }

  useEffect(() => {
    if (propriedadesKml.length > 0) {
      handleSelecionarAdutora('trechoAdutora');
    }
  }, [propriedadesKml]);

  async function handleCarregaAdutora() {
    const node1 = document.getElementById(
      'cmbAdutoraElev',
    ) as HTMLSelectElement;
    node1.value = 'trechoAdutora';
    handleSelecionarAdutora('trechoAdutora');

    // await uploadFileNew(textDrawingMapsPortal);
  }

  async function handleSalvaRespostas() {
    const questoesRespondidas = questoesPropriedadesKml.filter(
      questaoPropriedadeKml => questaoPropriedadeKml.questaoId,
    );

    const respostasAlteradas: RespostaAlterada[] = questoesRespondidas.map(
      questaoRespondida => {
        const { questaoId, resposta, questao } = questaoRespondida;
        const respostaId = respostas?.find(
          oracmentoResposta => oracmentoResposta?.questaoId === questaoId,
        )?.id;
        return {
          id: respostaId,
          questaoId,
          resposta,
          questao,
          orcamentoParteId,
        };
      },
    );

    try {
      setSalvandoRespostas(true);
      await api.post(`/orcamento/respostas`, {
        respostasAlteradas,
        orcamentoId: configuracaoId,
      });
      carregaRespostas();
    } finally {
      setSalvandoRespostas(false);

      if (onSave) {
        // props.onSave();
      }
    }
  }

  async function handleReloadPage() {
    Swal.fire({
      title: `Deseja desfazer e reiniciar a geolocalização?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        Swal.close();
        window.location.reload();
      }
    });
  }

  async function handleSalvarElevacao() {
    try {
      setSalvandoElevacao(true);
      await api.post(`/orcamento/pontos-mapa`, {
        orcamentoId: configuracaoId,
        tipo: 'elevacao_adutora',
        pontos: pontosElevacao,
      });

      await api.post(`/orcamento/pontos-mapa`, {
        orcamentoId: configuracaoId,
        tipo: 'pontos_adutora',
        pontos: pontosAdutora,
      });

      await api.post(`/orcamento/pontos-mapa`, {
        orcamentoId: configuracaoId,
        tipo: 'pontos_ventosa',
        pontos: pontosVentosa,
      });

      await api.post(`/orcamento/pontos-mapa`, {
        orcamentoId: configuracaoId,
        tipo: 'local_sede',
        pontos: localSede,
      });

      await api.post(`/orcamento/pontos-mapa`, {
        orcamentoId: configuracaoId,
        tipo: 'angulos_curvas',
        pontos: pontosCurvas.map((item: OrcamentoPontosMapaPontos) => {
          return {
            latitude: item.latitude,
            longitude: item.longitude,
            elevacao: item.elevacao,
            distancia: item.distancia,
            ordem: item.ordem,
            angulo: item.angulo,
            curvasCalculadas: item.grausCalculado?.join(','),
          };
        }),
      });
    } finally {
      setSalvandoElevacao(false);
      toast.success('Informações da adutora foram salvas à configuração');
    }
  }

  async function salvarDadosMapa() {
    try {
      Swal.fire({
        icon: 'info',
        text: `Salvando informações do mapa. Por favor, aguarde.`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await api.post('/orcamento/dados-mapa', {
        orcamentoId: configuracaoId,
        pontos: propriedades,
      });
    } finally {
      Swal.close();
    }
  }

  async function confirmarSalvar() {
    const valido = await checkPosicao();
    if (valido) {
      await handleSalvarElevacao();
      await salvarDadosMapa();
      if (onSave) props.onSave();
    }
  }

  async function handleSalvarRespostas() {
    if (!propriedades) return;

    if (mapBloqueadoEng === false) {
      if (!propriedadesKml.length || !dadosSalvosMapa) {
        toast.error(
          `Salve as alterações no mapa antes de finalizar essa etapa`,
        );
        return;
      }

      if (propriedadesKml.length > 0) {
        Swal.fire({
          title: `Deseja marcar essa etapa como concluída?`,
          text: `Será executado o processo para salvar as informações no campo de questionário. As alterações não salvas serão perdidas.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Sim`,
          confirmButtonColor: '#d33',
          cancelButtonText: `Não`,
        }).then(async result => {
          if (result.isConfirmed) {
            await confirmarSalvar();
          }
        });
      }
    } else {
      toast(`Não permitido após conclusão Engenharia!`, { icon: '⚠️' });
    }
  }

  // salvar pelo Mapa
  useEffect(() => {
    if (salvarPeloMapa) {
      const focusConcluir = document.getElementById(
        'btnSalvaMapa',
      ) as HTMLElement;
      focusConcluir.focus();
      // handlesalvarRespostas();
    }
  }, [salvarPeloMapa]);

  return (
    <>
      <table style={{ width: '100%' }}>
        <tr>
          <th style={{ width: '150px' }}> Tag do Pivo:</th>
          <th>
            <input
              id="tagPivo"
              type="text"
              className="form-control"
              placeholder="Insira o TAG do Pivo"
              style={{ width: '200px' }}
              autoComplete="off"
            />{' '}
          </th>
          <td />
          <td>
            <Input
              id="descProduto"
              name="descProduto"
              type="text"
              value={descricaoProduto}
              disabled
              hidden
            />
          </td>
          <td>
            <Input
              id="lancesPrevistos"
              name="lancesPrevistos"
              type="text"
              value={lancesPrevistos}
              disabled
              hidden
            />
          </td>
        </tr>
        <tr>
          <td>
            <span id="place-name" className="title" />
            Localização:
          </td>
          <td>
            <input
              id="pac-input"
              name="pac-input"
              className="form-control"
              type="text"
              placeholder="Cidade*"
              style={{ width: '400px' }}
              autoComplete="off"
            />
            <span id="place-id" />
            <span id="place-address" />
          </td>
          <td align="right" colSpan={2}>
            <input
              id="coord_input"
              name="coord_input"
              className="contato__container__box__form__input form-control"
              type="text"
              autoComplete="off"
              style={{
                alignItems: 'flex-end',
                width: '400px',
                height: '32px',
                padding: '5px',
                margin: '2px 2px 2px 2px',
              }}
              title="Opcional coordenada do centro Pivô lat,lng (-24.23,-54,32)"
              placeholder="Opcional coordenada do Pivô lat,lng (-24.23,-54,32)"
            />
          </td>
          <td align="left">
            <button
              id="coord_btn"
              name="coord_btn"
              style={{
                padding: '5px',
                borderRadius: '5px',
                background: '#666666',
                color: '#ffffff',
              }}
              title="Insere centro do pivo na coordenada"
              type="button"
            >
              <FaArrowDown /> Coordenada
            </button>
            <button
              id="coord_btn"
              name="coord_btn"
              onClick={event => handleReloadPage()}
              style={{
                padding: '5px',
                borderRadius: '5px',
                background: '#666666',
                color: '#ffffff',
              }}
              title="Voltar"
              type="button"
            >
              <FaUndo />
            </button>
          </td>
        </tr>
        <tr>
          <td>Área Definida (ha):</td>
          <td>
            <Input
              id="haLimite"
              name="haLimite"
              type="number"
              autoComplete="off"
              title="Se a área estiver definida em hectares, informe-a aqui e click no botão Pivô no mapa!"
              min={0}
              step={1}
              // hidden={mapBloqueadoEdicao}
              disabled={mapBloqueadoEdicao}
              max={getPivoFamiliar() ? 50 : 1000}
              value={infoHaLimite}
              onChange={event => {
                const info = Number(event.target.value);
                const infoRaio = getHtmlNumber('raioSemAspFinal');
                if (getPivoFamiliar() && (info > 50 || infoRaio > 399)) {
                  Swal.fire({
                    icon: 'info',
                    text: `Pivô AGF - Agric. Familiar o limite é de 50ha ou raio inferior a 399m!`,
                    allowOutsideClick: true,
                    showConfirmButton: true,
                  });
                  setInfoHaLimite(50);
                } else {
                  setInfoHaLimite(Number(event.target.value) ?? 0);
                }
                setInfoAreaManual(true);
              }}
            />
          </td>
          <td>
            ha
            <Tooltip
              title={
                getPivoFamiliar()
                  ? 'Pivô AGF - Agric. Familiar - limite 50ha ou raio inferior a 399m! Se estiver definida a área em ha, informe aqui! '
                  : 'Se estiver definida a área em ha, informe aqui!'
              }
            >
              <IconButton>
                <FaInfoCircle
                  size={20}
                  color="#999999"
                  onClick={e => {
                    setInfoAreaManual(!infoAreaManual);
                  }}
                />
              </IconButton>
            </Tooltip>
          </td>
          <td style={{ textAlign: 'right' }} hidden>
            <i>Raio:</i>{' '}
          </td>
          <td hidden>
            <Input
              id="raioSemAspFinal"
              name="raioSemAspFinal"
              type="number"
              value={raioSemAspFinal}
            />
          </td>
          <td hidden={raioAspFinal === 0} style={{ textAlign: 'right' }}>
            <Label>Área com Aspersor Final:</Label>
          </td>
          <td hidden={raioAspFinal === 0}>
            <Input
              id="raioAspFinal"
              name="raioAspFinal"
              type="number"
              value={raioAspFinal?.toFixed(1)}
              hidden
            />
            <Label id="areaAspFinal" name="reaAspFinal" type="text">
              <strong>{` ${areaAspFinal?.toFixed(1)} ha`}</strong>
            </Label>
          </td>
        </tr>
        <tr hidden={!infoAreaManual} style={{ color: '#ff0000' }}>
          <td />
          <td>
            <Label>Click no botão Pivô no mapa para dimensionar!</Label>
          </td>
          <td>
            <FaCalculator />
            Calcular área pelo raio:
          </td>
          <td>
            <Input
              id="raioInfoManual"
              name="raioInfoManual"
              type="number"
              disabled={mapBloqueadoEdicao}
              onChange={e => {
                const aLimite = getAreaEmHectares(e.target.value);
                setInfoHaLimite(Number(aLimite?.toFixed(1)));
              }}
            />
          </td>
          <td>m</td>
        </tr>
        <tr>
          <td>Observação:</td>
          <td colSpan={4}>
            <input
              id="obsPivo"
              type="text"
              className="form-control"
              placeholder=""
              autoComplete="off"
            />
          </td>
        </tr>

        <tr>
          <td>Referência KML</td>
          <td colSpan={3}>
            <Col sm="12">
              <input
                className="mb-2"
                accept=".kml"
                type="file"
                onChange={e => handleFileRead(e)}
              />
            </Col>
          </td>
        </tr>
      </table>
      <Row style={{ width: '100%' }} className="mt-2">
        <Col sm="8">
          <small>Dados Mapa:</small>{' '}
          <strong id="labelarea" style={{ textAlign: 'right' }} />
        </Col>
        <Col sm="4">
          <strong
            id="mapamsg"
            style={{
              color: '#1c1818',
              backgroundColor: '#fcff66',
              fontFamily: 'verdana',
              fontSize: '14px',
            }}
          >
            Localize a área e insira o Pivô
          </strong>
        </Col>
      </Row>
      <Row style={{ width: '100%' }} className="mt-2">
        <Col sm="12">
          <div id="mapId" style={{ height: '750px', fontSize: '20' }} />
        </Col>
      </Row>
      <Row style={{ width: '100%' }} className="mt-2">
        <Col sm="12">
          <p>
            <a
              id="linkEarth"
              href="https://www.fockink.ind.br"
              rel="noopener noreferrer"
              target="_blank"
              title="https://earth.google.com/web/@-28.39973914,-53.34539767,478.98516058a,2162.053936d,35y,0h,60t,0r"
            >
              Google Earth web
            </a>
          </p>

          <p>
            <a
              id="linkSentinel"
              href="https://www.fockink.ind.br"
              rel="noopener noreferrer"
              target="_blank"
              title="https://apps.sentinel-hub.com/sentinel-playground/?source=S2L2A&lat=-23.576554639022564&lng=-52.26013898849487&zoom=15&preset=1_TRUE_COLOR&layers=B01,B02,B03&maxcc=0&gain=2.4&gamma=1.1&atmFilter=&showDates=true"
            >
              Sentinel
            </a>
          </p>
        </Col>
      </Row>

      <Row className="mt-3" style={{ width: '100%' }}>
        <Col sm="2" hidden>
          <Table responsive>
            <thead>
              <tr>
                <Th>Propriedade</Th>
                <Th>Descrição</Th>
                <Th>Questão associada</Th>
              </tr>
            </thead>
            <tbody>
              {propriedadesKml.map(propriedadeKml => (
                <tr key={uuid()}>
                  <Td>
                    <Tooltip title={propriedadeKml.nome}>
                      <DivOverflow>{propriedadeKml.nome}</DivOverflow>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip title={propriedadeKml.valor || ''}>
                      <DivOverflow>{propriedadeKml.valor}</DivOverflow>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Input
                      bsSize="sm"
                      className="mb-1 mt--1"
                      type="select"
                      value={
                        questoesPropriedadesKml.find(
                          item =>
                            item.nomePropriedadeKml === propriedadeKml.nome,
                        )?.questaoId
                      }
                      onChange={event =>
                        handleChangeQuestaoPropriedadeKml(
                          propriedadeKml.nome,
                          event.target.value,
                        )
                      }
                    >
                      <option value="">Questão associada...</option>
                      {funcoesEntradasKml.map(funcaoEntradaKml => (
                        <option
                          key={funcaoEntradaKml.id}
                          value={funcaoEntradaKml.questaoId}
                        >
                          {funcaoEntradaKml.questao.descricao}
                        </option>
                      ))}
                    </Input>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-2">
            {questoesPropriedadesKml.length > 0 && (
              <div className="mt-1 mr-1 float-right">
                <Button
                  disabled={salvandoRespostas}
                  size="sm"
                  onClick={event => handleSalvaRespostas()}
                  color="danger"
                  outline
                >
                  {salvandoRespostas ? 'Salvando...' : 'Salvar respostas'}
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col sm="7" hidden>
          <Row>
            <Col sm="4">
              <span className="float-right">Propriedade da Adutora:</span>
            </Col>
            <Col sm="4">
              <Input
                id="cmbAdutoraElev"
                bsSize="sm"
                className="mb-1 mt--1"
                type="select"
                value={propriedadeAdutoraKml}
                onChange={event => handleSelecionarAdutora(event.target.value)}
              >
                <option value="">Propriedade Kml...</option>
                {propriedadesKml.map((prop: PropriedadeKml, index: number) => (
                  <option key={`${prop.nome}_${index}`} value={prop.nome}>
                    {prop.nome}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm="3">
              <div className="float-left">
                <Button
                  id="bt_elevacao"
                  size="sm"
                  onClick={event => handleCarregaAdutora()}
                  color="info"
                  outline
                >
                  Carrega Adutora
                </Button>
                <Button
                  disabled={
                    !showElevationMap || salvandoElevacao || keepItBlocked
                  }
                  size="sm"
                  onClick={event => handleSalvarElevacao()}
                  color="danger"
                  outline
                >
                  {salvandoElevacao ? 'Salvando...' : 'Salvar elevação'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="12" className="mt-0">
          <Row className="mt-0">
            <Col sm="7 p-0">
              <div
                id="elevation_chart"
                hidden={!showElevationMap}
                style={{ height: 450 }}
              />
            </Col>
            <Col sm="5" hidden={!pontosCurvas.length}>
              <div className="mt-4">
                <strong>Curvas do Trecho</strong>
                <Table className="align-items-center" responsive>
                  <thead style={{ background: '#C2C2C2' }}>
                    <tr>
                      <th>nº</th>
                      <th>Ângulo das Curvas</th>
                      <th>Ângulos Calculados</th>
                    </tr>
                  </thead>
                  {pontosCurvas?.map((item: OrcamentoPontosMapaPontos) => {
                    return (
                      <tr key={item.ordem}>
                        <td>{item.ordem}</td>
                        <td>{item.angulo}°</td>
                        <td>{item.grausCalculado?.join(' + ')}</td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <div>
          <Col sm="12">
            <Button
              id="btnSalvaMapa"
              fontSize="12"
              size="sm"
              className="btn-icon ml-1"
              color="primary"
              type="button"
              onClick={handleSalvarRespostas}
            >
              <FaArrowCircleRight />
              <span className="btn-inner--text">
                Concluir Etapa e avançar para a próxima
              </span>
            </Button>
            {'    '}
            <Button
              className="btn-icon ml-1"
              color="warning"
              type="button"
              size="sm"
            >
              <FaInfo />
            </Button>
            <strong>ATENÇÃO :</strong> Todas as etapas necessitarão ser{' '}
            <i>processadas e concluídas</i> para salvar os dados e alteração do
            mapa.
          </Col>
        </div>
      </Row>
      <Row style={{ width: '100%' }} hidden>
        <Col sm="12">
          DataDrawing :{' '}
          <textarea
            id="txtMsg"
            name="txtMsg"
            style={{
              width: '100%',
              height: '100px',
              margin: '4px 4px 4px',
              color: '#f1f1f1',
            }}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%', visibility: 'hidden' }} hidden>
        <Col sm="3" />
        <Col sm="6">
          <strong
            id="mapaha"
            style={{
              color: '#1c1818',
              backgroundColor: '#7ff2be',
              fontFamily: 'verdana',
              fontSize: '18px',
            }}
          >
            ha
          </strong>
        </Col>
      </Row>
    </>
  );
}
